import { fromClass, fromClassArray } from "fw-model";
import { EventNotification } from "models/scheduled-export"
import { VisibilityStatus } from "./visibility-status";
export enum ApplicationSourceFileCountType {
  Single = 0,
  Multiple = 1
}

export enum ApplicationSourceTransformationType {
  None = 0,
  Javascript = 1,
  Mapping = 2
}

export class ApplicationSourceJavascriptTransformation {
  IncludeFunctionIds: string[] = [];
  Function: string = null;
}

export class ColumnMapping {
  From?: string = null;
  FromScript?: string = null;
  FromValue?: string = null;
  To: string = null;
  TranslationTableId?: string = null;
}

export class ApplicationSourceMappingTransformation {
  Sample: string = null;
  SampleName: string = null;
  SampleUploadedOnUtc: Date = null;
  @fromClass Operation: ColumnMapping;
  @fromClassArray(ColumnMapping) Identifiers: ColumnMapping[];
  @fromClass Program: ColumnMapping;
  @fromClassArray(ColumnMapping) Columns: ColumnMapping[];
}

export class ApplicationSourceMetaData {
  IsManaged: boolean = false;
  DateLastImportedUtc: string = null;
}

export class ApplicationSource {
  Id: string;
  Name: string = null;

  FileSourceId: string = null;
  ProgramId: string = null;
  Path: string = null;
  Active: boolean = true;
  Visibility: VisibilityStatus = VisibilityStatus.Visible;

  FileCountType = ApplicationSourceFileCountType.Single;
  TransformationType = ApplicationSourceTransformationType.Javascript

  FileMask: string = '*.*';

  @fromClass JavascriptTransformation: ApplicationSourceJavascriptTransformation;
  @fromClass MappingTransformation: ApplicationSourceMappingTransformation;
  @fromClass MetaData: ApplicationSourceMetaData;

  @fromClassArray(EventNotification) Notifications: EventNotification[];
}

export enum ApplicationSourceHistoryStatus {
  Pending = 0,
  Processing = 1,
  Success = 2,
  PartialSuccess = 3,
  Failed = 4
}

export class ApplicationSourceHistoryMetaData {
  OrganizationId: string;
  ApplicationSourceId: string;
  SeasonId: string;
  StageId: string;
  PhaseId: string;
  PublicId: string;

  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  DateCompletedUtc: string;
  HasOutputFiles: boolean;
  EphemeralOriginalFileUrl: string;
  EphemeralLogFileUrl: string;
}

export class ApplicationSourceHistory {
  Id: string;
  FileName: string;
  FileDateUtc: string;
  Status: ApplicationSourceHistoryStatus;
  TotalRecordCount: number = 0;
  ProcessedRecordCount: number = 0;
  FailedRecordCount: number = 0;
  DeletedRecordCount: number = 0;
  @fromClass MetaData: ApplicationSourceHistoryMetaData;
}
