import { inject } from "fw";
import { dispatch } from 'fw-state';

import { createFilter, Filter } from "models/filter-setup";
import { getSectionMetas } from "models/testscore";
import { ApplicationSettingsStore } from "state/application-settings";
import { ContactStore, EnsureContactStoreAction } from "state/contacts";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { ContactsDataSourceInstanceStore, EnsureContactsDataSourceInstanceStoreAction } from "state/contacts-data-source-instance";
import { ContactSegmentStore } from "state/contact-segments";
import { PopoverController } from "service/popover";

import { ContactOrganization, ContactTypeDefinition, CustomFieldType, getFields, ICustomFieldDefinition, ICustomFieldDefinitionBase, NestedSearch, NestedSearchFieldDefinition } from "models/contact-organization";
import { CollaborationModulesStore } from "state/collaboration-modules";
import { FeatureFlagService } from "service/feature-flag";
import { DataDictionaryService } from "service/data-dictionary";
import { EventPermissionService } from "service/permissions/event";
import { CurrentUserStore } from "state/current-user";
import { Permissions } from "service/permission";
import { CurrentOrganizationStore } from "state/current-organization";

interface FilterItem { name: string; type: string; data?: any; }

type FilterType = "applications" | "contacts" | "activity" | "tasks" | "events" | "nested" | "nested_search";

export interface AddFilterParams {
  canAddGroup: boolean,
  type: FilterType,
  data: { [key: string]: string },
  overrideContactType?: string
}

function sortByName(a: FilterItem, b: FilterItem) {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;

  return 0;
}

@inject
export class AddFilter {
  private filters: FilterItem[] = [];
  private type: FilterType = "applications";
  private overrideContactType?: string = null;
  private selectedNestedFieldId: string = null;
  private selectedNestedSearchId: string = null;
  private canAddGroup: boolean = false;

  private width: string = "100%";
  private $refs: any;
  public searchTerm: string = "";
  private searchRef: any;

  loading: boolean = false;

  constructor(
    private controller: PopoverController<Filter>,
    private appSettingsStore: ApplicationSettingsStore,
    private contactStore: ContactStore,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private contactsDataSourceInstanceStore: ContactsDataSourceInstanceStore,
    private contactSegmentStore: ContactSegmentStore,
    private dataDictionaryService: DataDictionaryService,
    private currentUserStore: CurrentUserStore,
    private permissions: Permissions,
    private ffs: FeatureFlagService,
    private collaborationModulesStore: CollaborationModulesStore,
    private currentOrganizationStore: CurrentOrganizationStore,
    private eventPermissionService: EventPermissionService,
  ) { }

  public async activate(params: AddFilterParams) {
    if (params.type == "contacts") {
      this.loading = true;

      await dispatch(new EnsureContactStoreAction());
      await dispatch(new EnsureContactsDataSourceInstanceStoreAction());

      this.loading = false;
    }

    this.type = params.type;
    this.selectedNestedFieldId = params?.data?.id;
    this.selectedNestedSearchId = params?.data?.nestedSearchId;
    this.overrideContactType = params.overrideContactType;
    this.canAddGroup = params.canAddGroup;
    await this.updateFilters();
  }

  public clearSearch() {
    this.searchTerm = "";
    this.searchRef.focus();
  }

  public get filtered() {
    const list = this.filters;
    const search = this.searchTerm?.trim().toLowerCase();
    if (search?.length > 0) {
      // ??? not sure how else to keep the width the same while searching
      if (this.width === "100%") {
        this.width = `${this.$refs.filterList.clientWidth}px`;
      }
      return list.filter(f => f.name.toLowerCase().indexOf(search) !== -1);
    }
    return list;
  }

  private async updateFilters() {
    this.filters = [];

    switch (this.type) {
      case "contacts":
        await this.addContactsFilter();
        break;
      case "activity":
        this.addActivityFilter();
        break;
      case "nested":
        this.addContactNestedFieldsFilters();
        break;
      case "nested_search":
        this.addContactNestedSearchFieldsFilters();
        break;
      case "tasks":
        this.addTaskFilters();
        break;
      case "events":
        this.addEventFilters();
        break;
      default:
        await this.addApplicationsFilter();
        break;
    }

    if (this.canAddGroup) {
      // group filter..  always at the bottom
      this.filters.push({ name: "Group", type: "group-filter" });
    }
  }

  private addContactNestedFieldsFilters() {
    if (!this.ffContactNestedSearch) {
      return;
    }

    const fields: ICustomFieldDefinition[] = getFields(this.organization);
    const nestedFields = fields.find(field => field.id === this.selectedNestedFieldId)?.nested_fields;

    if (!nestedFields || !nestedFields.length) {
      return;
    }

    this.filters = this.getFilters(nestedFields);
  }

  private addContactNestedSearchFieldsFilters() {
    if (!this.ffComplexDataAttachments) {
      return;
    }

    const fields: ICustomFieldDefinition[] = getFields(this.organization);
    const nestedSearches = fields.find(field => field.id === this.selectedNestedFieldId)?.nested_searches;

    if (!nestedSearches || !nestedSearches.length) {
      return;
    }

    const nestedSearchesFields = nestedSearches.find(nestedSearch => nestedSearch.id === this.selectedNestedSearchId)?.fields;
    this.filters = this.getFilters(nestedSearchesFields);
  }

  private get organization(): ContactOrganization {
    return this.currentContactOrganizationStore.state.organization;
  }

  private async addApplicationsFilter(): Promise<void> {
    const filters: FilterItem[] = [];

    filters.push({ name: "Keyword", type: "keyword-filter" });
    filters.push({ name: "Application Form", type: "form-field-filter" });

    if (this.ffs.isFeatureFlagEnabled("MyApplicationReviewsFilter")
      && this.permissions.any("EvaluateApplications")
      && !this.currentUserStore.state.isGlobalPrincipal
    ) {
      filters.push({ name: "My Completed Reviews", type: "my-completed-evaluations-filter" });
      filters.push({ name: "My Incomplete Reviews", type: "my-incompleted-evaluations-filter" });
    }

    const { applicantSettings, applicationSettings } = this.appSettingsStore.state;

    if (applicationSettings.ApplicationProperties.length > 0) {
      filters.push({ name: "Application Property", type: "application-property-filter" });
    }

    if (applicantSettings.ApplicantProperties.length > 0) {
      filters.push({ name: "Applicant Property", type: "applicant-property-filter" });
    }

    filters.push(this.ffs.isFeatureFlagEnabled("ApplicationOrigin")
      ? { name: "Origin", type: "origin-filter" }
      : { name: "Application Source", type: "application-source-filter" }
    );

    if (this.ffs.isFeatureFlagEnabled("SearchableStepGroupPaymentStatus")) {
      filters.push({ name: "Submission Status", type: "submission-status-filter" });
      filters.push({ name: "Payment Status", type: "payment-status-filter" });
    }

    filters.push({ name: "Program", type: "program-filter" });
    filters.push({ name: "Program Property", type: "program-property-filter" });
    filters.push({ name: "Applicant Started", type: "date-started-filter" });
    filters.push({ name: "Application Stage", type: "program-stage-filter" });
    filters.push({ name: "Tags", type: "tag-filter" });

    if (await this.dataDictionaryService.hasReferenceStep()) {
      filters.push({ name: "Reference Counts", type: "reference-count-filter" });
    }

    filters.push({ name: "Segment", type: "segment-filter" });
    filters.push({ name: "Phase", type: "phase-filter-with-categories" });

    if (this.currentOrganizationStore.hasDecisionsFeatureEnabled) {
      filters.push({ name: "Decision", type: "decision-filter" });
      filters.push({ name: "Decision Letter", type: "decision-letter-filter" });
    }
    filters.push({ name: "Phase Calculated Field", type: "phase-calculated-field-filter" });
    filters.push({ name: "Review Assigned To", type: "assigned-user-filter" });
    filters.push({ name: "Reviewed By", type: "evaluated-by-filter" });
    filters.push({ name: "Review Complete", type: "evaluation-complete-filter" });

    const { modules } = this.collaborationModulesStore.state;
    if (modules.length > 0) {
      filters.push({ name: "Assigned to Collaboration Module", type: "collaboration-assigned-user-filter" });
      filters.push({ name: "Collaboration Reviewed By", type: "collaboration-evaluated-by-filter" });

      if (modules.some(m => m.CalculatedFields.length > 0)) {
        filters.push({ name: "Collaboration Calculated Field", type: "collaboration-calculated-field-filter" });
      }
    }

    filters.push({ name: "Last Updated", type: "last-updated-filter" });
    filters.push({ name: "Date Last Stage Changed", type: "date-stage-changed-filter" });
    filters.push({ name: "Date Last Phase Changed", type: "date-phase-changed-filter" });
    if (this.currentOrganizationStore.hasDecisionsFeatureEnabled) {
      filters.push({ name: "Date Last Decision Changed", type: "date-decision-changed-filter" });
    }
    filters.push({ name: "Comments", type: "comment-filter" });
    filters.push({ name: "Has Application", type: "application-filter" });

    this.filters = filters; /* filters.sort(sortByName); */
  }

  private addActivityFilter() {
    const filters: FilterItem[] = [
      { name: "Data Source", type: "activity-source-filter" },
      { name: "Activity Title", type: "activity-title-filter" },
      { name: "Activity Description", type: "activity-description-filter" },
      { name: "Business Value", type: "activity-business-value-filter" }
    ];
    this.filters = filters.sort(sortByName);
  }

  private async addContactsFilter() {
    const contactType = this.contactType;
    const filters: FilterItem[] = [];
    if (this.canAddGroup) {
      filters.push({ name: "Activity Filters", type: "contacts-activity-group-filter" });

      if (this.ffContactNestedSearch) {
        const fields: ICustomFieldDefinition[] = getFields(this.organization, contactType.key);
        fields.forEach(field => {
          if (field.nested_fields && field.nested_fields.length) {
            filters.push({ name: `${field.display_name} Filters`, type: "nested-group-filter", data: { field: field } });
          }
        });
      }

      if (this.ffComplexDataAttachments) {
        const fields: ICustomFieldDefinition[] = getFields(this.organization, contactType.key);
        fields.forEach(field => {
          if (field.nested_searches && field.nested_searches.length) {
            field.nested_searches.forEach(nestedSearch => {
              filters.push({ name: `${nestedSearch.display_name} Filters`, type: "nested-search-group-filter", data: { field: field, nestedSearch: nestedSearch } });
            })
          }
        });
      }
    }

    if (this.canViewEventFilters) {
      filters.push({ name: "Event Filters", type: "contacts-event-filter" });
    }

    if (this.organization.enable_contact_number_sequencing) {
      filters.push({ name: "Contact Number", type: "contacts-contact-number-filter" });
    }

    filters.push({ name: "Keyword", type: "contacts-keyword-filter" });
    filters.push({ name: "Email", type: "contacts-email-filter" });
    filters.push({ name: "Engagement Score", type: "contacts-operator-filter", data: { field: { display_name: "Engagement Score", search_field: "score" } } });

    let instanceFilter: FilterItem = {
      name: "Integration",
      type: "contacts-data-source-instance-filter",
      data: { integrations: [], selectedIds: [], uploadIds: [], mode: "any" }
    };
    let fields = this.organization.fields.filter(f => !f.is_system_field);

    if (contactType) {
      if (contactType.key === "applicant" && this.canAddGroup) {
        //  add this to the top of the filters list
        filters.unshift({ name: "Application Filters", type: "contacts-application-group-filter" });
      }

      if (this.contactSegmentStore.state.segments.findIndex(s => s.contact_type === contactType.key) !== -1) {
        filters.push({
          name: "Segment",
          type: "contacts-segment-filter",
          data: { contactType: contactType.key }
        });
      }

      instanceFilter.data.contactType = contactType.key;
      filters.push({ name: "Name", type: "contacts-name-filter", data: { useFullName: contactType.use_full_name } });

      const tagsField = this.organization.fields.find(f => f.contact_type == contactType.key && f.name === "Tags");
      if (tagsField) {
        const data = { field: tagsField, mode: "any", options: tagsField.options.map(o => o.name) };
        filters.push({ name: tagsField.display_name, type: "contacts-tags-filter", data: data });
      }

      fields = fields.filter(f => f.contact_type == contactType.key);
    }

    const instances = await this.contactsDataSourceInstanceStore.getInstances();
    if (instances && instances.length > 0) {
      instances.forEach(i => {
        if (contactType && contactType.key != i.contact_type) {
          return;
        }

        if (i.data_source_key === "upload") {
          instanceFilter.data.uploadIds.push(i.id);
          return;
        }

        instanceFilter.data.integrations.push({ name: i.name, id: i.id })
      });
    }
    filters.push(instanceFilter);

    this.filters = [...filters, ...this.getFilters(fields)];
  }

  private get contactType(): ContactTypeDefinition {
    return !this.overrideContactType ? this.contactStore.state.selectedContactType : this.organization.contact_types.find(ct => ct.key == this.overrideContactType);
  }

  private getFilters(fields: ICustomFieldDefinition[] | ICustomFieldDefinitionBase[]): FilterItem[] {
    const filters = [];

    fields.forEach(t => {
      let data: any = { field: t };
      switch (t.type) {
        case CustomFieldType.string:
        case CustomFieldType.largestring:
          filters.push({ name: t.display_name, type: "contacts-string-filter", data: data });
          break;
        case CustomFieldType.phone:
        case CustomFieldType.social:
          filters.push({ name: t.display_name, type: "contacts-key-value-filter", data: data });
          break;
        case CustomFieldType.address:
          filters.push({ name: t.display_name, type: "contacts-address-filter", data: data });
          break;
        case CustomFieldType.date:
          filters.push({ name: t.display_name, type: "contacts-date-filter", data: data });
          break;
        case CustomFieldType.number:
          filters.push({ name: t.display_name, type: "contacts-operator-filter", data: data });
          break;
        case CustomFieldType.dropdown:
        case CustomFieldType.funnel:
          data.options = t.options.map(o => { return { text: o.name, value: o.name }; });
          filters.push({ name: t.display_name, type: "contacts-option-filter", data: data });
          break;
        case CustomFieldType.boolean:
          data.options = [{ text: "Yes", value: true }, { text: "No", value: false }];
          filters.push({ name: t.display_name, type: "contacts-option-filter", data: data });
          break;
        case CustomFieldType.link:
          filters.push({ name: t.display_name, type: "contacts-link-filter", data: data });
          break;
        case CustomFieldType.multiselect:
          data.mode = "any";
          data.options = t.options.map(o => o.name);
          filters.push({ name: t.display_name, type: "contacts-multiselect-filter", data: data });
          break;
        case CustomFieldType.tags:
          data.mode = "any";
          data.options = t.options.map(o => o.name);
          filters.push({ name: t.display_name, type: "contacts-tags-filter", data: data });
          break;
        case CustomFieldType.relationship:
          filters.push({ name: t.display_name, type: "contacts-relationship-filter", data: data });
          break;
        case CustomFieldType.slideroomadmissionsapplications:
          filters.push({ name: t.display_name, type: "contacts-slideroom-admissions-applications-filter", data: data });
          break;
        case CustomFieldType.slideroomapplications:
          filters.push({ name: t.display_name, type: "contacts-slideroom-applications-filter", data: data });
          break;
        case CustomFieldType.testscore:
          data.sections = getSectionMetas(t);
          filters.push({ name: t.display_name, type: "contacts-test-score-filter", data: data });
          break;
        case CustomFieldType.user:
          data.mode = "any";
          filters.push({ name: t.display_name, type: "contacts-user-filter", data: data });
          break;
        case CustomFieldType.postalcode:
          filters.push({ name: t.display_name, type: "contacts-postal-code-filter", data: data });
          break;
        case CustomFieldType.country:
          filters.push({ name: t.display_name, type: "contacts-country-filter", data: data });
          break;
      }
    });

    return filters;
  }

  private addTaskFilters() {
    const filters: FilterItem[] = [];
    filters.push({ name: "Description", type: "task-description-filter" });
    filters.push({ name: "Date Completed", type: "task-date-completed-filter" });
    filters.push({ name: "Date Last Assigned", type: "task-date-last-assigned-filter" });
    filters.push({ name: "Tags", type: "task-tag-filter" });
    filters.push({ name: "Assignee", type: "task-assigned-user-filter" });
    filters.push({ name: "Target Type", type: "task-target-type-filter" });
    filters.push({ name: "Due Date", type: "task-date-range-filter" });
    this.filters = filters.sort(sortByName);
  }

  private addEventFilters() {
    const filters: FilterItem[] = [];
    filters.push({ name: "Location", type: "event-location-filter" });
    filters.push({ name: "Description", type: "event-description-filter" });
    filters.push({ name: "Event Type", type: "event-type-filter" });
    filters.push({ name: "Tags", type: "event-tags-filter" });
    filters.push({ name: "Department", type: "event-department-filter" });
    filters.push({ name: "Date", type: "event-date-filter" });
    this.filters = filters.sort(sortByName);
  }

  public addFilter(f: FilterItem) {
    this.controller.ok(createFilter(f.type, filter => {
      if (f.data) {
        Object.assign(filter, f.data);
      }
    }));
  }

  private get ffContactNestedSearch(): boolean {
    return this.ffs.isFeatureFlagEnabled("ContactNestedSearch");
  }

  public get ffComplexDataAttachments(): boolean {
    return this.ffs.isFeatureFlagEnabled("ComplexDataAttachments");
  }

  private get canViewEventFilters(): boolean {
    return this.ffs.isFeatureFlagEnabled("ContactsEventFiltersOUT4562Jul2024") &&
      this.ffs.isFeatureFlagEnabled("CalendarModule") &&
      this.eventPermissionService.canView;
  }
}
