export enum AvailableFieldType {
  Number = 0,
  Boolean = 1,
  Date = 2,
  String = 3
}

export class AttachmentCollection {
  name: string = null;
  path: string = null;
  available_fields: AvailableField[] = [];
}

export class AvailableField {
  path: string = null;
  display_name: string = null;
  type: AvailableFieldType = null;
  values: string[] = [];
}

export class AttachmentMappingSample {
  collections: AttachmentCollection[] = [];
}
