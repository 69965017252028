import { FrameworkConfig } from "fw";

// components
import { TextField } from "views/components/text-field";
import { TextFieldAdorned } from "views/components/text-field-adorned";
import { EncryptedTextField } from "views/components/encrypted-text-field";
import { DatetimeField } from "views/components/datetime-field";
import { DropdownField } from "views/components/dropdown-field";
import { DropdownTimeField } from "views/components/dropdown-time-field";
import { DropdownWithFilter } from "views/components/dropdown-with-filter";
import { DropdownWithFilterFormField } from "views/components/form/dropdown-with-filter-form-field";
import { Alert } from "views/components/alert";
import { DestroyAlert } from "views/components/destroy-alert";
import { CheckboxField } from "views/components/checkbox-field";
import { TextareaField } from "views/components/textarea-field";
import { SwitchField } from "views/components/switch-field";
import { RangeField } from "views/components/range-field";
import { MultiselectField } from "views/components/multiselect-field";
import { TagsField } from "views/components/tags-field";
import { ActionButton } from "views/components/action-button";
import { ColorField } from "views/components/color-field";

import { TextFormField } from "views/components/form/text-form-field";
import { TextFormFieldAdorned } from "views/components/form/text-form-field-adorned";
import { EncryptedTextFormField } from "views/components/form/encrypted-text-form-field";
import { TextareaFormField } from "views/components/form/textarea-form-field";
import { CheckboxFormField } from "views/components/form/checkbox-form-field";
import { DropdownFormField } from "views/components/form/dropdown-form-field";
import { Validation } from "views/components/form/validation";
import { FieldValidation } from "views/components/form/field-validation";
import { DatetimeFormField } from "views/components/form/datetime-form-field";
import { TimeFormField } from "views/components/form/time-form-field";
import { SwitchFormField } from "views/components/form/switch-form-field";
import { Filters } from "views/components/filters";
import { ColorFormField } from "views/components/form/color-form-field";
import { ImageUploaderFormField } from "views/components/form/image-uploader-form-field";


import { Popover } from "views/components/popover";
import { Tooltip } from "views/components/tooltip";
import { ScrollView } from "views/components/scroll-view";
import { FormattedDate } from "views/components/formatted-date";
import { Currency } from "views/components/currency";
import { Loader } from "views/components/loader";
import { ReadonlyField } from "views/components/readonly-field";

import { Avatar } from "views/components/avatar";
import { UserAvatar } from "views/components/user-avatar";
import { TeamAvatar } from "views/components/team-avatar";
import { PrincipalAvatar } from "views/components/principal-avatar";
import { AllOf } from "views/components/all-of";
import { AnyOf } from "views/components/any-of";

import { Icon } from "views/components/icon";
import { FeatureFlag } from "views/components/feature-flag";
import { Steps } from "./views/components/stepper/steps";
import { Step } from "./views/components/stepper/step";
import { LazyUser } from "views/components/lazy-user";
import { LazyTeam } from "views/components/lazy-team";
import { LazyValue } from "views/components/lazy-value";
import { LazyActor } from "views/components/lazy-actor";

import { WebsocketMessage } from "views/components/websocket-message";
import { BadgeWithIcon } from "./views/components/badge-with-icon";
import { Information } from "./views/components/information";
import { Divider } from "./views/components/divider";
import { PageToolbar } from "./views/components/page-toolbar";
import { GlobalUser } from "views/components/global-user";
import { TimezoneAbbreviation } from "./views/components/timezone-abbreviation";
import { WeightSelector } from "views/components/weight-selector/weight-selector";
import { CircularProgressbar } from "views/components/circular-progressbar";
import { AlertMessage } from "views/components/alert-message";
import { StickyMiniHeader } from "./views/components/sticky-mini-header";
import { AuthLogo } from "views/login/auth-logo";
import { FormatBytes } from "views/components/format-bytes";

export function registerComponents(c: FrameworkConfig) {
  c.registerComponents(
    ReadonlyField,
    TextField,
    TextFieldAdorned,
    EncryptedTextField,
    DatetimeField,
    RangeField,
    MultiselectField,
    TagsField,
    DropdownField,
    DropdownTimeField,
    DropdownWithFilter,
    DropdownWithFilterFormField,
    CheckboxField,
    TextareaField,
    TextFormField,
    TextFormFieldAdorned,
    Alert,
    DestroyAlert,
    EncryptedTextFormField,
    SwitchField,
    TextareaFormField,
    CheckboxFormField,
    DropdownFormField,
    SwitchFormField,
    Validation,
    FieldValidation,
    DatetimeFormField,
    TimeFormField,
    ColorField,
    ColorFormField,
    Tooltip,
    Popover,
    ScrollView,
    FormattedDate,
    Currency,
    Loader,
    CircularProgressbar,
    Avatar,
    UserAvatar,
    TeamAvatar,
    PrincipalAvatar,
    AllOf,
    AnyOf,
    Icon,
    FeatureFlag,
    WeightSelector,
    Steps,
    Step,
    Filters,
    LazyUser,
    LazyTeam,
    LazyValue,
    LazyActor,
    WebsocketMessage,
    BadgeWithIcon,
    ActionButton,
    Information,
    Divider,
    PageToolbar,
    GlobalUser,
    TimezoneAbbreviation,
    AlertMessage,
    StickyMiniHeader,
    AuthLogo,
    FormatBytes
  );
}
