import { inject, prop, ComponentEventBus } from "fw";
import { NestedSearchGroupFilter as NestedSearchGroupFilterModel } from "models/contacts-filters";

@inject
export class NestedSearchGroupFilter {
  @prop(null) filter: NestedSearchGroupFilterModel;

  public constructor(private ceb: ComponentEventBus) { }

  public get filterFieldLabel(): string {
    return this.filter.field.display_name;
  }

  public get filterNestedSearchLabel(): string {
    return this.filter.nestedSearch.display_name;
  }

  public get proxyFilter(): NestedSearchGroupFilterModel {
    return this.filter;
  }

  public set proxyFilter(val: NestedSearchGroupFilterModel) {
    this.ceb.dispatch("update:filter", val);
  }
}
