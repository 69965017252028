import { Validators, FormForType, formFor, Validator } from "fw-model";

import { ApplicationSource, ApplicationSourceJavascriptTransformation, ApplicationSourceMappingTransformation, ApplicationSourceTransformationType, ColumnMapping } from "models/application-source";

import { eventNotificationFormCreator } from "forms/scheduled-export";
import { isValidJavascript } from "./validators";
import { Override } from "helpers/ts-helpers";
import { EventNotification } from "models/scheduled-export";
import { getNestingLevel } from "helpers/application-source";
const { wrap, required } = Validators;

export type ApplicationSourceFormType = Override<FormForType<ApplicationSource>, {
  MappingTransformation: FormForType<ApplicationSourceMappingTransformation>
  JavascriptTransformation: FormForType<ApplicationSourceJavascriptTransformation>
  Notifications: FormForType<EventNotification>[]
}>;

export class UpdateApplicationSourceAction {
  constructor(public form: ApplicationSourceFormType) {}
}

export class AddApplicationSourceAction {
  constructor(public form: ApplicationSourceFormType) {}
}


export const applicationSourceJavascriptTransformationFormCreator = formFor(ApplicationSourceJavascriptTransformation, s => {
  s.field(u => u.IncludeFunctionIds, "Include Functions");
  s.requiredField(u => u.Function, "function (data, filename)", wrap(isValidJavascript));
});

export const columnMappingFormCreator = formFor(ColumnMapping, (s) => {
  s.field(u => u.From, "From", wrap(requiredOneOfField));
  s.field(u => u.FromScript, "From Script", wrap(requiredOneOfField));
  s.field(u => u.FromValue, "From Value", wrap(requiredOneOfField));
  s.requiredField(u => u.To, "To");
  s.field(u => u.TranslationTableId, "Translation Table");
});

export const applicationSourceMappingTransformationFormCreator = (form: ApplicationSourceMappingTransformation, isDynamicProgram: boolean) => formFor(ApplicationSourceMappingTransformation, s => {
  s.form(u => u.Operation, "Operation", columnMappingFormCreator);
  s.form(u => u.Program, "Program", isDynamicProgram && form.Program ? columnMappingFormCreator : () => null);
  s.requiredField(u => u.Sample, "Paste in a sample of your CSV or JSON import");
  s.field(u => u.SampleName, "Sample File Name");
  s.field(u => u.SampleUploadedOnUtc, "Sample Uploaded On");
  s.requiredField(u => u.Operation, "Operation");

  if (isDynamicProgram) {
    s.field(u => u.Program, "Program", 
      v => v.if(m => m.Operation?.FromValue !== "update"
        || !!m.Operation?.FromScript
        || !!m.Operation?.From, required));
  }

  s.formArray(u => u.Identifiers, "Identifiers", columnMappingFormCreator);
  s.field(u => u.Identifiers, "Identifiers", wrap(requireIdentifiers, requireExternalIdentifierOnCreate));
  s.formArray(u => u.Columns, "Columns", columnMappingFormCreator);
  s.field(u => u.Columns, "Columns", 
    v => v.if(m => m.Operation?.FromValue === "upsert", 
        requireOptionalField([
          { path: "applicant.givenName", name: "First Name"},
          { path: "applicant.familyName", name: "Last Name"},
          { path:"applicant.emailAddress", name: "Email Address"}]
        )
      ));
})(form);

export const applicationSourceFormCreator = (form: ApplicationSource) => formFor(ApplicationSource, s => {
  s.requiredField(u => u.Name, "Name");
  s.requiredField(a => a.FileSourceId, "File Source");
  s.field(a => a.ProgramId, "Program");
  s.field(a => a.Path, "Path");
  s.field(a => a.Active, "Active");
  s.field(a => a.Visibility, "Visibility");

  s.requiredField(a => a.FileCountType, "Expected File Record Count");
  s.formArray(u => u.Notifications, "Notifications", eventNotificationFormCreator);
  s.field(a => a.TransformationType, "Transformation Type");
  s.field(a => a.FileMask, "File Mask");
  s.form(a => a.MappingTransformation, "Mapping", form?.TransformationType === ApplicationSourceTransformationType.Mapping
    ? (thing) => applicationSourceMappingTransformationFormCreator(thing, !form?.ProgramId)
    : () => null);
  s.form(a => a.JavascriptTransformation, "Transform", form?.TransformationType !== ApplicationSourceTransformationType.Mapping
    ? applicationSourceJavascriptTransformationFormCreator 
    : () => null);
})(form) as unknown as ApplicationSourceFormType;

const requiredOneOfField = (input, column: ColumnMapping) => {
  const isNotEmpty = [
    column.From,
    column.FromValue,
    column.FromScript
  ].some((input) => !!input);

  if (isNotEmpty) {
    return null;
  }

  return "One of From inputs is required"
}

const requireOptionalField = (fields: { path: string, name: string}[]) => (input, mapping: ApplicationSourceMappingTransformation) => {
  const missingFields = fields.filter(({ path }) => !mapping.Columns.some((column) => column.To === path));
  
  if (missingFields.length === 0) {
    return null;
  }

  const fieldNames = missingFields.map(({ name }) => name);

  return `${fieldNames.join(",")} ${missingFields.length > 1 ? 'are': 'is' } required for Insert/Update operation`;
}

const requireIdentifiers = (input, mapping: ApplicationSourceMappingTransformation) => {
  if (mapping.Identifiers?.length || mapping.Operation?.FromValue == "ignore") {
    return null;
  }

  return "Identifiers are required"
}

const requireExternalIdentifierOnCreate = (input, mapping: ApplicationSourceMappingTransformation) => {
  const isRequiredExternalIdentifier = mapping.Operation?.FromValue === "upsert";
  
  if (isRequiredExternalIdentifier && !mapping.Identifiers?.some(x => x.To == "externalIdentifier")) {
    return "External Identifier is required for 'Create or Update' operation"
  }

  return null;
}