import { fromClass } from "fw-model";
import { MarketingOutreachListObject } from "state/marketing-outreach-list";

export class MarketingEmailTemplateDesign {
  Id: number = null;
  Name: string = null;
  Description: string = null;
  ContentType: 'html' | 'markup' = null;
  Html: string = null;
  Markup: string = null;
  File: string = null;
  Layout: string = null;
}

export class MarketingEmailTemplate implements MarketingOutreachListObject {
  Id: number = null;
  Name: string = null;
  AudienceType: string = null;
  Subject: string = null;
  Deleted: number = null;
  FromName: string = null;
  FromAddress: string = null;
  ReplyToName: string = null;
  ReplyToAddress: string = null;
  Alignment: string = 'center';
  EmailTemplateDesignId: number = null;
  ContentType: 'html' | 'markup' = null;
  Html: string = null;
  Text: string = null;
  Markup: string = null;
  HtmlFile: string = null;
  TextFile: string = null;
  MarkupFile: string = null;

  @fromClass design: MarketingEmailTemplateDesign;
}
