import { inject } from "fw";
import { Store, handle } from "fw-state";

import { OutreachStats } from "models/outreach-stats";

const DEFAULT_PAGE_SIZE = 20;

interface OutreachListShape<T> {
  loaded: boolean;
  outreachId: string;
  outreachSentId: string;
  outreachStats: OutreachStats;
  outreachStatsTarget: any;
  instanceKey: string;
  collection: Array<T>;
  totalCount: number;
  toggle: boolean;
  search: string;
  aggs: string;
  sort: string;
  selectAll: boolean;
  selected: number[];
  pageSize: number;
  currentPage: number;
  lastPage: number;
  pageStartNumber: number;
  pageEndNumber: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  canAdvancePage: boolean;
  latestSent: T;
}

export interface MarketingOutreachListObject {
  Id: number;
}

export class SetSelectAll {
  constructor(
    public store: MarketingOutreachListStore<any>,
    public selectAll: boolean
  ) {}
}

export class SetSelect {
  constructor(
    public store: MarketingOutreachListStore<any>,
    public selected: number[]
  ) {}
}

export class SetToggle {
  constructor(
    public store: MarketingOutreachListStore<any>,
    public toggle: boolean
  ) {}
}

export class SetSearchTerm {
  constructor(
    public store: MarketingOutreachListStore<any>,
    public searchTerm: string
  ) {}
}

export class SetSort {
  constructor(
    public store: MarketingOutreachListStore<any>,
    public column: string,
    public order: string
  ) {}
}

export class SetPage {
  constructor(
    public store: MarketingOutreachListStore<any>,
    public page: number
  ) {}
}

@inject
export class MarketingOutreachListStore<T> extends Store<OutreachListShape<T>> {
  defaultState() {
    return {
      loaded: false,
      outreachId: null,
      outreachSentId: '',
      outreachStats: null,
      outreachStatsTarget: null,
      instanceKey: '',
      collection: [] as Array<T>,
      totalCount: 0,
      toggle: true,
      search: '',
      aggs: '',
      sort: 'Id DESC',
      selectAll: false,
      selected: [],
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      lastPage: 1,
      pageStartNumber: 1,
      pageEndNumber: DEFAULT_PAGE_SIZE,
      hasPreviousPage: false,
      hasNextPage: false,
      canAdvancePage: false,
      latestSent: null,
    };
  }

  @handle(SetSelectAll)
  private handleSetSelectAll(action: SetSelectAll) {
    action.store.setState(state => ({
      ...state,
      selectAll: action.selectAll
    }));
  }

  @handle(SetSelect)
  private handleSetSelect(action: SetSelect) {
    action.store.setState(state => ({
      ...state,
      selected: action.selected
    }));
  }

  @handle(SetSearchTerm)
  private handleSetSearchTerm(action: SetSearchTerm) {
    action.store.setState(state => ({
      ...state,
      currentPage: 1,
      search: action.searchTerm
    }));
  }

  @handle(SetToggle)
  private handleSetToggle(action: SetToggle) {
    action.store.setState(state => ({
      ...state,
      currentPage: 1,
      toggle: action.toggle
    }));
  }

  @handle(SetSort)
  private handleSetSort(action: SetSort) {
    action.store.setState(state => ({
      ...state,
      currentPage: 1,
      sort: action.column + " " + action.order.toUpperCase()
    }));
  }

  @handle(SetPage)
  private handleSetPage(action: SetPage) {
    action.store.setState(state => ({
      ...state,
      currentPage: action.page
    }));
  }
}
