import { fromClass } from "fw-model";
import { VisibilityStatus } from "./visibility-status";

export enum FileSourceType {
  ManagedS3 = 0,
  CustomS3 = 1,
  Sftp = 2
}

export class FileSourceCustomS3Provider {
  Region: string = null;
  BucketName: string = null;
  BasePath: string = null;
  WildcardFileFilter: string;
  AccessKeyId: string = null;
  SecretAccessKey: string = null;
}

export class FileSourceSftpProvider {
  Host: string;
  Port: number;
  BasePath: string;
  WildcardFileFilter: string;
  Username: string;
  Password: string;
  SshVaultKeyId: string;
  HasPrivateKey: boolean;
}

export class FileSourceManagedS3MetaData {
  AccessKeyIds: string[]
  UseExternalCredentials: boolean;
}

export class FileSourceMetaData {
  OrganizationId: string;
  Type: FileSourceType;
  IsManaged: boolean = false;

  @fromClass ManagedS3: FileSourceManagedS3MetaData;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

export class FileSource {
  Id: string;
  Name: string = null;
  Visibility: VisibilityStatus = VisibilityStatus.Visible;

  @fromClass CustomS3: FileSourceCustomS3Provider;
  @fromClass Sftp: FileSourceSftpProvider;

  @fromClass MetaData: FileSourceMetaData;
}

export class FileSourceManagedS3PostResult {
  AccessKeyId: string;
  SecretAccessKey: string;
}

export class FileSourcePostResult {
  @fromClass FileSource: FileSource;
  @fromClass ManagedS3: FileSourceManagedS3PostResult;
}
