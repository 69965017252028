import { fromClassArray } from "fw-model";
import { DeepPascalToSnake } from "helpers/casing";
import { ConditionInfo } from "../../../form-runtime/src/models";

export enum DefaultContactTypes {
  applicant = "applicant"
}

export class ContactTypeDefinition {
  key: string = null;
  name: string = null;
  description?: string = null;
  plural_name: string = null;
  color: string = null;
  icon: string = null;
  use_full_name: boolean = false;
  is_default: boolean = false;
  order: number = 0;
}

export function createCustomFieldDefinition(contactType: string, type: CustomFieldType, displayName?: string, isCalculated: boolean = false, expression?: string): ICustomFieldDefinition {
  return <ICustomFieldDefinition>{
    display_name: displayName,
    contact_type: contactType,
    type: type,
    is_indexed: canIndex(type),
    identifier_mode: IdentifierMode.None,
    visibility: CustomFieldVisibility.AlwaysShow,
    is_calculated: isCalculated,
    is_read_only: isCalculated,
    expression: isCalculated ? expression : null
  }
}

function canIndex(type: CustomFieldType): boolean {
  switch (type) {
    case CustomFieldType.coursework:
    case CustomFieldType.attachment:
      return false;
    default: return true;
  }
}

export class ViewFieldDefinition {
  field_id: string = null;
  name: string = null;
  order: number = 0;
}

export class ViewSectionDefinition {
  id: string = null;
  name: string = null;
  column_span: number = 1;
  order: number = 0;
  @fromClassArray(ViewFieldDefinition) fields: ViewFieldDefinition[];
}

export class ViewDefinition {
  id: string = null;
  name: string = null;
  contact_type: string = null;
  @fromClassArray(ViewSectionDefinition) sections: ViewSectionDefinition[];
}

export interface ICustomFieldDefinitionBase {
  id: string;
  name: string;
  display_name: string;
  display_order: number;
  search_field: string;
  type: CustomFieldType;
  options?: ICustomFieldOption[];
  created_utc?: Date;
  updated_utc?: Date;
}

export class NestedSearchFieldDefinition implements ICustomFieldDefinitionBase {
  id: string = null;
  name: string = null;
  display_name: string = null;
  display_order: number = 0;
  search_field: string = null;
  type: CustomFieldType = CustomFieldType.string;
  options?: ICustomFieldOption[] = [];
  created_utc?: Date = null;
  updated_utc?: Date = null;
  path: string = null;
}

export class NestedSearch {
  id: string = null;
  name: string = null;
  display_name: string = null;
  display_order: number = 0;
  path: string = null;
  transform_expression?: string = null;
  fields: NestedSearchFieldDefinition[] = [];
}

export interface ICustomFieldDefinition extends ICustomFieldDefinitionBase {
  visibility: CustomFieldVisibility;
  is_indexed: boolean;
  identifier_mode: IdentifierMode;
  is_system_field: boolean;
  is_read_only: boolean;
  field_name: string;
  custom_field_group_id: string;
  is_calculated: boolean;
  expression: string;
  is_expression_disabled: boolean;
  data?: { [key: string]: any };
  contact_type: string;
  is_suppressed?: boolean;
  data_source_instance_ids: string[];
  data_source_instance_priorities: string[];
  nested_fields?: ICustomFieldDefinitionBase[];
  nested_searches?: NestedSearch[];
}

export interface IPinnedProperty {
  is_field: string;
  name: string;
  contact_type: string;
}

export type ContactConditionInfo = DeepPascalToSnake<ConditionInfo>;

export interface ICustomFieldOptionBase {
  id: string;
  name: string;
  display_order: number;
  is_enabled: boolean;
  color?: string;
  condition?: string;
  condition_info?: ContactConditionInfo;
}

export interface ICustomFieldOption extends ICustomFieldOptionBase {
  aliases?: ICustomFieldOptionBase[];
  _selected?: boolean;
}

export enum CustomFieldVisibility {
  Hidden,
  Show,
  AlwaysShow
}

export enum EmailMatchingMode {
  Email = 0,
  EmailPlusFirstAndLastName = 1
}

export enum IdentifierMode {
  None = 0,
  Instance = 1,
  DataSource = 2,
  Global = 3,
  Email = 4
}

export enum CustomFieldType {
  number = 0,
  boolean = 1,
  date = 2,
  string = 3,
  largestring = 4,
  dropdown = 5,
  address = 6,
  email = 7,
  phone = 8,
  social = 9,
  link = 10,
  relationship = 11,
  table = 12,
  slideroomapplications = 13,
  multiselect = 14,
  slideroomadmissionsapplications = 15, // obsolete
  testscore = 16,
  tags = 17,
  user = 18,
  postalcode = 19,
  country = 20,
  concealed = 21,
  funnel = 22,
  attachment = 23,
  coursework = 24,
}

export enum ContactFieldsMatch {
  EmailAddress = "email_address"
}

export type ContactRestrictionType = (
  "FieldDefinition" |
  "ContactTypeVisibility"
);

export class ContactTypeDataPolicy {
  contact_type: string;
  is_hidden: boolean;
  segment_id: string;
  restricted_field_ids: string[];
}

export class RoleSettings {
  role_id: string;
  @fromClassArray(ContactTypeDataPolicy) data_policies: ContactTypeDataPolicy[];
}

export class ContactOrganization {
  id: string;
  name: string;
  email_matching_mode: EmailMatchingMode;
  enable_auto_discover_user_info: boolean;
  enable_auto_discover_contact_info: boolean;
  enable_contact_number_sequencing: boolean;
  contact_types: ContactTypeDefinition[];
  @fromClassArray(RoleSettings) roles: RoleSettings[];
  views: ViewDefinition[];
  fields: ICustomFieldDefinition[];
  pinned_properties: IPinnedProperty[]
  data_source_instance_priorities: string[];
  data?: { [key: string]: any };
  created_utc?: Date;
  version: string;
}

export const EmptyContactOrganization: ContactOrganization = {
  id: null,
  name: null,
  email_matching_mode: EmailMatchingMode.Email,
  enable_auto_discover_user_info: true,
  enable_auto_discover_contact_info: true,
  enable_contact_number_sequencing: false,
  contact_types: [],
  roles: [],
  views: [],
  fields: [],
  pinned_properties: [],
  data_source_instance_priorities: [],
  version: null
};

export function getFields(organization: ContactOrganization, contactType: string = null, fieldType: CustomFieldType = null): ICustomFieldDefinition[] {
  if (!organization?.fields || organization.fields.length === 0)
    return [];

  const hasContactType = contactType?.length > 0;
  const hasFieldType = !!fieldType;

  if (hasContactType && hasFieldType)
    return organization.fields.filter(f => f.contact_type === contactType && f.type === fieldType);
  if (hasContactType)
    return organization.fields.filter(f => f.contact_type === contactType);
  if (hasFieldType)
    return organization.fields.filter(f => f.type === fieldType);
  return organization.fields;
}

export function isPathExcluded(fieldType: CustomFieldType) {
  switch (fieldType) {
    case CustomFieldType.attachment:
    case CustomFieldType.coursework:
    case CustomFieldType.concealed:
      return true;

    default: return false;
  }
}
export function isObject(fieldType: CustomFieldType) {
  switch (fieldType) {
    case CustomFieldType.address:
    case CustomFieldType.attachment:
    case CustomFieldType.coursework:
    case CustomFieldType.link:
    case CustomFieldType.relationship:
    case CustomFieldType.slideroomadmissionsapplications:
    case CustomFieldType.slideroomapplications:
    case CustomFieldType.table:
    case CustomFieldType.testscore:
    case CustomFieldType.user:
      return true;

    default: return false;
  }
}
export function isSortable(fieldType: CustomFieldType) {
  return isObject(fieldType) === false;
}

export function getFieldNamesFromIndexes(organization: ContactOrganization, searchFields: string | string[]): string[] {
  if (!searchFields || searchFields.length === 0 || !organization?.fields || organization.fields.length === 0)
    return [];

  const list = (typeof searchFields === 'string'
    ? searchFields.split(",") || []
    : searchFields);
  if (list.length === 0)
    return [];

  const names: string[] = [];
  list.forEach(searchField => {
    if (!searchField)
      return;

    const field = organization.fields.find(f => f.search_field === searchField);
    if (!field?.display_name)
      return;

    names.push(field.display_name);
  });

  return names;
}

export function getRoleSettings(organization: ContactOrganization, roleId: string): RoleSettings {
  if (!organization || !roleId)
    return null;
  return organization.roles?.find(r => r.role_id === roleId);
}

export function getContactTypeOrDefault(organization: ContactOrganization, contactType: string = null, roleId: string = null): ContactTypeDefinition {
  if (!contactType)
    return getDefaultContactType(organization, roleId);

  const restricted = getRoleSettings(organization, roleId)?.data_policies?.filter(d => d.is_hidden)?.map(d => d.contact_type);
  if (!restricted || restricted.length === 0 || restricted.indexOf(contactType) === -1)
    return organization.contact_types.find(t => t.key === contactType) || getDefaultContactType(organization, roleId);

  return getDefaultContactType(organization, roleId);
}

export function getDefaultContactType(organization: ContactOrganization, roleId: string = null): ContactTypeDefinition {
  if (!organization)
    return null;

  const applicant = organization.contact_types.find(f => f.key === DefaultContactTypes.applicant);
  if (!roleId)
    return applicant;

  const restricted = getRoleSettings(organization, roleId)?.data_policies?.filter(d => d.is_hidden)?.map(d => d.contact_type);
  if (!restricted || restricted.length === 0)
    return applicant;

  if (restricted.indexOf(applicant.key) === -1)
    return applicant;

  return organization.contact_types.filter(t => restricted.indexOf(t.key) === -1)[0];
}

export const defaultCompletionFieldTypes = [
  CustomFieldType.address,
  CustomFieldType.date,
  CustomFieldType.dropdown,
  CustomFieldType.funnel,
  CustomFieldType.email,
  CustomFieldType.largestring,
  CustomFieldType.number,
  CustomFieldType.phone,
  CustomFieldType.string,
  CustomFieldType.user,
];
