import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS, ICountResult } from "./ats";
import { Operation } from 'fast-json-patch';
import {
  ContactOrganization,
  type ICustomFieldDefinition,
  RoleSettings,
  ViewDefinition,
} from "models/contact-organization";
import { AttachmentMappingSample } from "models/contact-attachment-mapping";

@inject
export class ContactOrganizationRepository {
  constructor(private s: ATS) { }

  async count(f: string = null, aggs: string = null): Promise<ICountResult> {
    return await this.s.contacts.count("api/v1/organizations/count", f, aggs);
  }

  async getById(id: string): Promise<ContactOrganization> {
    const res = await this.s.contacts.get(`organizations/${id}`);
    return createFrom(ContactOrganization, res.body);
  }

  public async patch(id: string, version: string, data: Operation[]): Promise<ContactOrganization> {
    const res = await this.s.contacts.patch<ContactOrganization>(`organizations/${id}`, data, { version: version });
    return createFrom(ContactOrganization, res.body);
  }

  public async listFields(contactType: string): Promise<ICustomFieldDefinition[]> {
    const res = await this.s.contacts.get<ICustomFieldDefinition[]>(`organizations/type/${contactType}/field`);
    return res.body;
  }

  public async getField(contactType: string, fieldId: string): Promise<ICustomFieldDefinition> {
    const res = await this.s.contacts.get<ICustomFieldDefinition>(`organizations/type/${contactType}1/field/${fieldId}`);
    return res.body;
  }

  public async addField(fields: ICustomFieldDefinition[]): Promise<ICustomFieldDefinition[]> {
    const res = await this.s.contacts.post<ICustomFieldDefinition[]>(`organizations/type/${fields[0].contact_type}/field`, fields);
    return res.body;
  }

  public async saveField(field: ICustomFieldDefinition, patches: Operation[]): Promise<ICustomFieldDefinition> {
    const res = await this.s.contacts.patch<ICustomFieldDefinition>(`organizations/type/${field.contact_type}/field/${field.id}`, patches);
    return res.body;
  }

  public async deleteField(field: ICustomFieldDefinition) {
    await this.s.contacts.delete<void>(`organizations/type/${field.contact_type}/field/${field.id}`);
  }

  public async saveRoleSettings(roleSettings: RoleSettings): Promise<RoleSettings> {
    const res = await this.s.contacts.put<RoleSettings>(`organizations/role-settings/${roleSettings.role_id}`, roleSettings);
    return res.body;
  }

  public async deleteRoleSettings(roleId: string) {
    if (!roleId)
      return;

    await this.s.contacts.delete<void>(`organizations/role-settings/${roleId}`);
  }

  public async listViews(contactType: string): Promise<ViewDefinition[]> {
    const res = await this.s.contacts.get<ViewDefinition[]>(`organizations/type/${contactType}/view`);
    return res.body;
  }

  public async getView(contactType: string, viewId: string): Promise<ViewDefinition> {
    const res = await this.s.contacts.get<ViewDefinition>(`organizations/type/${contactType}1/view/${viewId}`);
    return res.body;
  }

  public async addView(views: ViewDefinition[]): Promise<ViewDefinition[]> {
    const res = await this.s.contacts.post<ViewDefinition[]>(`organizations/type/${views[0].contact_type}/view`, views);
    return res.body;
  }

  public async saveView(view: ViewDefinition, patches: Operation[]): Promise<ViewDefinition> {
    const res = await this.s.contacts.patch<ViewDefinition>(`organizations/type/${view.contact_type}/view/${view.id}`, patches);
    return res.body;
  }

  public async deleteView(view: ViewDefinition) {
    await this.s.contacts.delete<void>(`organizations/type/${view.contact_type}/view/${view.id}`);
  }

  public async getAttachmentMappingSample(json: string): Promise<AttachmentMappingSample> {
    const res = await this.s.contacts.post<AttachmentMappingSample>('organizations/attachment-fields/mapping-sample', json);
    return createFrom(AttachmentMappingSample, res.body);
  }
}
