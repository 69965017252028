import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { FileSource, FileSourceType, FileSourcePostResult, FileSourceManagedS3PostResult } from "models/file-source";

@inject
export class FileSourceRepository {
  constructor(private s: ATS) {}

  async list() {
    const res = await this.s.get<any[]>("file-source");
    return createFromArray(FileSource, res.body);
  }

  async get(id: string) {
    const res = await this.s.get<any[]>(`file-source/${id}`);
    return createFrom(FileSource, res.body);
  }

  async post(fs: FileSource, type = FileSourceType.ManagedS3) {
    const res = await this.s.post("file-source", fs, { type });

    return createFrom(FileSourcePostResult, res.body);
  }

  async update(fs: FileSource) {
    const res = await this.s.put(`file-source/${fs.Id}`, fs);

    return createFrom(FileSource, res.body);
  }

  async addAccessKey(fs: FileSource) {
    const res = await this.s.post(`file-source/${fs.Id}/manageds3/accesskey`, null);
    return createFrom(FileSourceManagedS3PostResult, res.body);
  }

  async deleteAccessKey(fs: FileSource, key: string) {
    await this.s.delete(`file-source/${fs.Id}/manageds3/accesskey/${key}`);
  }

  async del(ids: string[]) {
    await this.s.post("file-source/delete", ids);
  }
}
