import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer } from "../../models";

@inject
export class LongTextType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledby;

  @prop(null) requestCeeb;
  @prop(null) fileHash;
  @prop(null) fileService;
  @prop(null) showOptions;
  @prop(null) decryptData;

  @prop(null) summarize: Function;

  constructor(private ecb: ComponentEventBus) { }

  onClick(event) {
    const handleBlur = () => this.onBlur(
      () => event.target.removeEventListener("blur", handleBlur)
    );
    event.target.addEventListener("blur", handleBlur);
  }
  onBlur(cb)  {
    this.answer.Text = this.answer.Text?.trim();
    this.change();
    cb();
  }

  onSummarize(event) {
    this.summarize(this.answer.Text);
  }

  change() {
    this.ecb.dispatch("answer-changed");
  }
}
