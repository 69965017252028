import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { ApplicationSource, ApplicationSourceHistory } from "models/application-source";

@inject
export class ApplicationSourceRepository {
  constructor(private s: ATS) {}

  async list() {
    const res = await this.s.get<any[]>("application-source");
    return createFromArray(ApplicationSource, res.body);
  }

  public async listHistory(id: string, s = null, sort = null, page = 1, pageSize = 20) {
    const res = await this.s.get<any[]>(`application-source/${id}/history`, { s, sort, page, pageSize });
    return { list: createFromArray(ApplicationSourceHistory, res.body), total: parseInt(res.headers["x-ats-total"], 10) };
  }

  public async retry(appSourceId:string, historyId: string) {
        await this.s.post(`application-source/${appSourceId}/history/${historyId}/retry`, null);
  }

  async post(fs: ApplicationSource) {
    const res = await this.s.post("application-source", fs);

    return createFrom(ApplicationSource, res.body);
  }

  async getTransformation(fs: ApplicationSource, fileName: string): Promise<string> {
    const res = await this.s.post("application-source/transformation", fs, {
      fileName
    });

    return res.body as string;
  }

  async update(fs: ApplicationSource) {
    const res = await this.s.put(`application-source/${fs.Id}`, fs);

    return createFrom(ApplicationSource, res.body);
  }

  async del(ids: string[]) {
    await this.s.post("application-source/delete", ids);
  }
}
