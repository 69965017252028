const LIST_OF_KEYS_TO_CLEAR = [
  "ats-bearer-token-lifespan",
  "ats-portal-id",
  "use-organization-switcher",
  "ats-bearer-token",
  "ats-bearer-token-expiration-date",
  "ats-impersonation-bearer-token",
  "domain",
  "custom-domain-content-base-url",
];

export const clearStorages = () => {
  LIST_OF_KEYS_TO_CLEAR.forEach((key) => {
    localStorage.removeItem(key);
  });
};
