import { inject } from "fw";
import { createFrom } from "fw-model";
import { handle, Store, dispatch } from "fw-state";

import { CurrentUserStore } from "state/current-user";
import { ApplicationContextCacheService } from "service/application-context-cache-service";
import { Program, ProgramStage, ProgramStep, ProgramStepGroup } from "models/program";
import { Application, ApplicationStep, ApplicationStepGroup, ApplicationComment } from "models/application";
import { EvaluationPhasesRepository } from "network/evaluation-phases-repository";
import { Form } from "models/form";
import { Applicant } from "models/applicant";
import { ApplicantAccount } from "models/applicant-account";
import { File } from "models/file";
import { FileRepository } from "network/file-repository";
import { DocumentRepository } from "network/document-repository";
import { ApplicationClientModel, EntitySelection } from "models/application-client-model";
import { ApplicationReference } from "models/application-reference";
import { Document } from "models/document";
import { EvaluationPhase } from "models/evaluation-phase";
import { ApplicationEvaluation } from "models/application-evaluation";
import { GenerateDecisionLetterActionNode } from "models/workflow-node";
import { RunRulesAction } from "state/evaluation-phases";
import { ApplicantRepository } from "network/applicant-repository";
import {
  AddApplicationAttachmentAction,
  DeleteApplicationAttachmentAction,
} from "forms/application-attachment";
import { FileWatcher } from "service/file-watcher";
import {
  ApplicationWorkflowEvent,
  WebSocketMessageAction,
  filterWebsocketMessage,
} from "./filter-websocket-message";

import {
  LogoutAction,
  LoadApplicationAction,
  ApplyApplicationStageChangeAction,
  ApplyApplicationPhaseChangeAction,
  RefreshDashboardDataAction,
  ApplyRefreshClientModelAction,
  RefreshClientModelAction,
  ApplyUpdateApplicationPropertiesAction,
  ApplyDeleteApplicationAction,
  TaskFinishedAction,
  ApplyTagApplicationsAction,
  RefreshDecisionLetterModelAction,
} from "./actions";

import { AppRepository } from "network/app-repository";
import { ApplicationRepository } from "network/application-repository";
import { ProgramRepository } from "network/program-repository";
import { FormRepository } from "network/form-repository";
import { WorkflowRepository } from "network/workflow-repository";

import { isAnswered } from "shared/form-runtime";

import { flatten, difference } from "lodash-es";
import { WorkflowRun } from "models/application-workflow-run";
import { DecisionCode } from "models/decision-settings";
import { FeatureFlagService } from "service/feature-flag";
import { TaskService } from "service/task";
import { TaskRequestStatusTypeCode } from "models/task-request";
import { PaymentDetails } from "models/payments";
import { DecisionLetterTemplateSetRepository } from "../network/decision-letter-template-set-repository";
import { Notification } from "service/notification";
import { ApplicationEvaluationContainer } from "models/application-evaluation-container ";

export class ChangeApplicationStageAction {
  public taskId: string = null;

  constructor(public selection: EntitySelection, public stage: ProgramStage, public programId: string) {}
}

export class AuditFilterChangeAction {
  constructor(public searchText: string) {}
}

export class EnrollApplicationInWorkflowAction {
  public newRun: WorkflowRun;

  constructor(public applicationId: string, public workflowId: string) {}
}

export class SkipWorkflowWaitAction {
  public newRun: WorkflowRun;

  constructor(public runId: string, public nodeId: string) {}
}

export class UpdateDocumentStepFilesAction {
  constructor(public step: ApplicationStep, public documentFileIds: string[]) {}
}

export class UpdateApplicationFormStepAction {
  constructor(public updatedStep: ApplicationStep, public seenQuestionKeys: string[]) {}
}

export class ChangeApplicationPhaseAction {
  public taskId: string = null;

  constructor(public selection: EntitySelection, public phase: EvaluationPhase) {}
}

export class ChangeApplicationDecisionAction {
  public taskId: string = null;

  constructor(public selection: EntitySelection, public decision: DecisionCode) {}
}

export class GenerateDecisionLetterAction {
  public taskId: string = null;

  constructor(public id: string = null, public applicationIds: string[] = null) {}
}

export class DeleteDecisionLetterAction {
  constructor() {}
}

export class SaveEvaluationAction {
  constructor(
    public evaluation: ApplicationEvaluation,
    public seenQuestionKeys: string[],
    public evalForm: Form
  ) {}
}

export class UpdateApplicationWaivedFeesAction {
  constructor(public applicationId: string, public instructions: { [stepGroupId: string]: boolean }) {}
}

export class UnlockApplicationFormStepAction {
  constructor(public step: ApplicationStep) {}
}

export class UpdateApplicationWaivedDeadlinesAction {
  constructor(public applicationId: string, public instructions: { [stepGroupId: string]: boolean }) {}
}

export class UpdateApplicationPropertiesAction {
  public taskId: string = null;

  constructor(
    public applicationId: string,
    public propertyValues: { [key: string]: any },
    public fileIds: string[]
  ) {}
}

export class UpdateApplicantPropertiesAction {
  constructor(
    public applicantId: string,
    public propertyValues: { [key: string]: any },
    public fileIds: string[]
  ) {}
}

export class DeleteApplicationAction {
  constructor(public applicationId: string) {}
}

export class ReindexApplicationsAction {
  constructor(public applicationIds: string[]) {}
}

type SelectedTab = "info" | "rate" | "comments" | "audits" | "none";

export class ToggleTabAction {
  constructor(public tab: SelectedTab, public allowSameTab?: boolean) {}
}

export class ToggleSidebarAction {
  constructor(public collapse: boolean) {}
}

export class UpdateFileAction {
  constructor(public file: File) {}
}

export class AddFileAction {
  constructor(public fileId: string) {}
}

export class AddCommentAction {
  constructor(public applicationId: string, public comment: string) {}
}

export class UpdateCommentAction {
  constructor(public applicationId: string, public commentId: string, public comment: string) {}
}

export class DeleteCommentAction {
  constructor(public applicationId: string, public commentId: string) {}
}

export class EnsureCommentsAction {
  constructor(public applicationId: string) {}
}

// these types will have ids for both the application and the program
export type StepHash = {
  [id: string]: {
    programStep: ProgramStep;
    applicationStep: ApplicationStep;
  };
};

export type StepGroupHash = {
  [id: string]: {
    programStepGroup: ProgramStepGroup;
    applicationStepGroup: ApplicationStepGroup;
  };
};

export interface CurrentApplicationStoreShape {
  application: Application;
  applicant: Applicant;
  applicantAccount: ApplicantAccount;
  program: Program;
  evaluations: ApplicationEvaluation[];
  stageHash: { [id: string]: ProgramStage };
  stepHash: StepHash;
  clientModel: ApplicationClientModel;
  stepGroupHash: StepGroupHash;
  fileHash: { [id: string]: File };
  formHash: { [idAndVersion: string]: Form };
  documents: Document[];
  payments: PaymentDetails[];
  phases: ApplicationEvaluationContainer[];
  referenceHash: { [id: string]: ApplicationReference };
  selectedTab: SelectedTab;
  auditFilter: string;
  runs: WorkflowRun[];
  programPropertiesHash: Record<string, boolean | string>;

  comments: {
    loaded: boolean;
    loading: boolean;
    total: number;
    list: ApplicationComment[];
  };

  sidebarCollapsed: boolean;
}

@inject
export class CurrentApplicationStore extends Store<CurrentApplicationStoreShape> {
  constructor(
    private featureFlagService: FeatureFlagService,
    private appRepo: AppRepository,
    private programRepo: ProgramRepository,
    private applicantRepo: ApplicantRepository,
    private applicationRepository: ApplicationRepository,
    private workflowRepo: WorkflowRepository,
    private fileRepo: FileRepository,
    private documentRepo: DocumentRepository,
    private evalPhaseRepo: EvaluationPhasesRepository,
    private appContextCache: ApplicationContextCacheService,
    private formRepo: FormRepository,
    private fileWatcher: FileWatcher,
    private currentUser: CurrentUserStore,
    private taskService: TaskService,
    private decisionLetterRepo: DecisionLetterTemplateSetRepository,
    private notification: Notification
  ) {
    super();
  }

  defaultState(): CurrentApplicationStoreShape {
    return {
      application: null,
      applicant: null,
      applicantAccount: null,
      program: null,
      evaluations: null,
      stageHash: null,
      stepHash: null,
      clientModel: null,
      stepGroupHash: null,
      fileHash: null,
      documents: null,
      payments: null,
      phases: null,
      formHash: null,
      referenceHash: null,
      selectedTab: "info",
      auditFilter: null,
      runs: [],
      comments: {
        loaded: false,
        loading: false,
        list: [],
        total: 0,
      },
      sidebarCollapsed: false,
      programPropertiesHash: {},
    };
  }

  get isCurrentUserAssigned() {
    return (
      this.state.clientModel.phases.current != null &&
      this.state.clientModel.phases.current.assigned_users.filter((u) => {
        return u === this.currentUser.state.user?.Id;
      }).length > 0
    );
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState((s) => this.defaultState());
  }

  @handle(LoadApplicationAction)
  private async handleLoadApplication(l: LoadApplicationAction) {
    this.fileWatcher.clearAll();

    const firstApplicationCalls = await Promise.all([
      this.appRepo.applicationContext(l.id),
      this.applicationRepository.clientModel(l.id),
    ]);

    const applicationContext = firstApplicationCalls[0];
    const application = applicationContext.Application;
    const applicant = applicationContext.Applicant;
    const applicantAccount = applicationContext.ApplicantAccount;
    const references = applicationContext.References;
    const evaluations = applicationContext.Evaluations;
    const documents = applicationContext.Documents;
    const runs = applicationContext.WorkflowRuns;
    const payments = applicationContext.Payments;
    const phases = applicationContext.Phases;

    const clientModel = firstApplicationCalls[1];
    // now we need to make sure we have the program and steps loaded
    const program = await this.programRepo.get(application.MetaData.ProgramId);

    const stepHash: StepHash = {};
    const stepGroupHash: StepGroupHash = {};
    const stageHash: { [id: string]: ProgramStage } = {};

    program.Stages.forEach((stage) => {
      stageHash[stage.Id] = stage;

      stage.StepGroups.forEach((group) => {
        stepGroupHash[group.Id] = {
          applicationStepGroup: null,
          programStepGroup: group,
        };

        group.StepList.forEach((step) => {
          stepHash[step.Id] = {
            applicationStep: null,
            programStep: step,
          };
        });
      });
    });

    const applicationStepGroupIds = application.StepGroups.map((sg) => sg.MetaData.ProgramStepGroupId);
    const programStepGroupIds = stageHash[application.MetaData.StageId]?.StepGroups.map((sg) => sg.Id);

    const missingStepGroups = difference(applicationStepGroupIds, programStepGroupIds);

    if (missingStepGroups.length > 0) {
      const histories = await this.programRepo.listStepGroups(missingStepGroups);
      histories.forEach((h) => {
        stepGroupHash[h.Id] = {
          applicationStepGroup: null,
          programStepGroup: createFrom(ProgramStepGroup, h),
        };
      });
    }

    const missingProgramSteps: {
      appStepId: string;
      programStepId: string;
    }[] = [];
    const formAndVersionIds: string[] = [];

    const addFormVersion = (id: string, version: number) => {
      const fv = `${id}:${version}`;
      if (id == null || formAndVersionIds.indexOf(fv) >= 0) return;
      formAndVersionIds.push(fv);
    };

    // ok, lets see what we are missing from the application side
    // i think there is a case where a program group id can be different (like when a group got deleted...)
    application.StepGroups.forEach((group) => {
      stepGroupHash[group.MetaData.ProgramStepGroupId].applicationStepGroup = group;
      stepGroupHash[group.Id] = stepGroupHash[group.MetaData.ProgramStepGroupId];

      group.StepList.forEach((step) => {
        // load up the formAndVersionIds too

        if (step.Form != null && step.Form.MetaData != null) {
          const { FormId, FormVersion } = step.Form.MetaData;

          if (FormId != null && FormVersion != null) {
            addFormVersion(FormId, FormVersion);
          }
        }

        const existingStepHash = stepHash[step.MetaData.ProgramStepId];

        if (existingStepHash == null) {
          missingProgramSteps.push({
            appStepId: step.Id,
            programStepId: step.MetaData.ProgramStepId,
          });
        } else {
          stepHash[step.MetaData.ProgramStepId].applicationStep = step;
        }

        // setup the application side of steps
        // we will fill out the rest later
        stepHash[step.Id] = {
          applicationStep: step,
          programStep: existingStepHash ? existingStepHash.programStep : null,
        };
      });
    });

    references.forEach((ref) => addFormVersion(ref.MetaData.FormId, ref.MetaData.FormVersion));
    evaluations.forEach((evaluation) =>
      addFormVersion(evaluation.MetaData.FormId, evaluation.MetaData.FormVersion)
    );

    application.StepGroups.forEach((sg) => {
      sg.StepList.forEach((step) => {
        if (step.Folder != null && step.Folder.ItemList) {
          step.Folder.ItemList.forEach((item) =>
            addFormVersion(item.MetaData.FormId, item.MetaData.FormVersion)
          );
        }
      });
    });

    const stepsAndFormsCalls = await Promise.all([
      this.programRepo.listSteps(missingProgramSteps.map((p) => p.programStepId)),
      this.formRepo.versions(formAndVersionIds),
    ]);

    const steps = stepsAndFormsCalls[0];

    steps.forEach((step) => {
      const applicationStepId = missingProgramSteps.find(
        (missing) => missing.programStepId == step.Id
      ).appStepId;

      stepHash[step.Id] = {
        applicationStep: stepHash[applicationStepId].applicationStep,
        programStep: step,
      };

      stepHash[applicationStepId].programStep = step;
    });

    const fileHash: { [id: string]: File } = {};

    const { Files } = applicationContext;
    if (Files != null) {
      Files.forEach((file) => {
        fileHash[file.Id] = file;
        this.fileWatcher.watch(file);
      });
    }

    const formHash = stepsAndFormsCalls[1];

    const referenceHash: { [id: string]: ApplicationReference } = {};
    references.forEach((ref) => {
      referenceHash[ref.Id] = ref;
    });

    const programPropertiesHash: Record<string, boolean | string> = {};
    program.PropertyValues.forEach((property) => {
      programPropertiesHash[property.FieldKey] = property.Value;
    });

    const { comments } = this.defaultState();
    this.setState((state) => ({
      ...state,
      application,
      applicant,
      applicantAccount,
      program,
      evaluations,
      stageHash,
      stepHash,
      clientModel,
      stepGroupHash,
      fileHash,
      documents,
      payments,
      phases,
      formHash,
      referenceHash,
      runs,
      comments,
      programPropertiesHash,
    }));
  }

  @handle(UnlockApplicationFormStepAction)
  private async unlockFormStep(action: UnlockApplicationFormStepAction) {
    if (this.state.application == null) return;

    const steps = flatten(this.state.application.StepGroups.map((s) => s.StepList));

    const existingStep = steps.find((a) => a.Id == action.step.Id);
    if (existingStep == null) return;

    const stepGroup = this.state.application.StepGroups.find((s) =>
      s.StepList.some((ss) => ss.Id == existingStep.Id)
    );

    await this.applicationRepository.unlock(this.state.application.Id, stepGroup.Id, existingStep.Id);

    existingStep.Form.MetaData.IsLocked = false;
    this.setState((s) => s);
  }

  @handle(ChangeApplicationStageAction)
  private async handleChangeApplicationStageAction(action: ChangeApplicationStageAction) {
    const task = await this.applicationRepository.putStage(
      action.selection,
      action.stage.Id,
      action.programId
    );
    action.taskId = task.Id;
    await dispatch(new ApplyApplicationStageChangeAction(action.selection, action.stage));
  }

  @handle(AuditFilterChangeAction)
  private async handlAuditFilterChangeAction(action: AuditFilterChangeAction) {
    this.setState((state) => ({
      ...state,
      auditFilter: action.searchText,
    }));
  }

  @handle(ApplyApplicationStageChangeAction)
  private handleApplyApplicationStageChange(action: ApplyApplicationStageChangeAction) {
    if (this.state.application == null) {
      return;
    }

    if (action.selection.ids.some((a) => a == this.state.application.Id) == false) {
      return;
    }

    this.state.application.MetaData.StageId = action.stage.Id;
    this.state.clientModel.stage = action.stage.Name;
  }

  @handle(ChangeApplicationPhaseAction)
  private async handleChangeApplicationPhaseAction(action: ChangeApplicationPhaseAction) {
    const phaseId = action.phase?.Id;
    const task = await this.applicationRepository.putPhase(action.selection, phaseId);
    action.taskId = task.Id;

    if (this.featureFlagService.isFeatureFlagEnabled("PutPhaseAndAssignTask") === false) {
      // this is all fake shit, if they are not assigned.
      // run the rules for the one application.. (trickle)
      if (action.selection.ids.length == 1) {
        let newClientModel = await this.applicationRepository.clientModel(action.selection.ids[0], false);

        if (phaseId) {
          await this.evalPhaseRepo.runRules(phaseId, action.selection);
          newClientModel = await this.applicationRepository.clientModel(action.selection.ids[0], false);
        }

        this.setState((state) => ({ ...state, clientModel: newClientModel }));

        await dispatch(new RefreshDashboardDataAction());
      } else {
        await dispatch(new RunRulesAction(phaseId, action.selection));
      }
    }
    await dispatch(new ApplyApplicationPhaseChangeAction(action.selection, action.phase));
  }

  @handle(ChangeApplicationDecisionAction)
  private async handleChangeApplicationDecisionAction(action: ChangeApplicationDecisionAction) {
    const task = await this.applicationRepository.putDecision(
      action.selection,
      action.decision == null ? null : action.decision.Id
    );
    action.taskId = task.Id;

    if (this.state.application != null && action.selection.ids.includes(this.state.application.Id)) {
      const updatedClientModel = await this.applicationRepository.clientModel(
        this.state.application.Id,
        false
      );

      this.setState((state) => ({
        ...state,
        clientModel: updatedClientModel,
        application: {
          ...state.application,
          MetaData: {
            ...state.application.MetaData,
            DecisionId: action.decision == null ? null : action.decision.Id,
          },
        },
      }));
    }
  }

  @handle(GenerateDecisionLetterAction)
  private async handleGenerateDecisionLetterAction(action: GenerateDecisionLetterAction) {
    try {
      const task = await this.decisionLetterRepo.generatePdf(action.id, action.applicationIds);
      action.taskId = task.Id;
      return this.notification.notify(
        `Decision Letter${action.applicationIds.length > 1 ? "`s" : ""} generation starts`
      );
    } catch (err) {
      this.setState((state) => ({ ...state, loaded: true, errorLoading: true }));
      return this.notification.error(
        `Failed to generate Decision Letter${action.applicationIds.length > 1 ? "`s" : ""}`
      );
    }
  }

  @handle(DeleteDecisionLetterAction)
  private async handleDeleteDecisionLetterAction(action: DeleteDecisionLetterAction) {
    try {
      await this.decisionLetterRepo.deleteDecisionLetter(this.state.application.Id);
      await dispatch(new RefreshClientModelAction(this.state.application.Id));
      return this.notification.notify(`Decision Letter successfully deleted`);
    } catch (err) {
      this.setState((state) => ({ ...state, loaded: true, errorLoading: true }));
      return this.notification.error(`Failed to delete Decision Letter`);
    }
  }

  @handle(ApplyApplicationPhaseChangeAction)
  private handleApplyApplicationPhaseChange(action: ApplyApplicationPhaseChangeAction) {
    if (this.state.application == null) return;

    if (action.selection.ids.some((a) => a == this.state.application.Id)) {
      this.state.application.MetaData.PhaseId = action.phase?.Id;
      this.state.clientModel.phase = action.phase?.Name;
    }
  }

  @handle(ToggleTabAction)
  private handleToggleTabAction(action: ToggleTabAction) {
    this.setState((state) => ({
      ...state,
      selectedTab: action.tab,
    }));
  }

  @handle(ToggleSidebarAction)
  private handleToggleSidebarAction(action: ToggleSidebarAction) {
    this.setState((state) => ({
      ...state,
      sidebarCollapsed: action.collapse,
    }));
  }

  @handle(SaveEvaluationAction)
  private async handleSaveEvaluationAction(action: SaveEvaluationAction) {
    if (this.state.application == null) return;

    const { Id, MetaData } = this.state.application;

    const getQuestion = (key: string) => {
      for (let i = 0; i < action.evalForm.Sections.length; i++) {
        const question = action.evalForm.Sections[i].Questions.find((q) => q.Key == key);
        if (question != null) return question;
      }
      return null;
    };

    if (action.evaluation != null && action.evaluation.Answers != null) {
      action.evaluation.Answers = action.evaluation.Answers.filter(
        (a) =>
          action.seenQuestionKeys.some((sq) => a.QuestionKey == sq) &&
          isAnswered(getQuestion(a.QuestionKey), a)
      );
    }

    if (action.evaluation.Id == null) {
      const res = await this.applicationRepository.postEvaluation(
        Id,
        MetaData.PhaseId,
        action.evaluation,
        action.seenQuestionKeys
      );

      if (Id != this.state.application.Id) return;

      if (this.state.evaluations == null) {
        this.state.evaluations = [];
      }

      // do we have the form?
      const formIdAndVersion = `${res.MetaData.FormId}:${res.MetaData.FormVersion}`;

      if (!this.state.formHash[formIdAndVersion]) {
        const form = await this.formRepo.versions([formIdAndVersion]);
        this.state.formHash = {
          ...this.state.formHash,
          [formIdAndVersion]: form[formIdAndVersion],
        };
      }

      this.setState((state) => ({
        ...state,
        evaluations: [...state.evaluations, res],
      }));

      this.appContextCache.updateEvalIfCached(Id, res);
    } else {
      const res = await this.applicationRepository.putEvaluation(
        Id,
        action.evaluation,
        action.seenQuestionKeys
      );
      this.appContextCache.updateEvalIfCached(Id, res);
      if (Id != this.state.application.Id) return;

      const existingObject = this.state.evaluations.find((e) => e.Id == action.evaluation.Id);
      if (existingObject == null) return;

      Object.assign(existingObject, res);

      this.setState((s) => s);
    }

    await dispatch(new RefreshDashboardDataAction());
    await dispatch(new RefreshClientModelAction(Id));
  }

  @handle(AddApplicationAttachmentAction)
  private async handleAddApplicationAttachmentAction(action: AddApplicationAttachmentAction) {
    const attachment = await this.applicationRepository.postAttachment(
      action.applicationId,
      action.form.updatedModel()
    );
    const file = await this.fileRepo.get(attachment.FileId);

    this.state.application.Attachments.push(attachment);
    this.fileWatcher.watch(file);

    this.setState((state) => ({
      ...state,
      fileHash: {
        ...state.fileHash,
        [file.Id]: file,
      },
    }));
  }

  @handle(DeleteApplicationAction)
  private async handleDeleteApplicationAction(action: DeleteApplicationAction) {
    if (action.applicationId != this.state.application.Id) {
      return;
    }

    await this.applicationRepository.delete(action.applicationId);
    await dispatch(new ApplyDeleteApplicationAction(action.applicationId));
  }

  @handle(ReindexApplicationsAction)
  private async handleReindexApplicationsAction(action: ReindexApplicationsAction) {
    if (!action.applicationIds.includes(this.state.application.Id)) {
      return;
    }

    await this.applicationRepository.reindexApplications(action.applicationIds);
  }

  @handle(DeleteApplicationAttachmentAction)
  private async handleDeleteApplicationAttachmentAction(action: DeleteApplicationAttachmentAction) {
    if (action.applicationId != this.state.application.Id) {
      return;
    }

    const attachment = this.state.application.Attachments.find((a) => a.Id == action.attachmentId);
    if (attachment == null) return;

    this.fileWatcher.stopWatching(attachment.FileId);

    await this.applicationRepository.deleteAttachments(action.applicationId, [action.attachmentId]);

    const idx = this.state.application.Attachments.indexOf(attachment);
    this.state.application.Attachments.splice(idx, 1);

    this.setState((s) => s);
  }

  @handle(UpdateApplicantPropertiesAction)
  private async handleUpdateApplicantPropertiesAction(action: UpdateApplicantPropertiesAction) {
    if (action.applicantId != this.state.applicant.Id) return;

    await this.applicantRepo.putPropertyValues([action.applicantId], action.propertyValues);

    // see if we need to update the fileHash
    const getFileIds: string[] = [];
    for (const fileId of action.fileIds) {
      if (this.state.fileHash[fileId] == null) {
        getFileIds.push(fileId);
      }
    }

    if (getFileIds.length > 0) {
      const files = await this.fileRepo.list(getFileIds);

      const newFileHash = { ...this.state.fileHash };
      for (const file of files) {
        newFileHash[file.Id] = file;
        this.fileWatcher.watch(file);
      }

      this.setState((state) => ({
        ...state,
        fileHash: newFileHash,
      }));
    }

    await dispatch(new RefreshClientModelAction(this.state.application.Id));
  }

  @handle(UpdateApplicationPropertiesAction)
  private async handleUpdateApplicationPropertiesAction(action: UpdateApplicationPropertiesAction) {
    if (action.applicationId != this.state.application.Id) {
      return;
    }

    const selection = new EntitySelection();
    selection.ids = [action.applicationId];

    const task = await this.applicationRepository.putPropertyValues(selection, action.propertyValues);
    action.taskId = task.Id;

    // see if we need to update the fileHash
    const getFileIds: string[] = [];
    for (const fileId of action.fileIds) {
      if (this.state.fileHash[fileId] == null) getFileIds.push(fileId);
    }

    if (getFileIds.length > 0) {
      const files = await this.fileRepo.list(getFileIds);

      const newFileHash = { ...this.state.fileHash };
      for (const file of files) {
        newFileHash[file.Id] = file;
        this.fileWatcher.watch(file);
      }

      this.setState((state) => ({
        ...state,
        fileHash: newFileHash,
      }));
    }

    await dispatch(new RefreshClientModelAction(action.applicationId));
  }

  @handle(ApplyUpdateApplicationPropertiesAction)
  private handleApplyUpdateApplicationPropertiesAction(action: ApplyUpdateApplicationPropertiesAction) {
    if (this.state.application == null) {
      return;
    }

    if (action.selection.ids.some((a) => a == this.state.application.Id) == false) {
      return;
    }

    const property = this.state.application.PropertyValues.find((f) => f.FieldKey == action.fieldKey);

    if (property != null) {
      property.Value = action.value;
    }

    this.state.clientModel[action.fieldKey] = action.value;
  }

  @handle(UpdateDocumentStepFilesAction)
  private async handleUpdateDocumentStepFilesAction(action: UpdateDocumentStepFilesAction) {
    if (this.state.application == null) return;

    const steps = flatten(this.state.application.StepGroups.map((s) => s.StepList));

    const existingStep = steps.find((a) => a.Id == action.step.Id);
    if (existingStep == null) return;

    const stepGroup = this.state.application.StepGroups.find((s) =>
      s.StepList.some((ss) => ss.Id == existingStep.Id)
    );

    const updatedStep = await this.applicationRepository.putStep(
      this.state.application.Id,
      stepGroup.Id,
      existingStep.Id,
      action.step,
      action.documentFileIds
    );
    Object.assign(existingStep, updatedStep);

    const currentDocumentIds = this.state.documents.map((d) => d.Id);
    const documentsToGet = difference(updatedStep.Documents.DocumentIdList, currentDocumentIds);

    if (documentsToGet.length > 0 || action.documentFileIds.length > 0) {
      const documents = this.state.documents;
      const fileHash = { ...this.state.fileHash };

      if (documentsToGet.length > 0) {
        const docs = await this.documentRepo.list(documentsToGet);
        documents.push(...docs);
      }

      if (action.documentFileIds.length > 0) {
        const newFiles = await this.fileRepo.list(action.documentFileIds);
        newFiles.forEach((f) => {
          fileHash[f.Id] = f;
          this.fileWatcher.watch(f);
        });
      }

      this.setState((state) => ({
        ...state,

        documents,
        fileHash,
      }));
    } else {
      this.setState((s) => s);
    }
  }

  @handle(UpdateApplicationFormStepAction)
  private async handleUpdateApplicationFormStepAction(action: UpdateApplicationFormStepAction) {
    if (this.state.application == null) return;

    const steps = flatten(this.state.application.StepGroups.map((s) => s.StepList));

    const existingStep = steps.find((a) => a.Id == action.updatedStep.Id);
    if (existingStep == null) return;

    const stepGroup = this.state.application.StepGroups.find((s) =>
      s.StepList.some((ss) => ss.Id == existingStep.Id)
    );

    await this.applicationRepository.putStep(
      this.state.application.Id,
      stepGroup.Id,
      existingStep.Id,
      action.updatedStep,
      null,
      action.seenQuestionKeys
    );

    this.appContextCache.deleteContext(this.state.application.Id);
    await dispatch(new LoadApplicationAction(this.state.application.Id));
  }

  @handle(UpdateApplicationWaivedFeesAction)
  private async handleUpdateApplicationWaivedFeesAction(action: UpdateApplicationWaivedFeesAction) {
    if (this.state.application == null || this.state.application.Id != action.applicationId) {
      return;
    }

    await this.applicationRepository.waiveFees(action.applicationId, action.instructions);

    const stepGroupIds = Object.keys(action.instructions);
    const stepGroups = this.state.application.StepGroups.filter((sg) =>
      stepGroupIds.some((ssg) => ssg == sg.Id)
    );

    stepGroups.forEach((sg) => (sg.WaiveFee = action.instructions[sg.Id]));

    this.setState((s) => s);
  }

  @handle(UpdateApplicationWaivedDeadlinesAction)
  private async handleUpdateApplicationWaivedDeadlinesAction(action: UpdateApplicationWaivedDeadlinesAction) {
    if (this.state.application == null || this.state.application.Id != action.applicationId) {
      return;
    }

    await this.applicationRepository.waiveDeadlines(action.applicationId, action.instructions);

    const stepGroupIds = Object.keys(action.instructions);
    const stepGroups = this.state.application.StepGroups.filter((sg) =>
      stepGroupIds.some((ssg) => ssg == sg.Id)
    );

    stepGroups.forEach((sg) => (sg.WaiveDeadline = action.instructions[sg.Id]));

    this.setState((s) => s);
  }

  @handle(AddFileAction)
  private async handleAddFileAction(f: AddFileAction) {
    const file = await this.fileRepo.uploadComplete(f.fileId);

    this.setState((state) => ({
      ...state,
      fileHash: {
        ...state.fileHash,
        [file.Id]: file,
      },
    }));

    this.fileWatcher.watch(file);
  }

  @handle(UpdateFileAction)
  private handleUpdateFileAction(f: UpdateFileAction) {
    if (this.state.fileHash == null) return;

    const currentFile = this.state.fileHash[f.file.Id];

    if (currentFile == null) return;

    Object.assign(currentFile, f.file);
    this.setState((s) => s);
    this.appContextCache.deleteContext(this.state.application.Id);
  }

  @handle(ApplyTagApplicationsAction)
  private async handleApplyTagApplicationsAction(action: ApplyTagApplicationsAction) {
    if (this.state.application == null) {
      return;
    }

    if (!action.selection.ids.includes(this.state.application.Id)) {
      return;
    }

    for (const tag of action.addTags) {
      if (this.state.application.Tags == null) this.state.application.Tags = [];
      if (this.state.clientModel.tags == null) this.state.clientModel.tags = [];

      if (!this.state.application.Tags.includes(tag)) this.state.application.Tags.push(tag);
      if (!this.state.clientModel.tags.includes(tag)) this.state.clientModel.tags.push(tag);
    }

    for (const tag of action.removeTags) {
      if (this.state.application.Tags == null) this.state.application.Tags = [];
      if (this.state.clientModel.tags == null) this.state.clientModel.tags = [];

      if (this.state.application.Tags.includes(tag)) {
        const idx = this.state.application.Tags.indexOf(tag);
        this.state.application.Tags.splice(idx, 1);
      }
      if (this.state.clientModel.tags.includes(tag)) {
        const idx = this.state.clientModel.tags.indexOf(tag);
        this.state.clientModel.tags.splice(idx, 1);
      }
    }

    this.setState((s) => s);
  }

  @handle(EnsureCommentsAction)
  private async handleEnsureCommentsAction(action: EnsureCommentsAction) {
    if (this.state.application == null || this.state.application.Id != action.applicationId) return;
    if (this.state.comments.loaded) return;

    this.setState((state) => ({
      ...state,
      comments: {
        ...state.comments,
        loading: true,
      },
    }));

    const comments = await this.applicationRepository.getComments(action.applicationId);

    // re check this, just incase they nav away during a comment load
    if (this.state.application == null || this.state.application.Id != action.applicationId) return;

    this.setState((state) => ({
      ...state,
      comments: {
        ...state.comments,
        list: comments.comments,
        total: comments.total,
        loading: false,
        loaded: true,
      },
    }));
  }

  @handle(AddCommentAction)
  private async handleAddCommentAction(action: AddCommentAction) {
    if (this.state.application == null || this.state.application.Id != action.applicationId) return;

    const newComment = await this.applicationRepository.addComment(action.applicationId, action.comment);

    if (this.state.application == null || this.state.application.Id != action.applicationId) return;
    this.setState((state) => ({
      ...state,
      comments: {
        ...state.comments,
        list: [newComment, ...state.comments.list],
        total: state.comments.total + 1,
      },
    }));
  }

  @handle(UpdateCommentAction)
  private async handleUpdateCommentAction(action: UpdateCommentAction) {
    if (this.state.application == null || this.state.application.Id != action.applicationId) return;

    const updatedComment = await this.applicationRepository.updateComment(
      action.applicationId,
      action.commentId,
      action.comment
    );
    const existingComment = this.state.comments.list.find((c) => c.Id == updatedComment.Id);
    if (existingComment) {
      Object.assign(existingComment, updatedComment);
    }
  }

  @handle(DeleteCommentAction)
  private async handleDeleteCommentAction(action: DeleteCommentAction) {
    if (this.state.application == null || this.state.application.Id != action.applicationId) return;

    const deletedComment = await this.applicationRepository.deleteComment(
      action.applicationId,
      action.commentId
    );

    if (this.state.application == null || this.state.application.Id != action.applicationId) return;
    this.setState((state) => ({
      ...state,
      comments: {
        ...state.comments,
        total: state.comments.total - 1,
        list: state.comments.list.filter((c) => c.Id != deletedComment.Id),
      },
    }));
  }

  @handle(EnrollApplicationInWorkflowAction)
  private async handleEnrollApplicationInWorkflowAction(action: EnrollApplicationInWorkflowAction) {
    const res = await this.workflowRepo.enroll(action.workflowId, action.applicationId);
    action.newRun = res;

    if (action.applicationId == this.state.application.Id) {
      this.setState((state) => ({
        ...state,
        runs: [...state.runs, res],
      }));

      if (
        res.Workflow.Path.some(
          (path) => (path as GenerateDecisionLetterActionNode).DecisionLetterTemplateSetId
        )
      ) {
        await dispatch(new RefreshDecisionLetterModelAction(action.applicationId));
      }
    }
  }

  @handle(WebSocketMessageAction, filterWebsocketMessage("ApplicationWorkflowEvent"))
  private async handleApplicationWorkflowEventAction(
    action: WebSocketMessageAction<ApplicationWorkflowEvent>
  ) {
    if ("CompletedWorkflow" !== action.data.subEventName) {
      return;
    }

    if (!this.state.application || this.state.application.Id != action.data.applicationId) {
      return;
    }

    const dbUpdateWaitSeconds = 2;
    if (await new Promise((resolve) => setTimeout(() => resolve(true), dbUpdateWaitSeconds * 1000))) {
      const latestRuns = await this.workflowRepo.listRuns("Application", action.data.applicationId);
      if (latestRuns.length) {
        this.setState((state) => ({
          ...state,
          runs: [...latestRuns],
        }));
      }
    }
  }

  @handle(SkipWorkflowWaitAction)
  private async handleSkipWorkflowWaitAction(action: SkipWorkflowWaitAction) {
    const res = await this.workflowRepo.skipWait(action.runId, action.nodeId);
    action.newRun = res;

    const existingRun = this.state.runs.find((r) => r.Id == res.Id);
    if (existingRun) {
      Object.assign(existingRun, res);
    }
  }

  @handle(RefreshDecisionLetterModelAction)
  private async handleRefreshDecisionLetterAction(action: RefreshDecisionLetterModelAction) {
    if (!this.state.application || this.state.application.Id != action.applicationId) {
      return;
    }

    const model = await this.applicationRepository.clientModel(action.applicationId, false);

    if (!model.decisionLetter.fileId) return;

    const file = await this.fileRepo.get(model.decisionLetter.fileId);
    this.state.fileHash[file.Id] = file;

    await dispatch(new ApplyRefreshClientModelAction(model));
  }

  @handle(RefreshClientModelAction)
  private async handleRefreshClientModelAction(action: RefreshClientModelAction) {
    if (!this.state.application || this.state.application.Id != action.applicationId) {
      return;
    }

    this.appContextCache.deleteClientModel(action.applicationId);
    const model = await this.applicationRepository.clientModel(action.applicationId);
    await dispatch(new ApplyRefreshClientModelAction(model));
  }

  @handle(ApplyRefreshClientModelAction)
  private handleApplyRefreshClientModelAction(action: ApplyRefreshClientModelAction) {
    if (this.state.application.Id != action.clientModel.id) {
      return;
    }

    this.setState((state) => ({
      ...state,
      clientModel: action.clientModel,
    }));
  }

  @handle(TaskFinishedAction)
  private async handleTaskFinishedAction(action: TaskFinishedAction) {
    const { application } = this.state;

    if (application == null || action.taskRequest.Status == TaskRequestStatusTypeCode.Error) {
      return;
    }

    const ids: string[] = this.taskService.getApplicationIds(action.taskRequest);
    if (!ids.includes(application.Id)) {
      return;
    }

    switch (action.taskRequest.Type) {
      case "CollaborationAssignment":
      case "DirectPhaseEvaluationAssignment":
      case "PatchApplicationValues": // This might not be triggered if there is no selection ids (only filter)
      case "RunAssignmentRules":
        await dispatch(new RefreshClientModelAction(application.Id));
        break;
      case "DeleteApplications":
        // TODO: add state to mark the current application as deleted?
        break;
      case "DecisionLetter":
        this.appContextCache.deleteContext(application.Id);
        await dispatch(new RefreshDecisionLetterModelAction(application.Id));

        break;
    }
  }
}
