// A few actions that multiple stores will use
import { AppOrganizationContext } from "models/app-organization-context";
import { ProgramStage } from "models/program";
import { EvaluationPhase } from "models/evaluation-phase";
import {
  ApplicationClientModel,
  EntitySelection,
} from "models/application-client-model";
import { TaskRequest } from "models/task-request";
import { User } from "models/user";
import { ExportTask } from "models/export-task";
import { Contact } from "models/contact";
import { ContactOrganization } from "models/contact-organization";
import { GroupFilter } from "models/filter-setup";
import { GridColumn } from "models/grid-column";
import { FeatureFlagSet } from "models/feature-flag-set";

export class ReplaceTokenAction {
  constructor(public token: string) {}
}

export class StartAction {
  constructor(public context: AppOrganizationContext) {}
}

export class LogoutAction {
  constructor(public reason: string = null) {}
}

export class RefreshTokenAction {}

export class SwitchOrganizationAction {
  constructor(public organizationId: string, storeLast = false) {}
}

export class ImpersonateUserAction {
  constructor(
    public organizationUserId: string,
    public portalId?: string,
    public isCurrentPortal?: boolean
  ) {}
}

export class StopImpersonatingAction {}

export class LoadApplicationAction {
  constructor(public id: string) {}
}

export class ApplyUserChangeAction {
  constructor(public user: User) {}
}

export class RemoveUsersFromTeams {
  constructor(public userIds: string[], public collabOnly = false) {}
}

export class TagApplicationsAction {
  public taskId: string = null;

  constructor(
    public selection: EntitySelection,
    public addTags: string[],
    public removeTags: string[],
    public instant: boolean = false
  ) {}
}

export class ApplyTagApplicationsAction {
  constructor(
    public selection: EntitySelection,
    public addTags: string[],
    public removeTags: string[]
  ) {}
}

export class ApplyApplicationStageChangeAction {
  constructor(public selection: EntitySelection, public stage: ProgramStage) {}
}

export class ApplyApplicationPhaseChangeAction {
  constructor(
    public selection: EntitySelection,
    public phase: EvaluationPhase
  ) {}
}

export class ApplyUpdateApplicationPropertiesAction {
  constructor(
    public selection: EntitySelection,
    public fieldKey: string,
    public value: any
  ) {}
}

export class ApplyDeleteApplicationAction {
  constructor(public applicationId: string) {}
}

export class RefreshDashboardDataAction {}

export class RefreshClientModelAction {
  constructor(public applicationId: string) {}
}

export class RefreshDecisionLetterModelAction {
  constructor(public applicationId: string) {}
}

export class ApplyRefreshClientModelAction {
  constructor(public clientModel: ApplicationClientModel) {}
}

export class RefreshContactModelAction {
  constructor(public contactId: string) {}
}

export class ContactModelChangedAction {
  constructor(public contact: Contact) {}
}

export class RefreshContactOrganizationModelAction {
  constructor(public organizationId: string) {}
}

export class ContactOrganizationModelChangedAction {
  constructor(public organization: ContactOrganization) {}
}

export class ApplyEvaluationPhasesAction {
  constructor(public newPhases: EvaluationPhase[]) {}
}

export class WatchTaskAction {
  constructor(public taskRequest: TaskRequest, public friendlyName?: string) {}
}

export class TaskUpdatedAction {
  constructor(public taskRequest: TaskRequest) {}
}

export class RetryExportAction {
  constructor(public exportTask: ExportTask, public watch = false) {}
}

export class EnsureTaskRequestAction {
  constructor(
    public taskRequestIds: string[],
    public friendlyName: string = ""
  ) {}
}

export class TaskFinishedAction {
  constructor(public taskRequest: TaskRequest) {}
}

export class RefreshFileProviderAction {
  constructor(public id: string) {}
}

export class SelectedContactTypeChangedAction {
  constructor(
    public type: string,
    public filter: GroupFilter,
    public contactsColumns: GridColumn[],
    public dedupeColumns: GridColumn[],
    public segmentId?: string
  ) {}
}

export class SelectedContactTypeFilterChangedAction {
  constructor(
    public selectedContactTypeKey: string,
    public filter: GroupFilter
  ) {}
}

export class ApplyFeatureFlagsAction {
  constructor(public flags: FeatureFlagSet) {}
}

export class UpdateInviteCountAction {}

export class PortalInvitationsRefreshListAction {}
