const USER_LOGGED_IN = "userLoggedIn";
const channel = new BroadcastChannel("login_channel");

const listener = (event: MessageEvent) => {
  if (event.data === USER_LOGGED_IN) {
    location.reload();
  }
};

export const CrossTabCommunicator = {
  broadcastLogin: () => {
    console.log("broadcastLogin");
    channel.postMessage(USER_LOGGED_IN);
  },

  listenForLogin: () => {
    channel.addEventListener("message", listener);
  },

  removeLoginListener: () => {
    channel.removeEventListener("message", listener);
  },
};
