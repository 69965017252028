import { FilterTermsResult, registerFilter } from "./filter-setup"
import { CustomFieldType, NestedSearch, NestedSearchFieldDefinition, type ICustomFieldDefinition } from "models/contact-organization";
import type { IRelationship } from "models/contact";
import { IDropdownFieldOptions } from "views/components/dropdown-field";
import { ITestScoreSectionMeta } from "models/testscore";
import { GroupFilter } from 'models/filter-setup';
import { DateTiming, TimingFilterType } from "models/filter-timing";
import { toNumber } from "lodash";
import { escapeTerm, getAddressFilters, getDateFilter, getLinkFilters, getMultiselectFilters, getOperatorFilters, getTagsFilters, getTestScoreFilters } from "./contacts-filter-builder";

export class ContactsContactNumberFilter {
  contactNumber: string = null;
}

export class ContactsKeywordFilter {
  keyword: string = null;
}

export class ContactsEmailFilter {
  email: string = null;
}

export class ContactsNameFilter {
  first: string = null;
  last: string = null;
  name: string = null;
  useFullName: boolean = false;
}

export class ContactsLinkFilter {
  field: ICustomFieldDefinition;
  name: string;
  url: string;
}

export class ContactsStringFilter {
  field: ICustomFieldDefinition;
  value: string = null;
}

export class ContactsKeyValueFilter {
  field: ICustomFieldDefinition;
  value: string = null;
}

export class ContactsErrorsFilter {
  hasErrors: boolean;
}

export class ContactsOperatorFilter {
  field: ICustomFieldDefinition;
  value: string = null;
  operator: string = null;
}

export class ContactsAddressFilter {
  field: ICustomFieldDefinition;
  city: string = null;
  state: string = null;
  country: string = null;
  postalCode: string = null;
}

export class ContactsOptionFilter {
  field: ICustomFieldDefinition;
  value: string | boolean | number = null;
  options: IDropdownFieldOptions[] = [];
}

export class ContactsMultiselectFilter {
  field: ICustomFieldDefinition;
  mode: string = null;
  values: string[] = [];
  options: string[] = [];
}

export class ContactsTagsFilter {
  field: ICustomFieldDefinition;
  mode: string = null;
  values: string[] = [];
  wildcardValue: string = null;
  wildcardSegments: string[] = [];
  options: string[] = [];
}

export class ContactsRelationshipFilter {
  field: ICustomFieldDefinition;
  mode: string = null;
  values: IRelationship[] = [];
}

export class ContactsUserFilter {
  field: ICustomFieldDefinition;
  mode: string = null;
  values: string[] = [];
}

export class ContactsSlideroomAdmissionsApplicationsFilter {
  field: ICustomFieldDefinition;
  program: string = null;
  phase: string = null;
  hasSubmitted: boolean = false;
}

export class ContactsSlideroomApplicationsFilter {
  field: ICustomFieldDefinition;
  program: string = null;
  status: string = null;
  hasSubmitted: boolean = false;
}

export class ContactsTestScoreFilter {
  field: ICustomFieldDefinition;
  sections: ITestScoreSectionMeta[] = [];
  section: string = null;
  operator: string = null;
  value?: number = null;
}

export class ContactsPostalCodeFilter {
  field: ICustomFieldDefinition;
  value?: string = null;
}

export class ContactsCountryFilter {
  field: ICustomFieldDefinition;
  value?: string = null;
}

export class ContactsDateFilter {
  field: ICustomFieldDefinition;
  title: string = "";
  occurrence: string = "";
  timing: DateTiming = TimingFilterType.Any;
  sinceDays?: number = null;
  startDate?: string = null;
  endDate?: string = null;
  daysFrom?: number = null;
  daysTo?: number = null;
  marker?: Array<string> | string;
  since?: string = null;

}

export class ContactsApplicationGroupFilter extends GroupFilter { }

export class ContactsActivityGroupFilter extends GroupFilter {
  occurrence: string = "";
  timing: string = "";
  since?: string = null;
  startDate?: string = null;
  endDate?: string = null;
  daysFrom?: number = null;
  daysTo?: number = null;
  marker?: Array<string> | string;
}

export type IntegrationInstance = { name: string, id: string };

export class NestedGroupFilter extends GroupFilter {
  field: ICustomFieldDefinition;
}

export class NestedSearchGroupFilter extends GroupFilter {
  field: ICustomFieldDefinition;
  nestedSearch: NestedSearch;
}

export class ContactsDataSourceInstanceFilter {
  contactType: string = null;
  integrations: IntegrationInstance[] = [];
  uploadIds: string[] = [];
  selectedIds: string[] = [];
  includeUploads: boolean = false;
  mode: string = null;
}

export class ContactsSegmentFilter {
  contactType: string = null;
  category: string = null;
  segmentId: string = null;
  operator: "==" | "!=" = "=="
}
export class ContactsEventFilter {
  status: string = "All";
  series: string = null;
  occurrence: string = "Any";
}

registerFilter(ContactsContactNumberFilter, f => {
  f.toFilterString(data => {
    return `contact_number:"${data.contactNumber}"`;
  });
  f.toFilterTerms(data => [{ term: "contact_number" }]);
});

export const registerContactsFilters = () => {
  registerFilter(ContactsKeywordFilter, f => {
    f.toFilterString(data => data.keyword);
    f.validate(data => data.required(d => d.keyword));
  });

  registerFilter(ContactsErrorsFilter, s => {
    s.toFilterString(data => `field_error_count:${data.hasErrors ? ">0" : "0"}`);
    s.toFilterTerms(data => [{ term: "field_error_count" }]);
  });

  registerFilter(ContactsEmailFilter, f => {
    f.toFilterString(data => {
      return `email:"${escapeTerm(data.email)}"`;
    });
    f.toFilterTerms(data => [{ term: "email" }]);
    f.validate(data => data.required(d => d.email));
  });

  registerFilter(ContactsNameFilter, f => {
    f.toFilterString(data => {
      let filter: string = "";
      if (data.first) {
        filter = `first:"${escapeTerm(data.first)}"`;
      }
      if (data.last) {
        filter += `${filter ? " AND " : ""}last:"${escapeTerm(data.last)}"`;
      }
      if (data.name) {
        const nameKey = data.useFullName ? "company" : "name";
        filter += `${filter ? " AND " : ""}${nameKey}:"${escapeTerm(data.name)}"`;
      }
      return filter;
    });
    f.toFilterTerms(data => {
      var paths: FilterTermsResult[] = [];
      if (data.first) {
        paths.push({ term: "first" });
      }

      if (data.last) {
        paths.push({ term: "last" });
      }

      if (data.name) {
        paths.push({ term: data.useFullName ? "company" : "name" });
      }

      return paths;
    });
    f.validate((v, data) => {
      if (data.useFullName) {
        v.required(d => d.name);
      } else if (!data.first && !data.last) {
        v.required(d => d.first);
        v.required(d => d.last);
      }
    });
  });

  registerFilter(ContactsStringFilter, f => {
    f.toFilterString(data => {
      return `${data.field.search_field}:"${escapeTerm(data.value)}"`;
    });
    f.toFilterTerms(data => [{ term: data.field.search_field }]);
    f.validate(v => v.required(group => group.value));
  });

  registerFilter(ContactsKeyValueFilter, f => {
    f.toFilterString(data => {
      if (data.value === null || data.value === "__missing__") {
        return `_missing_:${data.field.search_field}`;
      }

      return `${data.field.search_field}:"${escapeTerm(data.value)}"`;
    });
  });

  registerFilter(ContactsLinkFilter, f => {
    f.toFilterString(data => {
      return getLinkFilters(data);
    });
    f.toFilterTerms(data => {
      var paths: FilterTermsResult[] = [];
      if (data.name) {
        paths.push({ term: `${data.field.search_field}.name` });
      }

      if (data.url) {
        paths.push({ term: `${data.field.search_field}.url` });
      }

      return paths;
    });
  });

  registerFilter(ContactsAddressFilter, f => {
    f.toFilterString(data => {
      return getAddressFilters(data);
    });

    f.toFilterTerms(data => {
      var paths: FilterTermsResult[] = [];
      if (data.country) {
        paths.push({ term: `${data.field.search_field}.country` });
      }

      if (data.state) {
        paths.push({ term: `${data.field.search_field}.state` });
      }

      if (data.city) {
        paths.push({ term: `${data.field.search_field}.city` });
      }

      return paths;
    });

    f.validate((v, data) => {
      if (!data.city && !data.country && !data.state && !data.postalCode) {
        v.required(d => d.city);
        v.required(d => d.country);
        v.required(d => d.state);
        v.required(d => d.postalCode);
      }
    });
  });

  registerFilter(ContactsOperatorFilter, f => {
    f.toFilterString(data => {
      return getOperatorFilters(data);
    });
    f.toFilterTerms(data => [{ term: data.field.search_field }]);
    f.validate(v => v.required(group => group.value));
  });

  registerFilter(ContactsOptionFilter, f => {
    f.toFilterString(data => {
      if (data.value === "__missing__") {
        return `_missing_:${data.field.search_field}`
      }

      if (typeof data.value === "boolean" || typeof data.value === "number") {
        return `${data.field.search_field}:${data.value}`;
      }

      return `${data.field.search_field}:"${escapeTerm(data.value)}"`;
    });

    f.toFilterTerms(data => [{ term: data.field.search_field }]);
  });

  registerFilter(ContactsMultiselectFilter, f => {
    f.toFilterString(data => {
      return getMultiselectFilters(data);
    });

    f.toFilterTerms(data => [{ term: data.field.search_field }]);
  });

  registerFilter(ContactsTagsFilter, f => {
    f.toFilterString(data => {
      return getTagsFilters(data);
    });
    f.toFilterTerms(data => [{ term: data.field.search_field }]);
    f.validate(v => v.required(group => group.values));
  });

  registerFilter(ContactsRelationshipFilter, f => {
    f.toFilterString(data => {
      if (data.values == null || data.values.length == 0) {
        return "";
      }

      const filterStr = data.values
        .map(v => `${data.field.search_field}:"${escapeTerm(v.id)}"`)
        .join(data.mode === "all" ? " AND " : " OR ");

      return data.mode === "none" ? `-(${filterStr})` : `(${filterStr})`;
    });
    f.toFilterTerms(data => [{ term: data.field.search_field }]);
  });

  registerFilter(ContactsUserFilter, f => {
    f.toFilterString(data => {
      if (data.mode == "me") {
        return `(${data.field.search_field}:"me")`;
      }

      if (data.values == null || data.values.length == 0) {
        return "";
      }

      const filterStr = data.values
        .map(v => `${data.field.search_field}:"${escapeTerm(v)}"`)
        .join(data.mode === "all" ? " AND " : " OR ");

      return data.mode === "none" ? `-(${filterStr})` : `(${filterStr})`;
    });
    f.toFilterTerms(data => [{ term: data.field.search_field }]);
  });

  registerFilter(ContactsDataSourceInstanceFilter, f => {
    f.toFilterString(data => {
      if (data.selectedIds.length === 0 && data.uploadIds.length === 0)
        return "";

      let values = data.includeUploads ? data.selectedIds.concat(data.uploadIds) : data.selectedIds;
      let valueStr = "";

      if (values == null || values.length === 0)
        return valueStr;

      switch (data.mode) {
        case "all":
          values = values.map(v => "+" + v);
          valueStr = values.join(" ");
          break;

        case "none":
          values = values.map(v => "-" + v);
          valueStr = values.join(" ");
          break;

        default:
          valueStr = values.join(" OR ");
          break;
      }

      return `datasourceinstance:(${valueStr})`;
    });
    f.toFilterTerms(data => [{ term: "datasourceinstance" }]);
  });

  registerFilter(ContactsSlideroomAdmissionsApplicationsFilter, f => {
    f.toFilterString(data => {
      const filters: string[] = [];
      if (data.program) {
        filters.push(`program.name:"${escapeTerm(data.program)}"`);
      }

      if (data.phase) {
        filters.push(`phase:"${escapeTerm(data.phase)}"`);
      }

      if (data.hasSubmitted) {
        filters.push("_exists_:date_started");
      }

      return filters.length > 0
        ? `${data.field.search_field}:(${filters.join(" ")})`
        : "";
    });

    f.toFilterTerms(data => {
      var paths: FilterTermsResult[] = [];
      if (data.program) {
        paths.push({ term: `${data.field.search_field}.program.name` });
      }

      if (data.phase) {
        paths.push({ term: `${data.field.search_field}.phase` });
      }

      if (data.hasSubmitted) {
        paths.push({ term: `${data.field.search_field}.date_started` });
      }

      return paths;
    });
  });

  registerFilter(ContactsSlideroomApplicationsFilter, f => {
    f.toFilterString(data => {
      const filters: string[] = [];
      if (data.program) {
        filters.push(`program:"${escapeTerm(data.program)}"`);
      }

      if (data.status) {
        filters.push(`status:"${escapeTerm(data.status)}"`);
      }

      if (data.hasSubmitted) {
        filters.push("_exists_:submitted");
      }

      return filters.length > 0
        ? `${data.field.search_field}:(${filters.join(" ")})`
        : "";
    });

    f.toFilterTerms(data => {
      var paths: FilterTermsResult[] = [];
      if (data.program) {
        paths.push({ term: `${data.field.search_field}.program` });
      }

      if (data.status) {
        paths.push({ term: `${data.field.search_field}.status` });
      }

      if (data.hasSubmitted) {
        paths.push({ term: `${data.field.search_field}.submitted` });
      }

      return paths;
    });
  });

  registerFilter(ContactsTestScoreFilter, f => {
    f.toFilterString(data => {
      return getTestScoreFilters(data);
    });

    f.toFilterTerms(data => data.section && data.value ? [{ term: `${data.field.search_field}.${data.section}` }] : []);

    registerFilter(ContactsPostalCodeFilter, f => {
      f.toFilterString(data => {
        if (data.value === "" || data.value === "__missing__")
          return `_missing_:${data.field.search_field}.postal_code`;

        return `${data.field.search_field}.postal_code:"${escapeTerm(data.value)}"`;
      });
      f.toFilterTerms(data => [{ term: `${data.field.search_field}.postal_code` }]);
    });

    registerFilter(ContactsCountryFilter, f => {
      f.toFilterString(data => {
        if (data.value === "" || data.value === "__missing__")
          return `_missing_:${data.field.search_field}.country`;

        return `${data.field.search_field}.country:"${escapeTerm(data.value)}"`;
      });
      f.toFilterTerms(data => [{ term: `${data.field.search_field}.country` }]);
    });
  });

  registerFilter(ContactsSegmentFilter, s => {
    s.toFilterString(data => `${data.operator == "!=" ? '-' : ''}(@include:${data.segmentId})`);
    s.validate(v => v.required(d => d.segmentId));
  });

  registerFilter(ContactsEventFilter, s => {
    s.toFilterString(data => {
      const occurrenceString = data.occurrence && data.occurrence != "Any" ? `${data.occurrence}_` : "";

      if (["All", "None"].includes(data.status)) {
        let negationString = "";

        if (data.status === "None") {
          negationString = "-";
        }

        const filters = [
          `events.Registered:${occurrenceString}${data.series}`,
          `events.Attended:${occurrenceString}${data.series}`,
          `events.Cancelled:${occurrenceString}${data.series}`,
          `events.Waitlisted:${occurrenceString}${data.series}`,
        ];
        const filterString = filters.join(" OR ");

        return `${negationString}(${filterString})`;
      }

      return `(events.${data.status}:${occurrenceString}${data.series})`;
    });
    s.validate(v => v.required(d => d.status));
    s.validate(v => v.required(d => d.series));
  });

  registerFilter(ContactsApplicationGroupFilter, s => {
    s.toFilterString((filter, context) => {
      const filterString = filter.toFilterString(context);
      if (!filterString) {
        return "";
      }

      if (
        filterString === '@application:(*)' ||
        filterString === '-@application:(*)'
      ) return filterString;

      return filter.filters.length == 1
        ? `@application:(${filterString})`
        : `@application:${filterString}`;
    });
    s.validate(v => v.required(group => group.hasFilter));
    s.toFilterTerms((filter, context) => filter.toFilterTerms(context));
  });

  registerFilter(ContactsDateFilter, s => {
    s.toFilterString((filter) => {
      const filters = [];

      const searchField = filter.field.search_field;
      const timing = filter.since || filter.timing;

      switch (timing) {
        case TimingFilterType.Any:
          return `_exists_:${searchField}`
        case TimingFilterType.Empty:
          return `_missing_:${searchField}`
        case TimingFilterType.Today:
          filters.push(`${searchField}:[now/d TO now+1d/d]`);
          break;
        case TimingFilterType.Yesterday:
          filters.push(`${searchField}:[now-1d/d TO now/d]`);
          break;
        case TimingFilterType.Tomorrow:
          filters.push(`${searchField}:[now+1d/d TO now+2d/d]`);
          break;
        case TimingFilterType.Last7:
        case TimingFilterType.Last7Days:
          filters.push(`${searchField}:[now-7d/d TO now]`);
          break;
        case TimingFilterType.Month:
        case TimingFilterType.CurrentMonth:
          filters.push(`${searchField}:>=now/M`);
          break;
        case TimingFilterType.CurrentYear:
          filters.push(`${searchField}:>=now/y`);
          break;
        case TimingFilterType.LastX:
        case TimingFilterType.LastXDays:
          filters.push(`${searchField}:[now-${filter.sinceDays}d/d TO now]`);
          break;
        case TimingFilterType.DayRange:
          if (filter.daysTo) {
            filters.push(`${searchField}:[now-${filter.daysFrom}d/d TO now-${filter.daysTo}d/d]`);
          } else {
            filters.push(`${searchField}:>=now-${filter.daysFrom}d/d`);
          }
          break;
        case TimingFilterType.DateRange:
          if (filter.startDate && filter.endDate) {
            return `(${searchField}:[${filter.startDate} TO ${filter.endDate}])`;
          }
          break;
      }

      return filters.length > 0
        ? `(${filter.occurrence}${filters.join(" ")})`
        : "";
    });
    s.toFilterTerms(data => [{ term: data.field.search_field }]);
  });

  registerFilter(ContactsActivityGroupFilter, s => {
    s.toFilterString((filter, context) => {
      const filters = [];

      const timing = filter.since || filter.timing;
      const daysFrom = toNumber(filter.daysFrom);
      const sinceDays = toNumber(filter["sinceDays"]) ?? 1;
      const daysTo = toNumber(filter.daysTo);

      switch (timing) {
        case TimingFilterType.Any:
          filters.push("_exists_:created_utc");
          break;
        case TimingFilterType.Empty:
          filters.push("_missing_:created_utc");
          break;
        case TimingFilterType.Today:
          filters.push("created_utc:>=now/d");
          break;
        case TimingFilterType.Yesterday:
          filters.push("created_utc:[now-1d/d TO now/d]");
          break;
        case TimingFilterType.Last7Days:
          filters.push("created_utc:>=now-7d/d");
          break;
        case TimingFilterType.Month:
        case TimingFilterType.CurrentMonth:
          filters.push("created_utc:>=now/M");
          break;
        case TimingFilterType.Year:
        case TimingFilterType.CurrentYear:
          filters.push("created_utc:>=now/y");
          break;
        case TimingFilterType.DayRange:
          if (daysFrom >= 0) {
            daysTo > 0 ? filters.push(`created_utc:[now-${daysFrom}d/d TO now-${daysTo}d/d]`) :
              filters.push(`created_utc:>=now-${daysFrom}d/d`)
          }
          break;
        case TimingFilterType.LastXDays:
        case TimingFilterType.LastX:
          filters.push(`created_utc:>=now-${sinceDays}d/d`);
          break;
        case TimingFilterType.DateRange:
          if (filter.startDate && filter.endDate) {
            filters.push(`created_utc:[${filter.startDate} TO ${filter.endDate}]`);
          }
          break;
      }
      const filterString = filter.toFilterString(context);
      if (filterString) {
        filters.push(filterString);
      }

      const contactType = context?.contactType;
      if (contactType) {
        return filters.length > 0
          ? `${filter.occurrence}@activity:(contact_type:${contactType} (${filters.join(" ")}))`
          : `${filter.occurrence}@activity:(contact_type:${contactType})`
      }

      return filters.length > 0
        ? `${filter.occurrence}@activity:(${filters.join(" ")})`
        : "";
    });
    s.toFilterTerms((data, context) => {
      if (data.marker?.length) {
        return [{ term: "created_utc" }];
      }

      const paths = [];
      const timing = data.since || data.timing;
      switch (timing) {
        case TimingFilterType.Any:
        case TimingFilterType.Empty:
        case TimingFilterType.Today:
        case TimingFilterType.Yesterday:
        case TimingFilterType.Last7Days:
        case TimingFilterType.Month:
        case TimingFilterType.CurrentMonth:
        case TimingFilterType.Year:
        case TimingFilterType.CurrentYear:
          paths.push({ path: "created_utc" });
          break;

        case TimingFilterType.LastXDays:
        case TimingFilterType.LastX:
        case TimingFilterType.DayRange:
          // when daysFrom is blanked out it will be typeof string instead of number
          if (typeof data.daysFrom == "number" && data.daysFrom >= 0) {
            paths.push({ path: "created_utc" });
          }
          break;

        case TimingFilterType.DateRange:
          if (data.startDate && data.endDate) {
            paths.push({ path: "created_utc" });
          }
          break;
      }

      const filterTerms = data.toFilterTerms(context);
      if (filterTerms?.length > 0) {
        paths.push(...filterTerms);
      }

      // TODO: Should this be returned as its filter and not a path.
      const contactType = context?.contactType;
      if (contactType) {
        paths.push({ path: "contact_type" });
      }
    });
    s.validate(v => v.required(group => group.hasFilter));
  });

  registerFilter(NestedGroupFilter, (f) => {
    f.toFilterString(({ filters, field, operation }, context) => {
      const nestedFieldsFilters = [];
      filters.forEach(({ data }) => {
        const { type } = data.field;
        switch (type) {
          case CustomFieldType.date:
            nestedFieldsFilters.push(getDateFilter(data));
            break;
          case CustomFieldType.number:
            nestedFieldsFilters.push(getOperatorFilters(data));
            break;
          case CustomFieldType.dropdown:
          case CustomFieldType.boolean:
            nestedFieldsFilters.push(`${data.field.search_field}:${data.value}`);
            break;
          case CustomFieldType.string:
          case CustomFieldType.largestring:
            if (data.value !== "") {
              nestedFieldsFilters.push(`${data.field.search_field}:"${data.value}"`);
            }
            break;
          // Unsupported nested filter types
          case CustomFieldType.multiselect:
          case CustomFieldType.testscore:
          case CustomFieldType.address:
          case CustomFieldType.tags:
          case CustomFieldType.funnel:
          case CustomFieldType.phone:
          case CustomFieldType.link:
          default:
            throw new Error("Unsupported nested filter type");
        }
      });

      const contactType = context?.contactType;
      if (!contactType) {
        throw new Error("No contact type was found.");
      }

      return nestedFieldsFilters.length > 0 ? `@nested_${contactType}_${field.name}:(${nestedFieldsFilters.join(` ${operation} `)})` : "";
    });
    f.validate(v => v.required(group => group.hasFilter));
  });

  registerFilter(NestedSearchGroupFilter, (f) => {
    f.toFilterString(({ filters, field, nestedSearch, operation }, context) => {
      const nestedSearchFieldsFilters = [];
      filters.forEach(({ data }) => {
        const { type } = data.field;
        switch (type) {
          case CustomFieldType.date:
            nestedSearchFieldsFilters.push(getDateFilter(data));
            break;
          case CustomFieldType.number:
            nestedSearchFieldsFilters.push(getOperatorFilters(data));
            break;
          case CustomFieldType.dropdown:
          case CustomFieldType.boolean:
            nestedSearchFieldsFilters.push(`${data.field.search_field}:${data.value}`);
            break;
          case CustomFieldType.string:
          case CustomFieldType.largestring:
            if (data.value !== "") {
              nestedSearchFieldsFilters.push(`${data.field.search_field}:"${data.value}"`);
            }
            break;
          default:
            throw new Error("Unsupported nested filter type");
        }
      });

      const contactType = context?.contactType;
      if (!contactType) {
        throw new Error("No contact type was found.");
      }

      return nestedSearchFieldsFilters.length > 0 ? `@nested_${contactType}_${field.name}_${nestedSearch.name}:(${nestedSearchFieldsFilters.join(` ${operation} `)})` : "";
    });
    f.validate(v => v.required(group => group.hasFilter));
  });
}
