import { inject } from "fw";

import { ATS } from "./ats";

@inject
export class GenAIRepository {
  constructor(private s: ATS) {}

  public async summarizeParagraphAnswer(prompt: string): Promise<string> {
    const template = localStorage.getItem('bedrock_templates_summarize_paragraph_answer');
    const toneInstruction = localStorage.getItem('bedrock_settings_tone_instruction');
    const presentationInstruction = localStorage.getItem('bedrock_settings_presentation_instruction');
    const toneAndPresentation = `Respond with tone specified as: ${toneInstruction}. \nFormat the response as follows: ${presentationInstruction}.\n\n`;
    const assembledPrompt = `${toneAndPresentation}${template}`
      .replace('{0}', prompt)

    const res = await this.s.post<string>("gai/summarize", {
        prompt: assembledPrompt,
        maxTokens: parseInt(localStorage.getItem('bedrock_settings_max_tokens')),
        temperature: parseFloat(localStorage.getItem('bedrock_settings_temperature'))
    });
    return res.body;
  }

  public async generateApplicationQuery(text: string): Promise<string> {
    const useKnowledgeBase = localStorage.getItem('bedrock_settings_rag_application_query_enabled') === "1";
    const template = localStorage.getItem('bedrock_templates_generate_application_query');
    const assembledPrompt = template.replace('{1}', text);
    
    const res = await this.s.post<string>("gai/generate-application-query", {
        prompt: assembledPrompt,
        knowledgeBaseId: useKnowledgeBase ? localStorage.getItem('bedrock_settings_rag_application_query_knowledge_base_id') : null,
        maxTokens: parseInt(localStorage.getItem('bedrock_settings_max_tokens')),
        temperature: parseFloat(localStorage.getItem('bedrock_settings_temperature')),
        useKnowledgeBase: useKnowledgeBase
    });
    return res.body;
  }

  public async explainApplicationQuery(prompt: string): Promise<string> {
    const useKnowledgeBase = localStorage.getItem('bedrock_settings_rag_application_query_enabled') === "1";
    const template = localStorage.getItem('bedrock_templates_explain_application_query');
    const assembledPrompt = template.replace('{1}', prompt);
    
    const res = await this.s.post<string>("gai/explain-application-query", {
        prompt: assembledPrompt,
        knowledgeBaseId: useKnowledgeBase ? localStorage.getItem('bedrock_settings_rag_application_query_knowledge_base_id') : null,
        maxTokens: parseInt(localStorage.getItem('bedrock_settings_max_tokens')),
        temperature: parseFloat(localStorage.getItem('bedrock_settings_temperature')),
        useKnowledgeBase: useKnowledgeBase
    });
    return res.body;
  }

  public async queryKnowledgeBase(prompt: string): Promise<string> {
    const toneInstruction = localStorage.getItem('bedrock_settings_tone_instruction');
    const presentationInstruction = localStorage.getItem('bedrock_settings_presentation_instruction');
    const toneAndPresentation = `Respond with tone specified as: ${toneInstruction}. \nFormat the response as follows: ${presentationInstruction}.\n\n`;
    const assembledPrompt = `${toneAndPresentation}${prompt}`;

    const res = await this.s.post<string>("gai/query-knowledge-base", {
        prompt: assembledPrompt,
        knowledgeBaseId: localStorage.getItem('bedrock_settings_knowledge_base_id'),
        maxTokens: parseInt(localStorage.getItem('bedrock_settings_max_tokens')),
        temperature: parseFloat(localStorage.getItem('bedrock_settings_temperature'))
    });
    return res.body;
  }

  public async queryDocument(documentText: string, conversationHistory: string, prompt: string): Promise<string> {
    const template = localStorage.getItem('bedrock_templates_query_document');
    const toneInstruction = localStorage.getItem('bedrock_settings_tone_instruction');
    const presentationInstruction = localStorage.getItem('bedrock_settings_presentation_instruction');
    const toneAndPresentation = `Respond with tone specified as: ${toneInstruction}. \nFormat the response as follows: ${presentationInstruction}.\n\n`;
    const assembledPrompt = `${toneAndPresentation}${template}`
        .replace('{0}', documentText)
        .replace('{1}', conversationHistory)
        .replace('{2}', prompt);

    const res = await this.s.post<string>("gai/query-document", {
        prompt: assembledPrompt,
        maxTokens: parseInt(localStorage.getItem('bedrock_settings_max_tokens')),
        temperature: parseFloat(localStorage.getItem('bedrock_settings_temperature'))
    });
    return res.body;
  }

  public async summarizeDocument(prompt: string): Promise<string> {
    const template = localStorage.getItem('bedrock_templates_summarize_document');
    const toneInstruction = localStorage.getItem('bedrock_settings_tone_instruction');
    const presentationInstruction = localStorage.getItem('bedrock_settings_presentation_instruction');
    const toneAndPresentation = `Respond with tone specified as: ${toneInstruction}. \nFormat the response as follows: ${presentationInstruction}.\n\n`;
    const assembledPrompt = `${toneAndPresentation}${template}`
      .replace('{0}', prompt)

    const res = await this.s.post<string>("gai/summarize", {
        prompt: assembledPrompt,
        maxTokens: parseInt(localStorage.getItem('bedrock_settings_max_tokens')),
        temperature: parseFloat(localStorage.getItem('bedrock_settings_temperature'))
    });
    return res.body;
  }

  public async generateApplicationText(applicationId: string): Promise<string> {
    const res = await this.s.post<string>("gai/generate-application-text", applicationId);
    return res.body;
  }
}