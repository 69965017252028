import { inject, Navigator } from "fw";
import { dispatch } from "fw-state";

import { PopoverController } from "service/popover";
import { CurrentUserStore } from "state/current-user";
import { CurrentOrganizationStore, SetIsLoading } from "state/current-organization";

import { LogoutAction } from "state/actions";
import { DialogService } from "fw-dialog";
import { Notification } from "service/notification";
import { copy } from "helpers/copy";
import { ATS } from "network/ats";
import { clearStorages } from "helpers/clear-storages";
import { BedrockConfigDialog } from "views/global-admin/bedrock-config-dialog";

@inject
export class UserPopover {
  constructor(
    private nav: Navigator,
    private currentUserStore: CurrentUserStore,
    private currentOrgStore: CurrentOrganizationStore,
    private controller: PopoverController<any>,
    private dialogService: DialogService,
    private notify: Notification,
    private ats: ATS
  ) {}

  private tapCount = 0;
  private tapTimeout: ReturnType<typeof setTimeout> | null = null;

  private async logout() {
    if (window.location.hostname === "localhost") {
      await dispatch(new SetIsLoading(true));
      await dispatch(new LogoutAction());
    }

    clearStorages();

    if (this.currentOrgStore.state.portalContext == null) {
      window.location.reload();
      window.location.href = "/#/login";
      return;
    }

    const {
      OrganizationPortal: { AllowLocalLogin },
      IdentityProviders,
    } = this.currentOrgStore.state.portalContext;

    if (AllowLocalLogin == false && IdentityProviders.length == 1) {
      await dispatch(new SetIsLoading(true));
      await dispatch(new LogoutAction());

      // we don't want to redirect them to login cause it will just log them back in :)
      this.nav.navigate("/logged-out");
    } else {
      window.location.reload();
      window.location.href = "/#/login";
    }
  }

  get nonImpersonatedUserAvailable() {
    return (
      this.currentUserStore.state.loggedIn &&
      this.currentUserStore.state.user != null &&
      this.currentUserStore.state.impersonating == false
    );
  }

  get canEditAccount() {
    return (
      !this.currentUserStore.state.impersonating &&
      !this.currentUserStore.state.isGlobalPrincipal &&
      this.currentUserStore.state.user != null
    );
  }

  get isGlobal() {
    return this.nonImpersonatedUserAvailable && this.currentUserStore.state.isGlobalPrincipal;
  }

  async openAudits() {
    this.controller.close();

    const { AuditsDialog } = await import("views/global-admin/audits-dialog");
    this.dialogService.open(AuditsDialog);
  }

  navTo(where: string) {
    this.nav.navigate(where);
    this.controller.cancel();
  }

  get organization() {
    return this.currentOrgStore.state.organization;
  }

  get isImpersonating() {
    return this.currentUserStore.state.impersonating;
  }

  get isSwitchingOrganization() {
    return this.currentUserStore.state.isSwitchingOrganization;
  }

  async stopImpersonating() {
    this.navTo("stop-impersonating");
  }

  async copyEmailAddress(e: Event) {
    try {
      const target = e.target as Element;
      await copy(target.innerHTML);
      this.notify.notify("Email address successfully copied to clipboard");
    } catch (err) {
      console.error("Failed to copy email address: ", err);
    }
  }

  handleAvatarClick() {
    if (!this.isGlobal) return;

    this.tapCount++;

    if (this.tapCount === 3) {
      this.toggleExperimentalFeatures();
      this.tapCount = 0; // Reset the counter
      this.controller.close();
      return;
    }

    // Reset the tap count after 3 seconds
    if (this.tapTimeout) {
      clearTimeout(this.tapTimeout);
    }
    this.tapTimeout = setTimeout(() => {
      this.tapCount = 0;
    }, 3000);
  }

  toggleExperimentalFeatures() {
    const currentStatus = sessionStorage.getItem("experimentalFeatures") === "true";
    if (currentStatus) {
        sessionStorage.setItem("experimentalFeatures", "false");
        this.notify.notify("Experimental features disabled");
    } else {
        sessionStorage.setItem("experimentalFeatures", "true");

        let defaultsUpdatedCount = 0;

        // Set default templates if they are not already set
        if (!localStorage.getItem('bedrock_templates_summarize_paragraph_answer')) {
            localStorage.setItem(
                'bedrock_templates_summarize_paragraph_answer', 
                "Summarize the following text without preamble:\n\n{0}"
            );
            defaultsUpdatedCount++;
        }

        if (!localStorage.getItem('bedrock_templates_generate_application_query')) {
          localStorage.setItem(
              'bedrock_templates_generate_application_query', 
              "Given the application fields described by:\n\n{0}\n\nConvert the following user query into an elasticsearch lucene querystring query. When available, use value ids instead of label. When users say \"answered\" it means that the field is populated. Return the lucene query only:\n\n<UserQuery>{1}</UserQuery>\n\n" +
              "Examples:\n" +
              "show all -> *\n" +
              "applications from applicant with email address first.last@domain.com -> applicant.emailAddress:first.last@domain.com\n" +
              "applications from applicants named First -> applicant.givenName:First\n" +
              "applications in phase Phase Name -> phase:Phase Name\n" +
              "applications started in last 7 days -> dateStarted:[now-7d TO now]\n" +
              "application started on December 10th, 2024 -> dateStarted:2024-12-10\n" +
              "application with public id 0123456 -> publicId:0123456\n" +
              "application with public id starting with 0123 -> publicId:0123*\n" +
              "applications with origin Origin Name -> origin:Origin Name\n" +
              "applications where the origin is not Origin Name -> origin:(-Origin Name)\n" +
              "applications with booleanApplicationProperty set to yes -> booleanApplicationProperty:true\n" +
              "applications that have comments -> commentCount:>0\n" +
              "application that have 2 or more comments -> commentCount:>=2\n" +
              "applications with a pending decision -> decision:Pending\n" +
              "applications with an accepted decision -> decision:Accepted\n" +
              "applications with numberApplicationProperty is exactly 15 -> numberApplicationProperty:15\n" +
              "applications with numberApplicationProperty is less than 15 -> numberApplicationProperty:<15\n\n" +
              "Again, return the lucene query only."
          );
          defaultsUpdatedCount++;
        }    

        if (!localStorage.getItem('bedrock_templates_explain_application_query')) {
            localStorage.setItem(
                'bedrock_templates_explain_application_query', 
                "Given the application fields described by:\n\n{0}\n\nWithout preamble, concisely explain the following lucene query in simple natural language without referencing any technical details as this is an explanation for the end user:\n\n{1}"
            );
            defaultsUpdatedCount++;
        }

        if (!localStorage.getItem('bedrock_templates_query_document')) {
            localStorage.setItem(
                'bedrock_templates_query_document',
                "You are a helpful assistant. The following text includes the original content of a document,\n" +
                "the conversation history between the human and you so far, and a follow-up question which you must answer\n" +
                "with the most accurate response possible.\n\n" +
                "<Document>{0}</Document>\n\n" +
                "<ConversationHistory>{1}</ConversationHistory>\n\n" +
                "<Question>:{2}</Question>\n\n" + 
                "You are allowed to say 'I do not know' if you don't have the answer. You should answer clearly and succinctly.\n" +
                "You should not reference the document you're extracting the info from as the end user is seeing this as an application chat. Don't start with 'According to the information provided' or similar."
            );
            defaultsUpdatedCount++;
        }

        if (!localStorage.getItem('bedrock_templates_summarize_document')) {
          localStorage.setItem(
            'bedrock_templates_summarize_document',
            `Could you please provide a summary of the given text, including all key points and supporting details? 
            The summary should be comprehensive and accurately reflect the main message and arguments presented in the original text, 
            while also being concise and easy to understand. To ensure accuracy, please read the text carefully and pay attention 
            to any nuances or complexities in the language. Additionally, the summary should avoid any personal biases or interpretations 
            and remain objective and factual throughout. Summary should contain 4 sections.
        
            Start the response with the following preamble: 
            "Here’s the summary of the application currently being viewed, highlighting its most important details and key aspects:"
        
            Each section should have:
            - A title enclosed in an <h4> tag, styled with margin-bottom: 6px; margin-left: -4px;. The titles are as follows:
              - "🧑 Personal Information" for Paragraph 1.
              - "🎓 Academic Information" for Paragraph 2.
              - "📄 Letters of Evaluation" for Paragraph 3.
              - "🌟 Experiences, Activities, and Work Experience" for Paragraph 4.
            - Content for the Personal Information section should be formatted as an HTML table with 5 rows and 6 columns. The table should have the following properties:
              - Use border-collapse: collapse; for a clean look.
              - Apply border: 1px solid #ccc; to table cells to create dividing lines.
              - Add padding: 8px; to table cells for better readability.
              - Ensure the table is set to full width: width: 100%;.
              - The first column should contain the property label, the second column should contain the property value, and so on, alternating between labels and values across the 6 columns.
              - The label columns should be bold for clarity.
              - Combine First Name and Last Name into a single property and display it as "Full Name" in the table.
              - The table should contain 15 properties spread across 5 rows, with 3 properties per row.
              - Add margin-top: 10px; to the table for spacing.
            - Content for all other sections should follow the format of a <p> tag, with each paragraph limited to approximately 200 tokens.
            - Each section wrapped in a <div> with margin-top: 20px; to ensure proper spacing between the intro and all sections.
        
            {0}`
          );
          defaultsUpdatedCount++;
        }         
      
        if (!localStorage.getItem('bedrock_settings_knowledge_base_id')) {
            localStorage.setItem('bedrock_settings_knowledge_base_id', "QYCLI0RZ4I");
            defaultsUpdatedCount++;
        }

        if (!localStorage.getItem('bedrock_settings_rag_application_query_enabled')) {
            localStorage.setItem('bedrock_settings_rag_application_query_enabled', "0");
            defaultsUpdatedCount++;
        }

        if (!localStorage.getItem('bedrock_settings_rag_application_query_knowledge_base_id')) {
            // default knowledge base with data dictionaries from Organization Here and Test Automation Org - Auto
            localStorage.setItem('bedrock_settings_rag_application_query_knowledge_base_id', "YYMKVFNJQS"); 
            defaultsUpdatedCount++;
        }

        if (!localStorage.getItem('bedrock_settings_tone_instruction')) {
            localStorage.setItem('bedrock_settings_tone_instruction', "Neutral and professional");
            defaultsUpdatedCount++;
        }

        if (!localStorage.getItem('bedrock_settings_presentation_instruction')) {
            localStorage.setItem('bedrock_settings_presentation_instruction', "Longer responses should be structured in paragraphs. Usage of number or bullet lists is encouraged");
            defaultsUpdatedCount++;
        }

        if (!localStorage.getItem('bedrock_settings_max_tokens')) {
            localStorage.setItem('bedrock_settings_max_tokens', String(2048));
            defaultsUpdatedCount++;
        }

        if (!localStorage.getItem('bedrock_settings_temperature')) {
            localStorage.setItem('bedrock_settings_temperature', String(0.0));
            defaultsUpdatedCount++;
        }

        let message = "Experimental features enabled";
        if (defaultsUpdatedCount > 0) {
            message += `. ${defaultsUpdatedCount} default setting${defaultsUpdatedCount > 1 ? 's' : ''} updated.`;
        }

        this.notify.notify(message);
    }
  }

  public get experimentalFeaturesEnabled(): boolean {
    return sessionStorage.getItem("experimentalFeatures") === "true";
  }

  async openBedrockConfig() {
    this.controller.close();
    this.dialogService.open(BedrockConfigDialog);
  }
}
