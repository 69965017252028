import { prop, needs, ComponentEventBus, inject } from "@derekpitt/fw";

import { Form, Question as QuestionModel, FormAnswer, ComputedAnswerOption, AnswerOption } from "../models";
import type { FileHash, FileService, CeebRequest, DecryptData } from "../models";
import { QuestionType } from "../enums";
import { validate } from "../validation";

import * as Types from "./types/index";

let uniqueId = 0;

@needs(
  Types.ShortTextType, Types.DropdownType, Types.LongTextType,
  Types.RadioType, Types.CheckboxType, Types.FileType,
  Types.ScaleType, Types.ScaleGroupType, Types.TableType, Types.NumberType,
  Types.NameType, Types.AddressType, Types.CeebType, Types.EncryptedType,
  Types.PhoneType,
)
@inject
export class Question {
  @prop(null) form!: Form;
  @prop(null) question!: QuestionModel;
  @prop(null) computedAnswerOptions:ComputedAnswerOption[];
  @prop(null) answer!: FormAnswer;
  @prop(null) fileHash!: FileHash;
  @prop(null) fileService!: FileService;
  @prop(null) requestCeeb!: CeebRequest;
  @prop(null) decryptData!: DecryptData;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(true) showValidation!: boolean;
  @prop(true) showOptions!: boolean;
  @prop(null) ariaLabelledby;
  @prop(null) summarize: Function;

  private questionType: string = null;
  private uniqueId = uniqueId++;

  constructor(private ceb: ComponentEventBus) { }

  private makeId() {
    return `${this.question.Type}-${ this.uniqueId }`;
  }

  applicationStepChanged() { this.attached(); }
  questionChanged() { this.computeQuestionType(); }
  attached() {
    this.computeQuestionType();
  }

  computeQuestionType() {

    if (this.question == null) return;


    switch (this.question.Type) {
      case QuestionType.ShortText:
      case QuestionType.EmailAddress:
      case QuestionType.URL:
      case QuestionType.Date:
        this.questionType = "short-text-type";
        break;

      case QuestionType.PhoneNumber: this.questionType = "phone-type"; break;
      case QuestionType.LongText: this.questionType = "long-text-type"; break;
      case QuestionType.DropDown: this.questionType = "dropdown-type"; break;
      case QuestionType.RadioButton: this.questionType = "radio-type"; break;
      case QuestionType.CheckBoxList: this.questionType = "checkbox-type"; break;
      case QuestionType.File: this.questionType = "file-type"; break;
      case QuestionType.Scale: this.questionType = "scale-type"; break;
      case QuestionType.ScaleGroup: this.questionType = "scale-group-type"; break;
      case QuestionType.Table: this.questionType = "table-type"; break;
      case QuestionType.Number: this.questionType = "number-type"; break;
      case QuestionType.Address: this.questionType = "address-type"; break;
      case QuestionType.CEEBCode: this.questionType = "ceeb-type"; break;
      case QuestionType.Name: this.questionType = "name-type"; break;
      case QuestionType.Encrypted: this.questionType = "encrypted-type"; break;

      default: break;
    }
  }

  public validateAnswer() {
    validate(this.form, this.question, this.answer);
    this.ceb.dispatch("answer-changed");
    return true;
  }

  public resolveAnswerOptions() {
    return this.computedAnswerOptions == null ? this.question?.AnswerOptions : this.computedAnswerOptions.map(c => this.question.AnswerOptions[c.index]);
  }

  get isInvalid() {
    return this.answer != null && this.answer.MetaData.IsValid === false && this.showValidation;
  }
}
