import { inject } from "fw";
import { createFrom, createFromArray, cloneOf } from "fw-model";

import { ATS } from "./ats";

import { CalendarEvent } from "models/calendar-event";
import { InquiryResponse } from "models/inquiry-response";

export interface CalendarEventRegistrationArgs {
  CalendarEventSeriesId: string;
  CalendarEventInstanceKey: string;
  ContactId: string;
  GuestCount: number;
  Response: InquiryResponse;
}

@inject
export class InquiryResponseRepository {
  constructor(private s: ATS) { }

  public async list(contactId: string, formId: string = null,sort: string = null) {
    const res = await this.s.get<any[]>("inquiry-response", { contactId, formId, sort });
    return createFromArray(InquiryResponse, res.body);
  }
}
