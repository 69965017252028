import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LogoutAction, StartAction } from "./actions";
import { InterviewPortalRepository } from "network/interview-portal-repository";
import { InterviewPortal as InterviewPortalModel } from "models/interview-portal";
import { InterviewPortalFormType } from "forms/interview-portal";
import { FormErrorHandling } from "./error-handling";
import { wait } from "wait";

interface InterviewPortalShape {
  interviewPortal: InterviewPortalModel,
  loading: boolean;
  errorLoading: boolean;
  loaded: boolean;
}

export class EnsureInterviewPortalAction {
  constructor(public refresh: boolean = false) { }
}

export class UpdateInterviewPortalAction {
  public interviewPortal: InterviewPortalModel = null;
  constructor(public form: InterviewPortalFormType) { }
}

export class RefreshInterviewPortalAction {
  constructor() { }
}

@inject
export class InterviewPortalStore extends Store<InterviewPortalShape> {
  public constructor(
    private interviewPortalRepo: InterviewPortalRepository
  ) {
    super();
  }

  defaultState() {
    return {
      interviewPortal: null,
      loading: false,
      errorLoading: false,
      loaded: false
    };
  }

  @handle(StartAction)
  private handleStart(s: StartAction) {
    this.setState(s => this.defaultState());
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureInterviewPortalAction)
  async handleEnsureInterviewPortalAction(action: EnsureInterviewPortalAction) {
    if (this.state.loaded && !action.refresh) {
      return;
    }

    if (this.state.loading) {
      while (this.state.loading) {
        await wait(10);
      }

      if (this.state.errorLoading) {
        throw new Error("Error loading interview portal");
      }

      return;
    }

    this.setState(state => ({
      ...state,
      loading: true
    }));

    try {
      const res = await this.interviewPortalRepo.get();

      this.setState(state => ({
        ...state,
        id: res.Id,
        interviewPortal: { ...res },
        loaded: true,
        errorLoading: false,
        loading: false
      }));
    } catch (e) {
      this.setState(state => ({
        ...state,
        loading: false,
        errorLoading: true
      }));
    }
  }

  @handle(UpdateInterviewPortalAction, FormErrorHandling)
  private async handleUpdateInterviewPortalAction(action: UpdateInterviewPortalAction) {
    this.setState(state => ({
      ...state,
      loading: true,
    }));

    try {
      const interviewPortal = await this.interviewPortalRepo.put(action.form.updatedModel());

      this.setState(state => ({
        ...state,
        interviewPortal: { ...interviewPortal }
      }));

      action.interviewPortal = interviewPortal;
    } finally {
      this.setState(state => ({
        ...state,
        loading: false
      }));
    }
  }

  @handle(RefreshInterviewPortalAction, FormErrorHandling)
  private async handleRefreshInterviewPortalAction(action: RefreshInterviewPortalAction) {
    this.setState(state => ({
      ...state,
      loading: true,
    }));

    try {
      await this.interviewPortalRepo.refresh();
    } finally {
      this.setState(state => ({
        ...state,
        loading: false
      }));
    }
  }
}
