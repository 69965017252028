import { inject, prop } from "fw";
import { FILE_SIZES } from "views/marketing/bulk-messages/manage-message/build-email.constants";

let id = 0;

@inject
export class FormatBytes {
  @prop(0) private bytes: number;
  constructor() {}

  public get formattedBytes(): string {
    if (this.bytes === 0) return "0 Bytes";

    const fixed = Math.floor(Math.log(this.bytes) / Math.log(1024));

    return `${parseFloat((this.bytes / Math.pow(1024, fixed)).toFixed(2))} ${FILE_SIZES[fixed]}`;
  }
}

