import { inject, NetworkException, Navigator } from "fw";
import { dispatch } from "fw-state";

import { SelectOrganizationPopover, ISelectOrganizationPopoverOptions } from "./select-organization-popover";
import { DomElementAttributes, PopoverService } from "service/popover";
import { AppRepository, OrganizationWithDomainView } from "network/app-repository";
import { SwitchOrganizationAction } from "state/actions";
import { Notification } from "service/notification";
import { CurrentUserStore, SwitchingOrganizationAction } from "state/current-user";
import { LocalStorageCache } from "caching";
import { wait } from "wait";
import { CrossTabCommunicator } from "service/cross-tab-communicator";

@inject
export class ChooseOrganization {
  private userOrganizations: OrganizationWithDomainView[] = null;
  private selectedId: string = null;
  private popoverContainerEl;
  private redirectTo: string = "";
  private isLoading = false;

  constructor(
    private nav: Navigator,
    private popoverService: PopoverService,
    private appRepo: AppRepository,
    private notification: Notification,
    private cache: LocalStorageCache,
    private currentUserStore: CurrentUserStore
  ) {}

  private attached() {
    const useOrganizationSwitcher = this.cache.get("use-organization-switcher");
    if (useOrganizationSwitcher) {
      this.nav.navigate(this.redirectTo || "/");
    }
  }

  private activate(data) {
    if (data?.redirect) {
      this.redirectTo = data.redirect;
    }
    void this.getAllowedUserOrganizations();
  }

  private async getAllowedUserOrganizations() {
    try {
      const resp = await this.appRepo.allowedUserOrganizations();
      this.userOrganizations = resp.OrganizationWithDomainViews;
    } catch (e) {
      this.showErrorMessage(e);
    }
  }

  private showErrorMessage(error: unknown) {
    let message: string = "Sorry, we encountered an error while fetching the data. Please try again later.";
    if (error instanceof NetworkException && error.result?.Message) {
      message = error.result?.Message;
    }

    this.notification.error(message);
  }

  private async selectOrganization() {
    const data: ISelectOrganizationPopoverOptions = {
      userOrganizations: this.userOrganizations,
    };

    const popoverMarker = document.getElementById("popover-marker");
    const res = await this.popoverService.open<string>(
      SelectOrganizationPopover,
      data,
      popoverMarker,
      undefined,
      undefined,
      this.popoverContainerEl,
      undefined,
      <DomElementAttributes>{
        class: "fw-popover-rounded",
        width: this.popoverContainerEl.clientWidth,
      }
    );
    if (res.canceled) return;

    this.selectedId = res.result;
  }

  private get organizationName() {
    if (!this.selectedId) return "";

    return this.userOrganizations.find((org) => org.OrganizationId === this.selectedId)?.Name;
  }

  private async authorizeToOrganization() {
    if (!this.selectedId) return;

    try {
      this.isLoading = true;
      this.cache.set("use-organization-switcher", true);
      await dispatch(new SwitchingOrganizationAction());
      await dispatch(new SwitchOrganizationAction(this.selectedId));
      this.nav.navigate(this.redirectTo);
      await wait(3000);
      CrossTabCommunicator.broadcastLogin();
    } catch (error) {
      let message: string =
        "Sorry, we encountered an error while switching the organization. Please try again later.";
      if (error instanceof NetworkException && error.result?.Message) {
        message = error.result?.Message;
      }
      this.showErrorMessage(message);
    } finally {
      await dispatch(new SwitchingOrganizationAction());
      this.isLoading = false;
    }
  }
}
