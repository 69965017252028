import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { ApplicationSource } from "models/application-source";

import { StartAction } from "./actions";
import { AddApplicationSourceAction, UpdateApplicationSourceAction } from "forms/application-source";
import { ApplicationSourceRepository } from "network/application-source-repository";
import { difference } from "lodash-es";
import { FormErrorHandling } from "./error-handling";
import { Permissions } from "service/permission";
import { FeatureFlagService } from "service/feature-flag";

type ApplicationSourceStoreShape = {
  list: ApplicationSource[];
  loading: boolean;
  loaded: boolean;
  errorLoading: boolean;
}

export class EnsureApplicationSourcesAction {}

export class DeleteApplicationSourcesAction {
  constructor(public ids: string[]) {}
}

@inject
export class ApplicationSourceStore extends Store<ApplicationSourceStoreShape> {
  constructor(private applicationSourceRepo: ApplicationSourceRepository,
    private permissions: Permissions,
    private ffs: FeatureFlagService) {
    super();
  }

  defaultState() {
    return {
      list: [],
      loading: false,
      loaded: false,
      errorLoading: false
    };
  }

  @handle(StartAction)
  handleStartAction() {
    this.setState(_ => this.defaultState());
  }

  @handle(EnsureApplicationSourcesAction)
  private async handleEnsureApplicationSourcesAction() {
    const { loading, loaded } = this.state;

    if (loading || loaded) return;

    this.setState(state => ({
      ...state,
      loading: true,
    }));

    try {
      const list = await this.applicationSourceRepo.list();

      this.setState(state => ({
        ...state,
        loading: false,
        loaded: true,
        list
      }));
    } catch {
      this.setState(state => ({ ...state, errorLoading: true , loading: false }));
    }
  }

  @handle(AddApplicationSourceAction, FormErrorHandling)
  private async handleAddApplicationSourceAction(action: AddApplicationSourceAction) {
    action.form.validate();

    if(!this.hasEditApplicationImportPermission()) return;

    const res = await this.applicationSourceRepo.post(action.form.updatedModel());

    await dispatch(new EnsureApplicationSourcesAction());
    this.setState(state => ({
      ...state,
      list: [ ...state.list, res ],
    }));
  }

  @handle(UpdateApplicationSourceAction, FormErrorHandling)
  private async handleUpdateApplicationSourceAction(action: UpdateApplicationSourceAction) {
    action.form.validate();
    const res = await this.applicationSourceRepo.update(action.form.updatedModel());

    await dispatch(new EnsureApplicationSourcesAction());
    const existing = this.state.list.find(a => a.Id == action.form.Id);

    if (existing) {
      Object.assign(existing, res);
    }
  }

  @handle(DeleteApplicationSourcesAction)
  private async handleDeleteApplicationSourcesAction(
    action: DeleteApplicationSourcesAction,
  ) {
    if(!this.hasEditApplicationImportPermission()) return;

    await dispatch(new EnsureApplicationSourcesAction());
    const objects = this.state.list.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.applicationSourceRepo.del(objects.map(t => t.Id));
    this.setState(state => ({
      ...state,
      list: difference(state.list, objects),
    }));
  }

  public hasEditApplicationImportPermission():boolean{
    if(this.ffs.isFeatureFlagEnabled("AppImportUI_OUT4867") ){
      return this.permissions.all(
        "EditApplicationImport"
      )
    }
    return true;
  }
}
