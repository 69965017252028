import { ContainerInstance } from "fw";
import { setupStores } from "fw-state";

import { CurrentUserStore } from "state/current-user";
import { DashboardStore } from "state/dashboard";
import { ProgramStore } from "state/program";
import { ApplicationStore } from "state/application";
import { UsersStore } from "state/users";
import { FormStore } from "state/forms";
import { ApplicantStore } from "state/applicants";
import { CurrentApplicationStore } from "state/current-application";
import { EvaluationPhasesStore } from "state/evaluation-phases";
import { PortalsStore } from "state/portals";
import { CurrentOrganizationStore } from "state/current-organization";
import { ApplicationSegmentStore } from "state/application-segments";
import { ApplicationSettingsStore } from "state/application-settings";
import { ReportDefinitionsStore } from "state/report-definitions";
import { ReportResultStore } from "state/report-results";
import { TeamsStore } from "state/teams";
import { TaskRequestStore } from "state/task-request";
import { IdentityProvidersStore } from "state/identity-providers";
import { ApiKeyStore } from "state/api-keys";
import { RolesStore } from "state/roles";
import { ExportStore } from "state/export";
import { ExportDownloadStore } from "state/export-download";
import { FileProvidersStore } from "state/file-provider";
import { PdfExportTemplateStore } from "state/pdf-export-template";
import { ScheduledExportsStore } from "state/scheduled-exports";
import { OrganizationSettingsStore } from "state/organization-settings";
import { FeatureFlagsStore } from "state/feature-flags";
import { UserExperienceSettingsStore } from "state/user-experience-settings";
import { IntegrationsStore } from "state/integrations";
import { CollaborationModulesStore } from "state/collaboration-modules";
import { CollaboratorRolesStore } from "state/collaborator-roles";
import { EmailTemplateStore } from "state/email-template";
import { EmailTaskStore } from "state/email-task";
import { ContentStore } from "state/content";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { CurrentContactStore } from "state/current-contact";
import { CurrentUserSettingsStore } from "state/current-user-settings";
import { ContactStore } from "state/contacts";
import { RoutingTablesStore } from "state/routing-tables";
import { ContactsDataSourceInstanceStore } from "state/contacts-data-source-instance";
import { PotentialDuplicateContactStore } from "state/potential-duplicate-contacts";
import { ContactsGeoStore } from "state/contacts-geo";
import { AutomationsRulesStore } from "state/automation-rules";
import { GoalsStore } from "state/goals";
import { ContactSegmentStore } from "state/contact-segments";
import { PaymentJournalStore } from "state/payment-journal";
import { PortalInvitationsStore } from "state/portal-invitations";
import { FileSourceStore } from "state/file-source";
import { ApplicationSourceStore } from "state/application-source";
import { EntityReferenceStore } from "state/entity-reference";
import { MarketingClientStore } from "state/marketing-clients";
import { MarketingOutreachCampaignStore } from "state/marketing-outreach-campaigns";
import { MarketingOutreachTrackStore } from "state/marketing-outreach-tracks";
import { MarketingOutreachTierStore } from "state/marketing-outreach-tiers";
import { MarketingOutreachItemStore } from "state/marketing-outreach-items";
import { MarketingEmailTemplateDesignStore } from "state/marketing-email-template-designs";
import { MarketingEmailTemplateStore } from "state/marketing-email-templates";
import { DecisionSettingsStore } from "state/decision-settings";
import { ScheduledExportHistoryStore } from "state/scheduled-export-history";
import { TranslationTableStore } from "state/translation-tables";
import { UserTaskStore } from "state/user-tasks";
import { WebSocketStore } from "state/web-socket";
import { WorkflowStore } from "state/workflow";
import { NotificationsStore } from "state/notifications";
import { ActivityStore } from "state/activity";
import { RoutePathStore } from "state/route-path";
import { DecisionLetterStore } from "state/decision-letter";
import { CalendarEventTypeStore } from "state/calendar-event-type";
import { CalendarEventStore } from "state/calendar-events";
import { CurrentCalendarEventStore } from "state/current-calendar-event";
import { ContactDataDictionaryStore } from "state/contact-data-dictionary";
import { ConversationStore } from "state/conversations";
import { CurrentConversationStore } from "state/current-conversation";
import { SwitchTableSectionViewStore } from "state/table-section-view";

export function configureStores() {
  setupStores(
    ContainerInstance,
    CurrentUserStore,
    DashboardStore,
    ProgramStore,
    ApplicationStore,
    EmailTaskStore,
    UsersStore,
    FormStore,
    ApplicantStore,
    CurrentApplicationStore,
    CurrentOrganizationStore,
    CurrentContactOrganizationStore,
    CurrentContactStore,
    CurrentUserSettingsStore,
    ContactStore,
    PortalsStore,
    ApplicationSegmentStore,
    ReportDefinitionsStore,
    ReportResultStore,
    EvaluationPhasesStore,
    TeamsStore,
    TaskRequestStore,
    IdentityProvidersStore,
    ApiKeyStore,
    RolesStore,
    ExportStore,
    FileProvidersStore,
    PdfExportTemplateStore,
    ScheduledExportsStore,
    OrganizationSettingsStore,
    ApplicationSettingsStore,
    FeatureFlagsStore,
    UserExperienceSettingsStore,
    ExportDownloadStore,
    IntegrationsStore,
    CollaborationModulesStore,
    CollaboratorRolesStore,
    ContentStore,
    EmailTemplateStore,
    RoutingTablesStore,
    ContactsDataSourceInstanceStore,
    PaymentJournalStore,
    PotentialDuplicateContactStore,
    ContactsGeoStore,
    AutomationsRulesStore,
    GoalsStore,
    ContactSegmentStore,
    PortalInvitationsStore,
    FileSourceStore,
    ApplicationSourceStore,
    MarketingClientStore,
    MarketingOutreachCampaignStore,
    MarketingOutreachTrackStore,
    MarketingOutreachTierStore,
    MarketingOutreachItemStore,
    MarketingEmailTemplateDesignStore,
    MarketingEmailTemplateStore,
    DecisionSettingsStore,
    ScheduledExportHistoryStore,
    TranslationTableStore,
    UserTaskStore,
    EntityReferenceStore,
    NotificationsStore,
    WebSocketStore,
    WorkflowStore,
    ActivityStore,
    RoutePathStore,
    DecisionLetterStore,
    CalendarEventTypeStore,
    CalendarEventStore,
    CurrentCalendarEventStore,
    ContactDataDictionaryStore,
    ConversationStore,
    CurrentConversationStore,
    SwitchTableSectionViewStore
  );
}
