import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LogoutAction, StartAction } from "./actions";
import { LoginForm } from "forms/login";

interface TableSectionViewShape {
  isTableView: boolean;
}

export class SwitchTableSectionView {
  constructor(public isTableView: boolean) { }
}

const IS_SELECTED_DOCUMENT_VIEW =  "is-selected-document-view";

@inject
export class SwitchTableSectionViewStore extends Store<TableSectionViewShape> {
  constructor() {
    super();
  }

  defaultState() {
    const isDocumentView = localStorage.getItem(IS_SELECTED_DOCUMENT_VIEW);

    return {
      isTableView: !isDocumentView
    };
  }

  @handle(SwitchTableSectionView)
  private async handleSwitchTableSectionViewAction(action: SwitchTableSectionView) {
    this.setState(({ isTableView }) => ({
      isTableView: action.isTableView,
    }));

    if (!action.isTableView) {
      return localStorage.setItem(IS_SELECTED_DOCUMENT_VIEW, IS_SELECTED_DOCUMENT_VIEW);
    }

    localStorage.removeItem(IS_SELECTED_DOCUMENT_VIEW);
  }

  private setDefaultView() {
    localStorage.removeItem(IS_SELECTED_DOCUMENT_VIEW);
    this.setState(() => ({
      isTableView: true,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setDefaultView();
  }

  @handle(StartAction)
  private handleStart() {
    this.setDefaultView();
  }

  @handle(LoginForm)
  private handleLoginForm() {
    this.setDefaultView();
  }
}
