import { fromClassArray, fromClass } from "fw-model";

// export class ApplicationMatchingProperty {
//   Key: string;
//   Values: string[];
// }

export class UserMembership {
  OrganizationId: string;
  DateLastLogin: string;
  DateLastLoginUTC?: string;
  RoleId: string;
  CollaboratorRoleIds: string[];
  MaxAssignmentsPerModule: number = null;
  IsAvailableForAssignment: boolean = true;
  ApplicationMatchingProperties: { [key: string]: any } = {};
  OptOutAssignmentSummary: boolean;
  OptInEventRegistrationDigest: boolean;
  // @fromClassArray(ApplicationMatchingProperty) ApplicationMatchingProperties: ApplicationMatchingProperty[] = [];
}

export class SeasonalTeamInfo {
  SeasonId: string;
  TeamIds: string[];
}

export class PortalLogin {
  PortalId: string;
  AuthProviderEntityIds: string[];
  DateActivatedUTC: string;
  DateLastLoginUTC: string;
}

export class AddressInfo {
  Address1: string = null;
  Address2: string = null;
  City: string = null;
  Region: string = null;
  PostalCode: string = null;
  Country: string = null;
}

export class UserMetaData {
  AvatarKey: string;
  AvatarEphemeralUrl: string = null;
  ProfileColor: string;

  MfaEnabled = false;

  @fromClassArray(PortalLogin) PortalLogins: PortalLogin[];
  @fromClassArray(SeasonalTeamInfo) CollaborationTeams: SeasonalTeamInfo[];
  @fromClassArray(SeasonalTeamInfo) EvaluationTeams: SeasonalTeamInfo[];

  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

export class User {
  Id: string = null;
  EmailAddress: string = null;
  FirstName: string = null;
  LastName: string = null;
  DateLastLoginUTC: string = null;
  IsGlobalAdmin = false;
  AvatarFileId: string = null;
  Title: string = null;
  Department: string = null;
  Bio: string = null;
  OfficePhone: string = null;
  MobilePhone: string = null;

  @fromClass Address: AddressInfo = null;
  @fromClassArray(UserMembership) Memberships: UserMembership[] = [];
  @fromClass MetaData: UserMetaData;
}

export class NewUser extends User {
  Password: string = null;
  ConfirmPassword: string = null;
}

export class NewPassword {
  Password: string = null;
  ConfirmPassword: string = null;
}

export class UserInviteArgs {
  EmailAddresses: string[] = [];
  RoleId: string = null;
  CollaboratorRoleIds: string[] = [];
  CollaboratorTeamIds: string[] = [];
}

export class UserInvitation {
  Id: string;
  CreatedUtc: string;
  EmailAddress: string = null;
  InvitedByUserId: string;
  ExpiryUtc: string;
  FirstName: string;
  LastName: string;
  RoleId: string = null;
  OrganizationId: string;
  CollaboratorRoleIds: string[] = [];
  CollaboratorTeamIds: string[] = [];
}

export enum AccountLockOutType {
  Temporary = 0,
  ResetRequired = 1,
}

export class PostUserArgs {
  EmailAddress: string = null;
  Password: string = null;
  FirstName: string = null;
  LastName: string = null;
  AvatarFileId: string = null;
  Title: string = null;
  Department: string = null;
  Bio: string = null;
  OfficePhone: string = null;
  MobilePhone: string = null;

  RoleId: string = null;
  OptOutAssignmentSummary: boolean;
  OptInEventRegistrationDigest: boolean;
  CollaboratorTeamIds: string[] = [];
  EvaluationTeamIds: string[] = [];
  InvitePortalIds: string[] = [];
  SendInvitesNow: boolean = false;

  MaxAssignmentsPerModule: number = null;
  IsAvailableForAssignment = true;

  @fromClass Address: AddressInfo = null;
}

export interface RelatedUsersMap {
  contactRelationship: string;
  userId: string;
}
