import { inject } from "fw";
import { DialogController } from "fw-dialog";
import { Notification } from "service/notification";

interface StorageItem {
  key: string;
  value: string;
}

@inject
export class BedrockConfigDialog {
  private storageItems: StorageItem[] = [];

  constructor(
    private controller: DialogController<void>,
    private notifier: Notification
  ) {}

  attached() {
    this.loadBedrockStorageItems();
  }

  close() {
    this.controller.close();
  }

  private loadBedrockStorageItems() {
    this.storageItems = Object.keys(localStorage)
      .filter((key) => key.startsWith("bedrock"))
      .map((key) => ({
        key,
        value: localStorage.getItem(key) ?? "",
      }));
  }

  saveChanges() {
    this.storageItems.forEach((item) => {
      localStorage.setItem(item.key, item.value);
    });
    this.controller.close();
    this.notifier.notify("Bedrock settings saved")
  }
}
