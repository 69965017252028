import { inject } from "fw";
import { handle } from "fw-state";
import { StartAction, LogoutAction } from "./actions";
import { MarketingEmailTemplateRepository } from "network/marketing-email-template-repository";
import { MarketingEmailTemplate } from "models/marketing-email-template";
import {
  CreateMarketingEmailTemplateAction,
  UpdateMarketingEmailTemplateAction,
  DeleteMarketingEmailTemplateAction,
  CopyMarketingEmailTemplateAction,
  ActivateMarketingEmailTemplateAction,
  DeactivateMarketingEmailTemplateAction
} from "forms/marketing-email-template";
import { FormErrorHandling } from "./error-handling";
import { MarketingOutreachListStore } from "./marketing-outreach-list";

const MAX_SKIP = 2000;

export class FetchMarketingEmailTemplatesAction {
  constructor(public refresh: boolean = false, public aggs: string = '') {}
}
export class FetchMarketingEmailTemplateAction {
  constructor(public id: number, public refresh: boolean = false) {}
}

@inject
export class MarketingEmailTemplateStore extends MarketingOutreachListStore<MarketingEmailTemplate> {
  constructor(private network: MarketingEmailTemplateRepository) {
    super();
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    this.setState(state => ({ ...this.defaultState() }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(state => this.defaultState());
  }

  @handle(FetchMarketingEmailTemplatesAction)
  private async handleFetchMarketingEmailTemplates(action: FetchMarketingEmailTemplatesAction) {
    if (this.state.loaded && ! action.refresh) return;

    const aggs = action.aggs;
    const { toggle, search, sort, pageSize } = this.state;
    let { currentPage } = this.state;

    const res = await this.network.list(toggle ? '1' : '0', search, aggs, sort, currentPage, pageSize);
    const totalCount = res.total;

    const lastPage = Math.ceil(totalCount / pageSize);
    currentPage = Math.min(currentPage, lastPage);

    const pageStartNumber = ((currentPage - 1) * pageSize) + 1;
    const pageEndNumber = pageStartNumber + (pageSize - 1);
    const hasNextPage = pageEndNumber < totalCount;
    const hasPreviousPage = pageStartNumber > 1;
    const canAdvancePage = pageEndNumber + 1 < MAX_SKIP;

    this.setState(state => ({
      ...state,
      loaded: true,
      collection: res.results,
      totalCount,
      currentPage,
      pageStartNumber: Math.max(pageStartNumber, 0),
      pageEndNumber: Math.min(pageEndNumber, totalCount),
      hasNextPage,
      hasPreviousPage,
      canAdvancePage,
      lastPage
    }));
  }

  @handle(FetchMarketingEmailTemplateAction)
  private async handleFetchMarketingEmailTemplate(action: FetchMarketingEmailTemplateAction) {
    if (! action.id) return;

    const item = this.state.collection.find(i => i.Id == action.id);
    if (item && ! action.refresh) return;

    const newItem = await this.network.show(action.id);
    const collection = this.state.collection.slice();

    if (item) {
      collection[collection.indexOf(item)] = newItem;
    } else {
      collection.push(newItem);
    }

    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(CreateMarketingEmailTemplateAction, FormErrorHandling)
  private async handleCreateMarketingEmailTemplate(action: CreateMarketingEmailTemplateAction) {
    action.form.validate();

    const newItem = await this.network.post(action.form.updatedModel());
    const collection = this.state.collection.slice();

    collection.push(newItem);
    this.setState(state => ({ ...state, collection: collection }));
    action.created = newItem;
  }

  @handle(UpdateMarketingEmailTemplateAction, FormErrorHandling)
  private async handleUpdateMarketingEmailTemplate(action: UpdateMarketingEmailTemplateAction) {
    action.form.validate();

    const updatedItem = await this.network.put(action.form.updatedModel());
    const collection = this.state.collection.slice();
    const currentItem = collection.find(i => i.Id == updatedItem.Id);

    collection[collection.indexOf(currentItem)] = updatedItem;
    this.setState(state => ({ ...state, collection: collection }));
    action.updated = updatedItem;
  }

  @handle(DeleteMarketingEmailTemplateAction)
  private async handleDeleteMarketingEmailTemplate(action: DeleteMarketingEmailTemplateAction) {
    const idsArr: number[] = Array.isArray(action.ids) ? action.ids : [action.ids];

    await this.network.destroy(idsArr, action.all, action.toggle, action.search);
  }

  @handle(CopyMarketingEmailTemplateAction, FormErrorHandling)
  private async handleCopyMarketingEmailTemplate(action: CopyMarketingEmailTemplateAction) {
    const idsArr: number[] = Array.isArray(action.ids) ? action.ids : [action.ids];

    const copiedItems = await this.network.copy(idsArr, action.all, action.toggle, action.search);
  }

  @handle(ActivateMarketingEmailTemplateAction, FormErrorHandling)
  private async handleActivateMarketingEmailTemplate(action: ActivateMarketingEmailTemplateAction) {
    const idsArr: number[] = Array.isArray(action.ids) ? action.ids : [action.ids];

    await this.network.activate(idsArr, action.all, action.toggle, action.search);
  }

  @handle(DeactivateMarketingEmailTemplateAction, FormErrorHandling)
  private async handleDeactivateMarketingEmailTemplate(action: DeactivateMarketingEmailTemplateAction) {
    const idsArr: number[] = Array.isArray(action.ids) ? action.ids : [action.ids];

    await this.network.deactivate(idsArr, action.all, action.toggle, action.search);
  }
}
