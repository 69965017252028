import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { ContactRepository } from "network/contact-repository";
import { InquiryResponseRepository } from "network/inquiry-response-repository";

import {
  StartAction,
  ContactModelChangedAction,
  ContactOrganizationModelChangedAction,
  RefreshContactModelAction,
} from "./actions";
import { InquiryResponse } from "models/inquiry-response";
import { ContactActivityRepository } from "network/contact-activity-repository";

import {
  Contact,
  ContactMetaData,
  Activity,
  IContactRelationshipGroupModel,
  IRelationship,
  IUserRelationshipGroupModel,
  IFieldAttachmentData
} from "models/contact";
import { Operation, compare } from 'fast-json-patch';
import { CustomFieldType, ContactOrganization } from 'models/contact-organization';
import { WorkflowRun } from 'models/application-workflow-run';
import { wait } from "wait";
import { difference } from "lodash-es";
import { IAggregationResult } from "network/ats";
import {
  EntityChanged,
  WebSocketMessageAction,
  filterWebsocketMessage
} from './filter-websocket-message';
import { FeatureFlagService } from "service/feature-flag";
import { ActivityResult } from "models/contact-activity";
import { UserTaskRepository } from "network/user-task-repository";
import { WorkflowRepository } from "network/workflow-repository";
import { UserTask } from "models/user-task";
import { ContactsService } from "service/contacts";
import { CurrentUserStore } from "./current-user";
import { FileService } from "service/file";
import { AppRepository } from "network/app-repository";
import { createFrom } from "fw-model";
import { EnsureContactReferencesAction } from "./entity-reference";
import { ContactWorkflowEvent } from "models/contact-workflow-event";

export const ACTIVITY_PER_PAGE = 10;

type Agg = { id: string; count: number };
type EnrollingWorkflow = {
  id: string,
  name: string
}

interface CurrentContactShape {
  loading: boolean;
  loading_id: string;
  contact: Contact;
  contactMeta: ContactMetaData;
  inquiryResponses: InquiryResponse[];
  inquiryResponsesSort: string,

  activity: Activity[];
  activityLoading: boolean;
  activityPage: number;
  activityTotal: number;
  activityFilter: string;
  activityAggregations: Agg[];
  activityTypeAggregations: Agg[];
  activityAfterToken?: string;

  tasks: UserTask[];
  tasksPage: number;
  tasksPageSize: number;
  tasksLoading: boolean,
  tasksSort: string,
  tasksLoadingError: boolean;
  tasksTotal: number;
  tasksFilter: string;
  tasksSelectAll: boolean;
  tasksSelectedIds: string[];

  relationshipsLoading: boolean;
  contactRelationships: IContactRelationshipGroupModel[];
  userRelationships: IUserRelationshipGroupModel[];
  organizationId: string;
  organization: ContactOrganization;

  workflowRunsLoadingError: boolean;
  workflowRunsLoading: boolean;
  enrollingWorkflows: EnrollingWorkflow[];
  workflowRuns: WorkflowRun[];

  attachmentFieldLoading: boolean;
  attachmentFieldLoadingError: boolean;
  attachmentFieldName: string;
  attachmentFieldRendered: string;
  attachmentFieldShowRaw: boolean;
  attachmentFieldRaw: string;

  attachmentFieldHasData: boolean;
  attachmentFieldDisplayName: string;
  attachmentFieldData: IFieldAttachmentData;
}

export class UpdateContactPropertiesAction {
  constructor(
    public contact: Contact,
    public operations: Operation[]
  ) { }
}

export class RemoveContactPropertyManualEntryAction {
  constructor(
    public contact: Contact,
    public fieldId: string
  ) { }
}

export class AddContactRelationshipAction {
  constructor(
    public contact: Contact,
    public relationship: IContactRelationshipGroupModel,
    public relationships: IRelationship[],
    public shouldAppend: boolean = true,
  ) { }
}

export class RemoveContactRelationshipAction {
  constructor(
    public contact: Contact,
    public relationship: IContactRelationshipGroupModel,
    public relationshipId: string,
  ) { }
}

export class AddUserRelationshipAction {
  constructor(
    public contact: Contact,
    public relationship: IUserRelationshipGroupModel,
    public relationships: string[],
  ) { }
}

export class RemoveUserRelationshipAction {
  constructor(
    public contact: Contact,
    public relationship: IUserRelationshipGroupModel,
    public relationshipId: string,
    public refresh: boolean = true
  ) { }
}

export class LoadContactAction {
  constructor(public contactId: string) { }
}

export class EnsureTasksAction {
  constructor(public forceRefresh: boolean = false) { }
}

export class TasksNextPageAction { }
export class TasksPreviousPageAction { }

export class ToggleContactTaskSortAction {
  constructor(public sort: string) { }
}

export class ToggleContactInquiryResponsesSortAction {
  constructor(public sort: string) { }
}

export class ToggleContactTaskSelectAllAction {
  constructor(public selectAll: boolean) { }
}

export class ContactTaskIdsSelectedAction {
  constructor(public ids: string[]) { }
}

export class ContactTaskFilterAction {
  constructor(public filter: string) { }
}

export class DeleteSelectedContactTasksAction {
  constructor() { }
}

export class SetCompletedSelectedContactTasksAction {
  constructor(public isCompleted: boolean) { }
}

export class AssignUserToSelectedContactTasksAction {
  constructor(public userId: string) { }
}

export class EnsureCurrentActivityAction {
  constructor(public forceRefresh: boolean = false, public defaultTabFilter: string = null) { }
}

export class EnsureCommentsAction {
  constructor() { }
}

export class ActivityNextPageAction {}
export class ActivityLoadMoreAction {}
export class ActivityPreviousPageAction {}

export class ActivityLoadMoreMessagesAction {
  constructor(public contactId: string) { }
}

export class AddActivityAction {
  constructor(public contactId: string, public contactType: string, public description: string) { }
}

export class RemoveActivityAction {
  constructor(public id: string) { }
}

export class ActivityFilterAction {
  constructor(public filter: string, public page: number = null) { }
}

export class EnsureCurrentRelationshipsAction { }

export class RefreshCurrentRelationshipsAction {
  constructor(public refreshContact: boolean = true, public refreshUser: boolean = true) { }
}

export class EnsureCurrentWorkflowRunsAction {
}

export class EnrollContactInWorkflowAction {
  public newRun: WorkflowRun;

  constructor(public contactId: string, public workflowId: string, public workflowName: string) {
  }
}

export class DeleteContactFieldDataAction {
  public success: boolean = false;
  constructor(public contactId: string, public fieldName: string) { }
}

export class SetContactAttachmentFieldShowRawAction {
  constructor(public contactId: string, public fieldName: string, public showRaw: boolean = false) { }
}

export class LoadContactAttachmentFieldAction {
  constructor(public contactId: string, public fieldName: string) { }
}

export class UploadProfilePictureAction {
  constructor(public contactId: string, public file: File) { }
}

export class DeleteProfilePictureAction {
  constructor(public contactId: string) { }
}

export class DeleteCurrentContactAction {
  constructor(public contactId: string) { }
}

@inject
export class CurrentContactStore extends Store<CurrentContactShape> {
  public readonly COMMENT_TYPE: string = "@contact.comment";

  constructor(
    private repository: ContactRepository,
    private inquiryResponseRepository: InquiryResponseRepository,
    private activityRepo: ContactActivityRepository,
    private userTaskRepo: UserTaskRepository,
    private workflowRepo: WorkflowRepository,
    private contactService: ContactsService,
    private currentUserStore: CurrentUserStore,
    private ffs: FeatureFlagService,
    private fileService: FileService,
    private appRepo: AppRepository
  ) {
    super();
  }

  defaultState() {
    return {
      contact: null,
      contactMeta: null,
      inquiryResponses: [],
      inquiryResponsesSort: "-(metadata.dateCreatedUtc)",
      loading: false,
      loading_id: null,

      activity: [],
      activityPage: 1,
      activityLoading: false,
      activityTotal: 0,
      activityFilter: null,
      activityAggregations: [],
      activityTypeAggregations: [],
      activityAfterToken: undefined,

      tasks: [],
      tasksPage: 1,
      tasksSort: 'dateDueUtc',
      tasksPageSize: 50,
      tasksLoading: false,
      tasksLoadingError: false,
      tasksTotal: 0,
      tasksFilter: 'isCompleted:false',
      tasksSelectAll: false,
      tasksSelectedIds: [],

      relationshipsLoading: false,
      contactRelationships: [],
      userRelationships: [],
      organizationId: null,
      organization: null,

      workflowRunsLoadingError: false,
      workflowRunsLoading: false,
      enrollingWorkflows: [],
      workflowRuns: [],

      attachmentFieldLoading: false,
      attachmentFieldLoadingError: false,
      attachmentFieldName: null,
      attachmentFieldRendered: null,
      attachmentFieldShowRaw: false,
      attachmentFieldRaw: null,

      attachmentFieldData: null,
      attachmentFieldHasData: false,
      attachmentFieldDisplayName: null,
    };
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    this.setState(state => ({
      ...state,
      ...this.defaultState(),
      organizationId: action.context.ContactOrganization.id,
      organization: action.context.ContactOrganization,
    }));
  }

  @handle(ContactOrganizationModelChangedAction)
  async handleContactOrganizationModelChangedAction(action: ContactOrganizationModelChangedAction) {
    this.setState(state => ({
      ...state,
      organization: action.organization,
    }));
  }

  @handle(UpdateContactPropertiesAction)
  private async handleUpdateContactPropertiesAction(action: UpdateContactPropertiesAction) {
    if (action.operations.length === 0) {
      return;
    }

    const contact = await this.repository.patchWithRetries(action.contact, action.operations);
    const contactMeta = await this.repository.getMetaByContactId(action.contact.id);

    await this.update(contact, contactMeta);
    await dispatch(new RefreshCurrentRelationshipsAction());
  }



  @handle(RemoveContactPropertyManualEntryAction)
  private async handleRemoveContactPropertyManualEntryAction(action: RemoveContactPropertyManualEntryAction) {
    if (!action.contact || !action.fieldId) {
      return;
    }

    const contact = await this.repository.removeManualEntry(action.contact.id, action.fieldId);
    const contactMeta = await this.repository.getMetaByContactId(action.contact.id);

    await this.update(contact, contactMeta);
    await dispatch(new RefreshCurrentRelationshipsAction());
  }

  @handle(EnsureTasksAction)
  private async handleEnsureTasksAction(action: EnsureTasksAction) {
    if (this.state.contact == null || this.state.tasksLoading || (this.state.tasks.length > 0 && !action.forceRefresh)) {
      return;
    }

    await this.loadTasks();
  }

  @handle(ToggleContactTaskSortAction)
  async handleToggleContactTaskSortAction(action: ToggleContactTaskSortAction) {
    const sort = action.sort === this.state.tasksSort ? `-(${action.sort})` : action.sort;
    this.setState(state => ({
      ...state,
      tasksSort: sort,
      tasksSelectAll: false,
      tasksSelectedIds: []
    }));

    await this.loadTasks();
  }

  @handle(ToggleContactInquiryResponsesSortAction)
  async handleToggleContactInquiryResponsesSortAction(action: ToggleContactInquiryResponsesSortAction) {
    const sort = action.sort === this.state.inquiryResponsesSort ? `-(${action.sort})` : action.sort;
    this.setState(state => ({
      ...state,
      inquiryResponsesSort: sort
    }));

    await this.updateinquiryResponses(this.state.contact.id);
  }

  @handle(ToggleContactTaskSelectAllAction)
  async handleToggleContactTaskSelectAllAction(action: ToggleContactTaskSelectAllAction) {
    this.setState(state => ({
      ...state,
      tasksSelectAll: action.selectAll,
      tasksSelectedIds: action.selectAll ? [] : state.tasksSelectedIds
    }));
  }

  @handle(ContactTaskIdsSelectedAction)
  handleContactTaskIdsSelectedAction(action: ContactTaskIdsSelectedAction) {
    this.setState(state => ({
      ...state,
      tasksSelectAll: false,
      tasksSelectedIds: action.ids
    }));
  }

  @handle(ContactTaskFilterAction)
  async handleContactTaskFilterAction(action: ContactTaskFilterAction) {
    this.setState(state => ({
      ...state,
      tasksFilter: action.filter,
      tasksSelectAll: false,
      tasksSelectedIds: [],
      tasksPage: 1
    }));

    await this.loadTasks();
  }

  @handle(DeleteSelectedContactTasksAction)
  private async handleDeleteSelectedUserTasksAction(action: DeleteSelectedContactTasksAction) {
    if (this.state.tasksSelectAll) {
      const filter = this.getTasksFilter();
      await this.userTaskRepo.deleteAll(filter);
    } else {
      await this.userTaskRepo.delete(this.state.tasksSelectedIds);
      const totalLeft = this.state.tasksTotal - this.state.tasksSelectedIds.length;
      const lastPage = Math.ceil(totalLeft / this.state.tasksPageSize);
      if (this.state.tasksPage > lastPage) {
        this.state.tasksPage = lastPage;
      }
    }

    this.setState(state => ({
      ...state,
      tasksSelectAll: false,
      tasksSelectedIds: [],
      tasksPage: state.tasksSelectAll? 1: state.tasksPage
    }));

    await this.loadTasks();
  }

  @handle(SetCompletedSelectedContactTasksAction)
  private async handleSetCompleteSelectedUserTasksAction(action: SetCompletedSelectedContactTasksAction) {
    const { tasksSelectAll, tasksSelectedIds} = this.state;
    if (tasksSelectAll) {
      const filter = this.getTasksFilter();
      await this.userTaskRepo.setCompletedAll(action.isCompleted, filter);
    } else {
      await this.userTaskRepo.setCompleted(action.isCompleted, tasksSelectedIds);
    }

    this.setState(state => ({
      ...state,
      tasksPage: this.state.tasksSelectAll ? 1 : this.state.tasksPage,
      tasksSelectAll: false,
      tasksSelectedIds: []
    }));

    await this.loadTasks();
  }

  @handle(AssignUserToSelectedContactTasksAction)
  private async handleAssignUserToSelectedUserTasksAction(action: AssignUserToSelectedContactTasksAction) {
    const { tasksSelectAll, tasksSelectedIds } = this.state;
    if (tasksSelectAll) {
      const filter = this.getTasksFilter();
      await this.userTaskRepo.assignAll(action.userId, filter);
    } else {
      await this.userTaskRepo.assign(action.userId, tasksSelectedIds);
    }

    const tasksPage = this.state.tasksSelectAll ? 1 : this.state.tasksPage;
    this.setState(state => ({
      ...state,
      tasksPage: tasksPage,
      tasksSelectAll: false,
      tasksSelectedIds: []
    }));

    await this.loadTasks();
  }

  private getTasksFilter() {
    let filter = `targetId:${this.state.contact.id}`;
    if (this.state.tasksFilter) {
      filter = `(${filter}) AND (${this.state.tasksFilter})`;
    }
    return filter;
  }

  private async loadTasks() {
    this.setState(state => ({
      ...state,
      tasksSelectAll: false,
      tasksSelectedIds: [],
      tasksLoading: true,
      tasksLoadingError: false
    }));

    let filter = this.getTasksFilter();

    try {
      const { total, list } = await this.userTaskRepo.search(
        filter,
        null,
        this.state.tasksSort,
        this.state.tasksPage,
        this.state.tasksPageSize,
      );

      this.setState(state => ({
        ...state,
        tasksLoading: false,
        tasksTotal: total,
        tasks: list,
      }));
    } catch (err) {
      this.setState(state => ({
        ...state,
        tasksLoading: false,
        tasksLoadingError: true,
      }));
      throw err;
    }
  }

  @handle(TasksNextPageAction)
  private async handleTasksNextPageAction() {
    this.setState(state => ({
      ...state,
      tasksPage: state.tasksPage + 1,
      tasksSelectAll: false,
      tasksSelectedIds: []
    }));

    await this.loadTasks();
  }

  @handle(TasksPreviousPageAction)
  private async handleTasksPreviousPageAction() {
    this.setState(state => ({
      ...state,
      tasksPage: state.tasksPage - 1,
      tasksSelectAll: false,
      tasksSelectedIds: []
    }));

    await this.loadTasks();
  }

  private async loadCurrentActivityPage(updateAggs: boolean = false, shouldAppend: boolean = false) {
    const { contact, activityPage, activityFilter, activityAfterToken, activity } = this.state;

    this.setState(state => ({
      ...state,
      activityLoading: true,
    }));

    const filter: string = `contact:${contact.id}`;
    const combinedFilter: string = activityFilter ? `${filter} AND ${activityFilter}` : filter;

    const shouldUpdateAggs: boolean = updateAggs || !this.state.activityAggregations;
    const aggs: string = "terms:datasource terms:type";
    try {
      const response = await this.activityRepo.list(
          null,
          combinedFilter,
          shouldUpdateAggs && !activityFilter ? aggs : null,
          "-created",
          shouldAppend ? null : activityPage,
          ACTIVITY_PER_PAGE,
          contact.id,
          shouldAppend ? activityAfterToken : null
      );

      let aggregationResult: IAggregationResult = response.aggregations;
      if (shouldUpdateAggs && activityFilter) {
        const aggResponse = await this.activityRepo.count(filter, aggs, contact.id);
        aggregationResult = aggResponse.aggregations;
      }

      const activityAggregations: Agg[] = shouldUpdateAggs
        ? aggregationResult["terms_datasource"].items.map(a => ({ id: a.key as string, count: a.total }))
        : this.state.activityAggregations;

      const activityTypeAggregations: Agg[] = shouldUpdateAggs
        ? aggregationResult["terms_type"].items.map(a => ({ id: a.key as string, count: a.total }))
        : this.state.activityTypeAggregations;

      this.setState(state => ({
        ...state,
        activityLoading: false,
        activity: [
            ...(shouldAppend ? activity : []),
            ...response.results,
        ],
        activityTotal: response.total,
        activityAggregations,
        activityTypeAggregations,
        activityAfterToken: response.after
      }));
    } catch (e) {
      this.setState(state => ({
          ...state,
          activityLoading: false,
      }));
    }
  }

  private async loadMoreMessageActivity() {
    try {
      // @todo: update request when paginated messages endpoint exists
      const { messages } = await this.repository.getById(this.state?.contact?.Id);
      this.setState(state => ({
        ...state,
        contact: createFrom(Contact, {
          ...state.contact,
          messages: [
            ...state.contact.messages,
            ...messages,
          ],
        }),
      }));
    } catch (err) { }
  }

  @handle(EnsureCurrentActivityAction)
  private async handleEnsureCurrentActivityAction(action: EnsureCurrentActivityAction) {
    const { contact, activity, activityLoading, activityFilter } = this.state;
    if (contact == null) {
      return;
    }

    const isCachedDataMatchingWithCurrentTabFilter = activityFilter == action.defaultTabFilter;
    const isDataUpToDate = activityLoading || (activity.length > 0 && !action.forceRefresh);
    if (isCachedDataMatchingWithCurrentTabFilter && isDataUpToDate) {
      return;
    }

    if (!isCachedDataMatchingWithCurrentTabFilter) {
      await dispatch(new ActivityFilterAction(action.defaultTabFilter));
    } else {
      await this.loadCurrentActivityPage(true);
    }
  }

  @handle(EnsureCommentsAction)
  private async handleEnsureCommentsAction(action: EnsureCommentsAction) {
    const { contact, activity, activityLoading, activityFilter, activityPage } = this.state;
    if (contact === null) {
      return;
    }

    const isCachedDataMatchingWithCurrentTabFilter = activityFilter === this.commentsFilter && activityPage === 1;
    const isDataUpToDate = activityLoading || (activity.length > 0);
    if (isCachedDataMatchingWithCurrentTabFilter && isDataUpToDate) {
      return;
    }

    if (!isCachedDataMatchingWithCurrentTabFilter) {
      await dispatch(new ActivityFilterAction(this.commentsFilter, 1));
    } else {
      await this.loadCurrentActivityPage(true);
    }
  }

  public get commentsFilter() {
    const { isGlobalPrincipal } = this.currentUserStore.state;
    const filters = [`type:${this.COMMENT_TYPE}`];
    if (!isGlobalPrincipal) {
      // global admin comments does not have user_id
      filters.push("_exists_:user_id");
    }

    return filters.join(" ");
  }

  @handle(AddActivityAction)
  private async handleAddActivityAction(action: AddActivityAction) {
    const newActivity = await this.activityRepo.post(<ActivityResult>{
      contact_id: action.contactId,
      contact_type: action.contactType,
      type: this.COMMENT_TYPE,
      description: action.description,
    });

    this.setState(state => ({
      ...state,
      activity: [newActivity, ...state.activity],
      activityTotal: state.activityTotal + 1,
    }));
  }

  @handle(RemoveActivityAction)
  private async handleRemoveActivityAction(action: RemoveActivityAction) {
    await this.activityRepo.remove(action.id);

    const existing = this.state.activity.find(a => a.id === action.id);
    if (existing) {
      this.setState(state => ({
        ...state,
        activity: difference(this.state.activity, [existing]),
        activityTotal: state.activityTotal - 1,
      }));
    }
  }

  @handle(ActivityFilterAction)
  private async handleActivityFilterByDatasourceAction(action: ActivityFilterAction) {
    this.setState(state => ({
      ...state,
      activityPage: action.page ?? state.activityPage,
      activityFilter: action.filter,
      activity: []
    }));

    await this.loadCurrentActivityPage();
  }

  @handle(ActivityNextPageAction)
  private async handleActivityNextPageAction() {
    this.setState(state => ({
      ...state,
      activityPage: state.activityPage + 1,
    }));

    await this.loadCurrentActivityPage();
  }

  @handle(ActivityLoadMoreAction)
  private async handleActivityLoadMoreAction() {
    await this.loadCurrentActivityPage(false, true);
  }

  @handle(ActivityPreviousPageAction)
  private async handleActivityPreviousPageAction() {
    this.setState(state => ({
      ...state,
      activityPage: Math.max(1, state.activityPage - 1),
    }));

    await this.loadCurrentActivityPage();
  }

  @handle(ActivityLoadMoreMessagesAction)
  private async handleActivityLoadMoreMessagesAction(action: ActivityLoadMoreMessagesAction) {
    if (this.state.contact && this.state.contact.id !== action.contactId) {
      return;
    }
    await this.loadMoreMessageActivity();
  }

  @handle(LoadContactAction)
  private async handleLoadContactAction(l: LoadContactAction) {
    // if it is already loaded, then don't load it..
    if (this.state.contact !== null && this.state.contact.id === l.contactId) {
      return;
    }

    this.setState(state => ({
      ...state,
      loading: true,
      loading_id: l.contactId,
      contact: null,
      inquiryResponses: [],

      activity: [],
      activityPage: 1,
      activityLoading: false,
      activityTotal: 0,
      activityFilter: null,
      activityAggregations: [],
      activityTypeAggregations: [],

      tasks: [],
      tasksPage: 1,
      tasksPageSize: 50,
      tasksLoading: false,
      tasksTotal: 0,

      contactRelationships: [],
      userRelationships: [],
      relationshipsLoading: false,

      woworkflowRunsLoadingError:false,
      workflowRunsLoading: false,
      enrollingWorkflows: [],
      workflowRuns: []
    }));
    await this.updateinquiryResponses(l.contactId);
  }

  private async updateinquiryResponses(contactId :string){
    const [contact, contactMeta, inquiryResponses] = await Promise.all([
      this.repository.getById(contactId),
      this.repository.getMetaByContactId(contactId),
      this.inquiryResponseRepository.list(contactId, null, this.state.inquiryResponsesSort)
    ]);
    await this.update(contact, contactMeta, inquiryResponses);
  }

  @handle(RefreshContactModelAction)
  private async handleRefreshContactModelAction(action: RefreshContactModelAction) {
    // only refresh the contact if it IS the current contact.
    if (this.state.contact && this.state.contact.id !== action.contactId) {
      return;
    }

    const [contact, contactMeta] = await Promise.all([
      this.repository.getById(action.contactId),
      this.repository.getMetaByContactId(action.contactId),
    ]);
    await this.update(contact, contactMeta);
    await this.loadCurrentRelationships();
    this.updateAttachmentField(action.contactId, this.state.attachmentFieldName);
  }

  @handle(WebSocketMessageAction, filterWebsocketMessage("EntityChanged"))
  private async handleEntityChangedAction(action: WebSocketMessageAction<EntityChanged>) {
    const { contact } = this.state;
    if (!contact || !contact.id) {
      return;
    }

    switch (action.data.type) {
      case "PersistentContact":
        if (action.data.id && action.data.id === contact.id) {
          await dispatch(new RefreshContactModelAction(action.data.id));
          await dispatch(new EnsureCurrentActivityAction(true));
        }
        break;
      case "Activity":
        const contactId = action.data.data?.contact_id;
        if (contactId === contact.id) {
          await dispatch(new RefreshContactModelAction(contactId));
          await dispatch(new EnsureCurrentActivityAction(true));
        }
        break;
      case "MessageInfoChangedEvent": {
        const contactId = action.data.data?.contact_id;
        const contactType = action.data.data?.contact_type;
        if (contactId === contact.id && contactType == contact.type) {
          await dispatch(new RefreshContactModelAction(contactId));
        }
        break;
      }
    }
  }

  @handle(WebSocketMessageAction)
  private async handleContactWorkflowEventAction(action: WebSocketMessageAction<ContactWorkflowEvent>) {
    const { contact } = this.state;
    if (!contact?.id) {
      return;
    }

    if ("CompletedWorkflow" !== action.data.subEventName) {
      return;
    }

    // wait for elastic consistency
    setTimeout(() => dispatch(new EnsureCurrentWorkflowRunsAction()), 1000);
  }

  private async update(contact: Contact, contactMeta: ContactMetaData, inquiryResponses?: InquiryResponse[]) {
    this.setState(state => ({
      ...state,
      contact,
      contactMeta,
      inquiryResponses: inquiryResponses || state.inquiryResponses,
    }));

    await dispatch(new ContactModelChangedAction(contact));
    await dispatch(new EnsureContactReferencesAction([contact.id], true));
  }

  @handle(EnsureCurrentRelationshipsAction)
  private async handleEnsureCurrentRelationshipsAction(action: EnsureCurrentRelationshipsAction) {
    const { contact, relationshipsLoading, contactRelationships, userRelationships } = this.state;
    if (contact == null || relationshipsLoading || contactRelationships.length > 0 || userRelationships.length > 0 ) {
      return;
    }

    await this.loadCurrentRelationships();
  }

  @handle(RefreshCurrentRelationshipsAction)
  private async handleRefreshCurrentRelationshipsAction(action: RefreshCurrentRelationshipsAction) {
    await this.loadCurrentRelationships(action.refreshContact, action.refreshUser);
  }

  private async loadCurrentRelationships(refreshContactRelationships: boolean = true, refreshUserRelationship: boolean = true) {
    this.setState(state => ({
      ...state,
      relationshipsLoading: true
    }));

    // const organization = await this.contactOrganizationRepository.getById(this.state.organizationId);
    const organization = this.state.organization;
    let contactRelationships: IContactRelationshipGroupModel[] = null;
    if (refreshContactRelationships) {
      contactRelationships= await this.loadContactRelationships(organization);
    }

    let userRelationships: IUserRelationshipGroupModel[] = null;
    if (refreshUserRelationship) {
      userRelationships = this.loadUserRelationships(organization);
    }

    this.setState(state => ({
      ...state,
      relationshipsLoading: false,
      contactRelationships: contactRelationships || state.contactRelationships,
      userRelationships: userRelationships || state.userRelationships
    }));
  }

  private loadUserRelationships(organization: ContactOrganization): IUserRelationshipGroupModel[] {
    if (!this.state.contact)
      return [];
    const contact = this.state.contact;
    const contactMeta = this.state.contactMeta;
    const contactType = contact.type;

    const relationshipFields = organization.fields.filter(f => f.contact_type === contactType && f.type === CustomFieldType.user);
    let relationships: IUserRelationshipGroupModel[] = [];
    for (const field of relationshipFields) {
      relationships.push(<IUserRelationshipGroupModel>{
        loading: false,
        type: field.data.contact_type,
        display_name: field.display_name,
        field: field,
        isManuallySelected: this.contactService.isManuallySelected(contact, contactMeta, field),
        userId: contact.data && contact.data[field.name]
      });
    }

    return relationships;
  }

  private async loadContactRelationships(organization: ContactOrganization): Promise<IContactRelationshipGroupModel[]> {
    if (!this.state.contact)
      return [];
    const relationshipFields = organization.fields.filter(f => f.type === CustomFieldType.relationship);
    let relationships: IContactRelationshipGroupModel[] = [];
    for (const field of relationshipFields) {
      if (field.contact_type === this.state.contact.type) {
        relationships.push(<IContactRelationshipGroupModel>{
          loading: true,
          primary: true,
          type: field.data.contact_type,
          display_name: field.display_name,
          field: field,
          contacts: { results: [], total: 0 }
        });
      }
      if (field.data.contact_type === this.state.contact.type) {
        relationships.push(<IContactRelationshipGroupModel>{
          loading: true,
          primary: false,
          type: field.contact_type,
          display_name: field.data.group_display_name,
          field: field,
          contacts: { results: [], total: 0 }
        });
      }
    }

    await Promise.all(relationships.map(async (r) => await this.loadContactRelationship(r)));
    return relationships;
  }

  private async loadContactRelationship(r: IContactRelationshipGroupModel) {
    r.loading = true;

    const contact = this.state.contact;
    if (r.primary) {
      const relationships: IRelationship[] = contact?.data && contact.data[r.field.name] || [];
      if (relationships.length === 0) {
        r.contacts = { results: [], total: 0 };
        r.loading = false;
        return;
      }

      try {
        r.contacts = await this.repository.selectUnrestricted({ ids: relationships.map(r => r.id) });
      } catch (err) { }
    } else {
      try {
        r.contacts = await this.repository.list(null, `${r.field.search_field}:${contact.id}`, null, null, 1, 30, r.type);
      } catch (err) { }
    }

    r.loading = false;
  }


  @handle(AddContactRelationshipAction)
  private async handleAddContactRelationshipAction(action: AddContactRelationshipAction) {
    if (!action.contact || action.relationships.length == 0) {
      return;
    }

    const name: string = action.relationship.field.name;
    const original = JSON.parse(JSON.stringify(action.contact));
    const relationships: IRelationship[] = action.shouldAppend ? action.contact.data[name] || [] : action.relationships;

    if (action.shouldAppend) {
      for (const relationship of action.relationships) {
        const existing = relationships.find(f => f.id === relationship.id);
        if (existing) {
          existing.display_name = relationship.display_name;
        } else {
          relationships.push(relationship);
        }
      }
    }

    action.contact.data[name] = relationships;
    const operations = compare({ value: original }, { value: action.contact });
    operations.forEach(o => {
      o.path = o.path.replace("/value", "");
    });

    if (operations.length == 0) {
      console.log("No contact relationships to update");
      await this.refreshContactRelationship(action.contact, action.relationship);
      return;
    }

    console.log("Add contact relationship patch: %s", operations)
    action.relationship.loading = true;
    action.contact = await this.repository.patchWithRetries(action.contact, operations);
    if (action.contact.id === this.state.contact.id) {
      const contactMeta = await this.repository.getMetaByContactId(action.contact.id);
      await this.update(action.contact, contactMeta);
    } else {
      await dispatch(new ContactModelChangedAction(action.contact));
    }

    await this.refreshContactRelationship(action.contact, action.relationship);
    await dispatch(new RefreshCurrentRelationshipsAction(false, true));
  }

  @handle(RemoveContactRelationshipAction)
  private async handleRemoveContactRelationshipAction(action: RemoveContactRelationshipAction) {
    if (!action.contact || !action.contact.data) {
      return;
    }

    const name: string = action.relationship.field.name;
    const relationships: IRelationship[] = action.contact.data[name];
    if (!relationships || !relationships.find(r => r.id == action.relationshipId)) {
      console.log("No contact relationship found to remove.");
      await this.refreshContactRelationship(action.contact, action.relationship);
      return;
    }

    action.relationship.loading = true;
    action.contact = await this.repository.patchWithRetries(action.contact, [
      <Operation>{ "op": "remove", "path": `$.data.${name}.[?(@.id=='${action.relationshipId}')]` }
    ]);

    if (action.contact.id === this.state.contact.id) {
      const contactMeta = await this.repository.getMetaByContactId(action.contact.id);
      await this.update(action.contact, contactMeta);
    } else {
      await dispatch(new ContactModelChangedAction(action.contact));
    }

    await this.refreshContactRelationship(action.contact, action.relationship);
    await dispatch(new RefreshCurrentRelationshipsAction(false, true));
  }

  private async refreshContactRelationship(contact: Contact, relationship: IContactRelationshipGroupModel) {
    await wait(1000);

    if (this.state.contact.id === contact.id && relationship.field.contact_type === relationship.field.data.contact_type) {
      await this.loadCurrentRelationships();
    } else {
      await this.loadContactRelationship(relationship);
    }
  }

  @handle(AddUserRelationshipAction)
  private async handleAddUserRelationshipAction(action: AddUserRelationshipAction) {
    if (!action.contact || action.relationships.length !== 1) {
      return;
    }

    const original = JSON.parse(JSON.stringify(action.contact));

    const name: string = action.relationship.field.name;
    action.contact.data[name] = action.relationships[0];
    const operations = compare({ value: original }, { value: action.contact });
    operations.forEach(o => {
      o.path = o.path.replace("/value", "");
    });

    if (operations.length == 0) {
      console.log("No user relationships to update");
      await this.refreshUserRelationship(action.contact, action.relationship);
      return;
    }

    console.log("Add user relationship patch: %s", operations)
    action.relationship.loading = true;
    action.contact = await this.repository.patchWithRetries(action.contact, operations);
    if (action.contact.id === this.state.contact.id) {
      const contactMeta = await this.repository.getMetaByContactId(action.contact.id);
      await this.update(action.contact, contactMeta);
    } else {
      await dispatch(new ContactModelChangedAction(action.contact));
    }

    await this.refreshUserRelationship(action.contact, action.relationship);
    await dispatch(new RefreshCurrentRelationshipsAction(false, true));
  }

  @handle(RemoveUserRelationshipAction)
  private async handleRemoveUserRelationshipAction(action: RemoveUserRelationshipAction) {
    if (!action.contact || !action.contact.data) {
      return;
    }

    const name: string = action.relationship.field.name;
    const relationship: string = action.contact.data[name];
    if (!relationship) {
      console.log("No relationship found to remove.");
      await this.refreshUserRelationship(action.contact, action.relationship);
      return;
    }

    action.relationship.loading = true;
    action.contact = await this.repository.patchWithRetries(action.contact, [
      <Operation>{ "op": "remove", "path": `/data/${name}` }
    ]);

    if (action.contact.id === this.state.contact.id) {
      const contactMeta = await this.repository.getMetaByContactId(action.contact.id);
      await this.update(action.contact, contactMeta);
    } else {
      await dispatch(new ContactModelChangedAction(action.contact));
    }

    if (action.refresh) {
      await this.refreshUserRelationship(action.contact, action.relationship);
      await dispatch(new RefreshCurrentRelationshipsAction(false, true));
    }
  }

  private async refreshUserRelationship(contact: Contact, relationship: IUserRelationshipGroupModel) {
    await this.loadCurrentRelationships(false, true);
  }

  @handle(EnsureCurrentWorkflowRunsAction)
  private async handleEnsureCurrentWorkflowRunsAction(action: EnsureCurrentWorkflowRunsAction) {
    const contactId = this.state.contact.id;
    this.setState(state => ({
      ...state,
      workflowRunsLoadingError: false,
      workflowRunsLoading: true,
    }))

    try{
      const runs = await this.workflowRepo.listRuns("Contact", this.state.contact.id);
      if(contactId == this.state.contact.id)
        this.setState(state => ({
          ...state,
          workflowRunsLoading: false,
          workflowRuns: runs,
        }))
    } catch {
      this.setState(state => ({
        ...state,
        workflowRunsLoadingError: true,
        workflowRunsLoading: false,
      }))
    }

  }

  @handle(EnrollContactInWorkflowAction)
  private async handleEnrollContactInWorkflowAction(action: EnrollContactInWorkflowAction) {
    if(action.contactId == this.state.contact.id)
      this.setState(state => ({
        ...state,
        enrollingWorkflows: [...state.enrollingWorkflows, {
          id: action.workflowId,
          name: action.workflowName
        }]
      }))

    try {
      const res = await this.workflowRepo.enroll(action.workflowId, action.contactId, "Contact");
      action.newRun = res;

      if (action.contactId == this.state.contact.id) {
        this.setState(state => ({
          ...state,
          enrollingWorkflows: state.enrollingWorkflows.filter(x => x.id != action.workflowId),
          workflowRuns: [...state.workflowRuns, res]
        }))
      }
    } catch (err) {
      if (action.contactId == this.state.contact.id)
        this.setState(state => ({
          ...state,
          enrollingWorkflows: state.enrollingWorkflows.filter(x => x.id != action.workflowId),
        }))
      throw err;
    }
  }


  /*
  attachmentFieldLoading: boolean;
  attachmentFieldLoadingError: boolean;
  attachmentFieldName: string;
  attachmentFieldRendered: string;
  attachmentFieldShowRaw: boolean;
  attachmentFieldRaw: string;
  */
  private resetAttachmentField(fieldName: string = null, showRaw: boolean = false, loadingError: boolean = false) {
    this.setState(state => ({
      ...state,
      attachmentFieldLoading: false,
      attachmentFieldLoadingError: loadingError,
      attachmentFieldName: fieldName,
      attachmentFieldShowRaw: showRaw,
      attachmentFieldRendered: null,
      attachmentFieldRaw: null,

      attachmentFieldData: null,
      attachmentFieldHasData: false,
      attachmentFieldDisplayName: null,

    }));
  }

  private async updateAttachmentField(contactId: string, fieldName: string) {
    if (contactId !== this.state.contact?.id || !fieldName) {
      this.resetAttachmentField();
      return;
    }

    const { attachmentFieldShowRaw } = this.state;
    try {
      this.setState(state => ({...state, attachmentFieldLoading: true, attachmentFieldLoadingError: false }));
      const result = await this.repository.getAttachmentResult(contactId, fieldName, attachmentFieldShowRaw);

      const fieldData = this.state.contact.data[fieldName];

      this.setState(state => ({
        ...state,
        attachmentFieldLoading: false,
        attachmentFieldName: fieldName,
        attachmentFieldRendered: result.rendered,
        attachmentFieldRaw: result.raw,
        attachmentFieldHasData: fieldData != null,
        attachmentFieldData: fieldData,
        attachmentFieldDisplayName: fieldData?.display_name,
      }));

    } catch {
      this.resetAttachmentField(fieldName, attachmentFieldShowRaw, true);
    }
  }

  @handle(DeleteContactFieldDataAction)
  private async handleDeleteContactFieldData(action: DeleteContactFieldDataAction) {
    if (action.contactId !== this.state.contact?.id || !action.fieldName) {
      this.resetAttachmentField();
      return;
    }

    try {
      this.repository.deleteCustomFieldValue(action.contactId, action.fieldName);
      await dispatch(new RefreshContactModelAction(action.contactId));

      action.success = true;
    } catch (err) { /* error handling here */}
  }

  @handle(SetContactAttachmentFieldShowRawAction)
  private async handleSetContactAttachmentFieldShowRaw(action: SetContactAttachmentFieldShowRawAction) {
    if (this.state.attachmentFieldShowRaw === action.showRaw) return;

    this.setState(state => ({...state, attachmentFieldShowRaw: action.showRaw}));
    this.updateAttachmentField(action.contactId, action.fieldName);
  }

  @handle(LoadContactAttachmentFieldAction)
  private async handleLoadContactAttachmentField(action: LoadContactAttachmentFieldAction) {
    this.resetAttachmentField(action.fieldName);
    this.updateAttachmentField(action.contactId, action.fieldName);
  }

  @handle(UploadProfilePictureAction)
  private async handleUploadProfilePictureAction(action: UploadProfilePictureAction) {
    if (action.contactId !== this.state.contact?.id) {
      return;
    }
    const contact = await this.repository.postProfilePicture(action.contactId, action.file);
    const contactMeta = await this.repository.getMetaByContactId(action.contactId);
    await this.update(contact, contactMeta);
  }

  @handle(DeleteProfilePictureAction)
  private async handleDeleteProfilePictureAction(action: DeleteProfilePictureAction) {
    if (action.contactId !== this.state.contact?.id) {
      return;
    }
    const contact = await this.repository.deleteProfilePicture(action.contactId);
    const contactMeta = await this.repository.getMetaByContactId(action.contactId);
    await this.update(contact, contactMeta);
  }

  @handle(DeleteCurrentContactAction)
  private async handleDeleteCurrentContactAction(action: DeleteCurrentContactAction) {
    if (action.contactId !== this.state.contact?.id) {
      return;
    }
    await this.repository.delete(action.contactId);

    const defaultState = this.defaultState()

    this.setState(state => ({
      ...state,
      ...defaultState,
      organization: state.organization || defaultState.organization,
    }));
  }
}
