import { inject } from "fw";
import { CurrentOrganizationStore } from "state/current-organization";
import { CurrentUserStore } from "state/current-user";
import { DialogService  } from "../../../node_modules/fw-dialog";
import { PopoverController } from "service/popover";
import { KnowledgeBaseChatDialog } from "views/components/knowledge-base-chat/knowledge-base-chat-dialog";

@inject
export class HelpPopover {
  constructor(
    private currentOrganizationStore: CurrentOrganizationStore,
    private currentUserStore: CurrentUserStore,
    private dialogService: DialogService,
    private controller: PopoverController<void>,
  ) {}

  activate() {}

  get orgName() {
    const { organization } = this.currentOrganizationStore.state;
    if (organization == null || organization.Name == null) return "";
    return organization.Name;
  }

  get mailSubject() {
    const { principal } = this.currentUserStore.state;
    return encodeURIComponent(
      `Admissions ticket from ${principal.FirstName} ${principal.LastName} for ${this.orgName}`
    );
  }

  get mailToUrl() {
    return `mailto:admissionssupport@liaisonedu.com?subject=${this.mailSubject}`;
  }

  public openChat() {
    // @ts-ignore
    if (window.adaEmbed) window.adaEmbed.toggle();
  }

  get customization() {
    return this.currentOrganizationStore.state.customization;
  }

  public getHelpCenterUrl() {
    return this.customization.helpCenterUrl?.length > 0 ? this.customization.helpCenterUrl : "https://help.liaisonedu.com/Outcomes";
  }

  public get experimentalFeaturesEnabled(): boolean {
    return sessionStorage.getItem("experimentalFeatures") === "true";
  }

  public openKnowledgeBaseChat() {
    this.dialogService.open(KnowledgeBaseChatDialog);
    this.controller.close();
  }
}
