import { inject } from "fw";
import { FeatureFlagService } from "./feature-flag";
import { Permissions } from "service/permission";

@inject
export class OrganizationModuleService {
  constructor(
    private featureFlagService: FeatureFlagService,
    private permissions: Permissions
  ) {}

  public get hasContactsFeature() {
    return (
      this.permissions.all(null, "Feature.Contacts")
    );
  }

  public get hasMarketingFeature() {
    return (
      this.featureFlagService.isFeatureFlagEnabled("MarketingOutreach") &&
      this.permissions.all(null, "Feature.Marketing")
    );
  }

  public get hasReviewFeature() {
    return (
      this.hideAts === false &&
      this.permissions.all(
            "ApplicationsModule",
            "Feature.ApplicationReview"
          )
    );
  }

  public get hasApplicationSourcesPermissions() {
    return (
      this.permissions.any(
            ["EditApplicationImport",
            "ViewApplicationImportLogs"]
          )
    );
  }

  public get hasPaymentJournalFeature() {
    return (
      this.featureFlagService.isFeatureFlagEnabled("PaymentJournal") &&
      this.permissions.all("ViewPaymentDetails")
    );
  }

  public get hasSuppressedItemsFeature() {
    return (
      this.featureFlagService.isFeatureFlagEnabled("SuppressedItems")
    );
  }

  public get canPersonalizeDashboard() {
    return this.permissions.all("PersonalizeDashboard", "Feature.Dashboard");
  }

  public get hasApplicantPortalFeature() {
    return (
      this.hideAts === false &&
      this.permissions.all("ApplicationsModule", "Feature.ApplicantPortal")
    );
  }

  public get hasReferencesFeature() {
    return (
      this.hideAts === false &&
      this.permissions.all("ApplicationsModule", "Feature.References")
    );
  }

  public get hasCollaborationFeature() {
    return (
      this.hideAts === false &&
        this.permissions.all("ApplicationsModule", "Feature.Collaboration")
    );
  }

  public get hasWorkflowFeature() {
    return (
      this.hideAts === false &&
      this.permissions.all(
            "ApplicationsModule",
            "Feature.ApplicationWorkflow"
          )
    );
  }

  private get hideAts() {
    return this.featureFlagService.isFeatureFlagEnabled("HideATS");
  }
}
